export enum AutoPlanningAlgorithmTypeEnum {
  BY_TERRITORY_COUNT = 'BY_TERRITORY_COUNT',
  BY_PER_VALUE = 'BY_PER_VALUE',
  BY_SERVICE_TIME = 'BY_SERVICE_TIME',
}

export enum AutoPlanningWayCalculationEnum {
  SHORTEST = 'SHORTEST',
  FASTEST = 'FASTEST',
}

export enum AutoPlanningModelOptimizationEnum {
  SIZE_1 = 'SIZE_1',
  DURATION = 'DURATION',
  FREQUENCY = 'FREQUENCY',
  SERVICE_TIME = 'SERVICE_TIME',
  COUNT_PER_TERRITORY = 'COUNT_PER_TERRITORY',
}

export enum AutoPlanningModelPointPlanedChoiceEnum {
  OPTIMIZE = 'OPTIMIZE',
  MANUEL = 'MANUEL',
}
