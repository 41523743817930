import React from 'react';
//
import Header from './header';
import TableContent from './table';
import { MapsBottomTasksToPlannedItem } from 'src/store/maps-bottom-tasks-to-planned/types';
import {
  Box,
  Chip,
  Table,
  Stack,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@mui/material';
//
import ModalFooter from 'src/components/modals/footer';
import ModalHeader from 'src/components/modals/header';
import ModalContent from 'src/components/modals/content';
import { getGeomarketingFilter } from 'src/store/geomarketing-filters';

interface Props {
  tableHeight: number;
}

export default function MapBottomWrapperTasksToPlanned(props: Props) {
  const { tableHeight } = props;
  const [visibleItem, setVisibleItem] = React.useState<MapsBottomTasksToPlannedItem | null>(null);

  const renderModal = () => {
    if (!visibleItem) return null;

    let desc = 'Planlanmamış Noktalar';

    const { geoMarketingFilters, points } = visibleItem;

    let getFilter = null;

    if (geoMarketingFilters.length > 0 && points?.length > 0) {
      getFilter = getGeomarketingFilter(geoMarketingFilters[0].geoMarketFilterId);
    }

    return (
      <Dialog open fullWidth maxWidth="md">
        <ModalContent>
          <ModalHeader title={visibleItem.name} description={desc} />
          <Box sx={{ maxHeight: 480, overflow: 'auto' }}>
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table>
                <TableHead sx={{ '& th': { bgcolor: 'background.neutral' } }}>
                  <TableRow>
                    <TableCell>Nokta ID</TableCell>
                    <TableCell>Nokta Adı</TableCell>
                    <TableCell align="center">Planlanmış Task</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleItem.points.map((point) => (
                    <TableRow key={point.id}>
                      <TableCell width={140}>{point.id}</TableCell>
                      <TableCell width={280}>{point.name}</TableCell>
                      <TableCell width={140} align="center">
                        <Chip size="small" variant="ghost" label={point.createdTaskCount} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ModalContent>
        <ModalFooter
          onCloseTitle="Kapat"
          onClose={() => setVisibleItem(null)}
          contentLeft={
            <Stack flex={1} direction="row" alignItems="center" spacing={1}>
              {getFilter && (
                <Chip color="primary" variant="ghost" label={`Filtre: ${getFilter?.label}`} />
              )}
              <Chip
                color="error"
                variant="ghost"
                label={`Tekrarlanabilir Task Sayısı: ${visibleItem.maxItemCount}`}
              />
            </Stack>
          }
        />
      </Dialog>
    );
  };

  return (
    <>
      <Header />
      <TableContent tableHeight={tableHeight} setVisibleItem={setVisibleItem} />
      {renderModal()}
    </>
  );
}
