import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import { WmsNameEnums } from 'src/types';
import useMapEventsStore from '../map-events';
import { WmsLayer } from '../map-events/types';
import axios, { endpoints } from 'src/utils/axios';
import useBaseWorkspaceStore from '../base-workspaces';
import useMapAsideTeamsStore from '../map-aside-teams';
import useMapAsidePoolsStore from '../map-aside-pools';
import { TaskItemAssignmentData } from '../tasks/types';
import useMapAsidePersonsStore from '../map-aside-persons';
import { BASE_API, WMS_SERVER_URL } from 'src/config-global';
import { LayerTypes } from 'src/libs/map/helpers/enums/layer.enum';
import { SourceTypes } from 'src/libs/map/helpers/enums/source.enum';
import { MapLibHelper } from 'src/libs/mapLibInterface/map.lib.helper';
import { MapActionsStore, PointsAllocationRequestPayload } from './types';
import { MapPointSearchResponseListItem } from '../map-aside-points/types';
import { getMapsBottomAllTasksToPlanned } from '../maps-bottom-tasks-to-planned';
import useMapsBottomTasksStore, { getMapsAllBottomTasks } from '../maps-bottom-tasks';

const initialState: MapActionsStore = {
  map: null,
  fullScreen: false,
  polygonActive: false,
  drawingManager: null,
  //
  requestLoading: false,
};

const useMapActionsStore = create<MapActionsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => {
    set((s) => ({ ...s, polygonActive: false, selectedsPoints: [] }));
  },
}));

export default useMapActionsStore;

const { getState, setState } = useMapActionsStore;

export const mapActionsCenteredMap = (points: MapPointSearchResponseListItem[]) => {
  const state = getState();
  const bounds = new window.google.maps.LatLngBounds();

  points.forEach((point) => {
    bounds.extend(new window.google.maps.LatLng(point.COORDINATE_Y, point.COORDINATE_X));
  });

  state.map?.fitBounds(bounds);
};

export const handlePointsAllocation = async () => {
  setState({ requestLoading: true });
  const mapEventsStore = useMapEventsStore.getState();
  const mapAsidePoolsStore = useMapAsidePoolsStore.getState();
  const mapAsideTeamsStore = useMapAsideTeamsStore.getState();
  const mapsBottomTasksStore = useMapsBottomTasksStore.getState();
  const mapAsidePersonsStore = useMapAsidePersonsStore.getState();

  const assignments: TaskItemAssignmentData[] = [];
  const assignmentsLength =
    mapAsidePersonsStore.selectedItems.length +
    mapAsideTeamsStore.selectedItems.length +
    mapAsidePoolsStore.selectedItems.length;

  for (let i = 0; i < assignmentsLength; i++) {
    assignments.push({
      employeeId: mapAsidePersonsStore.selectedItems[i]?.id || '',
      employeeName: mapAsidePersonsStore.selectedItems[i]?.userName || '',
      //
      teamId: mapAsideTeamsStore.selectedItems[i]?.id || '',
      teamName: mapAsideTeamsStore.selectedItems[i]?.name || '',
      //
      poolId: mapAsidePoolsStore.selectedItems[i]?.id || '',
      poolName: mapAsidePoolsStore.selectedItems[i]?.name || '',
      roleId: '',
      roleName: '',
    });
  }

  const selectedTasks = mapsBottomTasksStore.response.list
    .filter((task) => {
      return (
        Object.entries(mapsBottomTasksStore.rowSelection)
          // Numbere çevirmemizin sebebi, tabloda getRowId fonksiyonu ile id'yi stringe çeviriyoruz.
          // Çünkü tablo bizden string bir id bekliyor.
          .map(([id]) => Number(id))
          .includes(task.id)
      );
    })
    .map((item) => ({ id: item.id, name: item.name }));

  const payload: PointsAllocationRequestPayload = {
    assignments,
    tasks: selectedTasks,
    points: mapEventsStore.response.features.map((point) => ({
      id: point.id,
      name: point.properties.sign_name,
      COORDINATE_X: point.geometry.coordinates[0],
      COORDINATE_Y: point.geometry.coordinates[1],
    })),
  };

  try {
    await axios.post(endpoints.task.pointsAllocation, payload);
    await getMapsAllBottomTasks();
    await getMapsBottomAllTasksToPlanned();
    // Clear selected items
    useMapEventsStore.getState().reset();
    useMapAsideTeamsStore.setState({ selectedItems: [] });
    useMapAsidePoolsStore.setState({ selectedItems: [] });
    useMapAsidePersonsStore.setState({ selectedItems: [] });
    useMapsBottomTasksStore.getState().onRowSelectionChange({});
    enqueueSnackbar('Seçilenler başarıyla atanmıştır.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
  } finally {
    setState({ requestLoading: false });
  }
};

export async function createSld(viewparams?: string) {
  const { baseWorkspace } = useBaseWorkspaceStore.getState();

  const sldPayload = [
    {
      layer_name: `gvg:${baseWorkspace?.workspaceId}`,
      styles: [
        {
          name: 'Style',
          pointStacker: false,
          rules: [
            {
              name: 'Point Style',
              max: 0,
              min: 0,
              point_symbolizers: [
                {
                  size: 16,
                  symbol: 'circle',
                  fill: { color: '#F04438', opacity: 1 },
                  stroke: { opacity: 1, width: 1, color: '#FFFFFF' },
                },
              ],
            },
            {
              name: 'Text Style',
              max: 0,
              min: 0,
              text_symbolizers: [
                {
                  font_size: 14,
                  font_family: 'DejaVu Sans',
                  font_style: '',
                  font_weight: '1',
                  fill: {
                    color: '#9751F2',
                    opacity: 1,
                  },
                  halo: {
                    radius: 1,
                    opacity: 0,
                    color: '#9751F2',
                  },
                  anchor_point: [0, 0],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  try {
    const url = `${BASE_API}geoserver-manager/api/v2/gsm/sld/store`;
    const token = localStorage.getItem('token');

    const res = await axios.post(url, sldPayload);

    if (!res.data) {
      throw new Error('SLD oluşturulamadı.');
    }
    const sldURL = `${BASE_API}geoserver-manager/api/v2/gsm/sld/${res.data}`;

    const sld = `${sldURL}?token=${token}`;

    const wmsLayer: WmsLayer = {
      opacity: 1,
      active: true,
      visible: true,
      title: 'Map Points',
      name: WmsNameEnums.MAP_POINTS,
      disabledInLayerSwitcher: true,
      layerType: LayerTypes.TileLayer,
      source: {
        crossOrigin: 'Anonymous',
        url: WMS_SERVER_URL as string,
        sourceType: SourceTypes.TileWMS,
        params: {
          SLD: sld,
          viewparams: viewparams || '',
          LAYERS: sldPayload[0].layer_name,
        },
      },
    };

    MapLibHelper.wmsLayerSil(WmsNameEnums.MAP_POINTS);

    MapLibHelper.wmsLayerEkle(wmsLayer);
  } catch (error) {
    throw error;
  }
}
