export enum SourceTypes {
    TileWMS = 'TileWMS',
    VectorSource = 'VectorSource',
    ImageWMS='ImageWMS',
    XYZ = 'XYZ',
    Stamen = 'Stamen',
    OSM = 'OSM',
    TileImage='TileImage',
    WMTS='WMTS',
    VectorTileSource='VectorTileSource',
    BingMaps='BingMaps'
  }