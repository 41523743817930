import React from 'react';
import List from 'rc-virtual-list';
import { Box, Card, Checkbox, Stack, Typography } from '@mui/material';
//
import { endpoints } from 'src/utils/axios';
import CalendarFiltersContentOptions from './options';
import CalendarFiltersContentOptionsItem from './options-item';
import useCalendarLayoutStore from 'src/store/calendar-layout';
import { UsersResponseType, UsersType } from 'src/store/users/types';
import { TeamsResponseType, TeamsType } from 'src/store/teams/types';
import { PoolsResponseType, PoolsType } from 'src/store/pools/types';
import { CalendarLayoutStore } from 'src/store/calendar-layout/types';

const size = 200;
const defaultValue = {
  status: true,
  message: null,
  data: { count: 0, items: [], totalPages: 0, totalItems: 0 },
};

interface ComponentProps<T> {
  data: T;
}

type OnChangeChecked = {
  checked: boolean;
  item: UsersType | TeamsType | PoolsType;
  filtersName: 'selectedsEmployees' | 'selectedsTeams' | 'selectedsPools';
};

type Props = {
  tempFilters: CalendarLayoutStore['filters'];
  setTempFilters: React.Dispatch<React.SetStateAction<CalendarLayoutStore['filters']>>;
};

export default function CalendarFiltersContent(props: Props) {
  const { tempFilters, setTempFilters } = props;
  const storeFiltersStatuses = useCalendarLayoutStore((s) => s.filters.statuses);

  const onChangeChecked = React.useCallback((body: OnChangeChecked) => {
    const { checked, filtersName, item } = body;

    setTempFilters((s) => {
      let selectedsData = [...s[filtersName]];

      if (checked) {
        selectedsData = selectedsData.filter((select) => select.id !== item.id);
      } else {
        selectedsData.push(item);
      }

      return { ...s, [filtersName]: selectedsData };
    });
  }, []);

  const onChangeStatus = React.useCallback((key: string) => {
    setTempFilters((s) => {
      const statuses = s.statuses.map((status) => {
        if (status.key === key) {
          return { ...status, checked: !status.checked };
        }
        return status;
      });

      return { ...s, statuses };
    });
  }, []);

  return (
    <Stack flex={1} spacing={2.5} p={2.5} bgcolor="background.neutral" overflow="auto">
      <CalendarFiltersContentOptions<ComponentProps<UsersResponseType>>
        title="Kişiler"
        placeholder="Kişi ara..."
        defaultValue={defaultValue}
        url={endpoints.users.list(size, 0, '')}
        renderData={(response) => {
          const dataLength = response.data.items.length;
          const display = dataLength > 0;
          const height = dataLength >= 4 ? 140 : dataLength * 36;

          return {
            display,
            children: (
              <List
                itemKey="id"
                height={height}
                itemHeight={36}
                style={{ marginTop: 12 }}
                data={response.data.items}
              >
                {(item, index) => {
                  const checked = Boolean(
                    tempFilters.selectedsEmployees.find((person) => person.id === item.id)
                  );
                  return (
                    <CalendarFiltersContentOptionsItem
                      key={index}
                      checked={checked}
                      name={item.fullName}
                      onChange={() => {
                        onChangeChecked({ checked, filtersName: 'selectedsEmployees', item });
                      }}
                    />
                  );
                }}
              </List>
            ),
          };
        }}
      />
      <CalendarFiltersContentOptions<ComponentProps<TeamsResponseType>>
        title="Ekipler"
        placeholder="Ekip ara..."
        defaultValue={defaultValue}
        url={endpoints.teams.list(size, 0, '')}
        renderData={(response) => {
          const dataLength = response.data.items.length;
          const display = dataLength > 0;
          const height = dataLength >= 4 ? 140 : dataLength * 36;

          return {
            display,
            children: (
              <List
                itemKey="id"
                height={height}
                itemHeight={36}
                style={{ marginTop: 12 }}
                data={response.data.items}
              >
                {(item, index) => {
                  const checked = Boolean(
                    tempFilters.selectedsTeams.find((team) => team.id === item.id)
                  );
                  return (
                    <CalendarFiltersContentOptionsItem
                      key={index}
                      name={item.name}
                      checked={checked}
                      onChange={() => {
                        onChangeChecked({ checked, filtersName: 'selectedsTeams', item });
                      }}
                    />
                  );
                }}
              </List>
            ),
          };
        }}
      />
      {/* <CalendarFiltersContentOptions<ComponentProps<PoolsResponseType>>
        title="Havuzlar"
        placeholder="Havuz ara..."
        defaultValue={defaultValue}
        url={endpoints.pools.list(size, 0, '')}
        renderData={(response) => {
          const dataLength = response.data.items.length;
          const display = dataLength > 0;
          const height = dataLength >= 4 ? 140 : dataLength * 36;

          return {
            display,
            children: (
              <List
                itemKey="id"
                height={height}
                itemHeight={36}
                style={{ marginTop: 12 }}
                data={response.data.items}
              >
                {(item, index) => {
                  const checked = Boolean(
                    tempFilters.selectedsPools.find((pool) => pool.id === item.id)
                  );
                  return (
                    <CalendarFiltersContentOptionsItem
                      key={index}
                      name={item.name}
                      checked={checked}
                      onChange={() => {
                        onChangeChecked({ checked, filtersName: 'selectedsPools', item });
                      }}
                    />
                  );
                }}
              </List>
            ),
          };
        }}
      /> */}
      <Stack spacing={0.5}>
        <Typography variant="subtitle2">Task Statüleri</Typography>
        <Card sx={{ py: 0.5 }} component={Stack}>
          {storeFiltersStatuses.map((status) => {
            const checked = Boolean(
              tempFilters.statuses.find((s) => s.key === status.key)?.checked
            );
            return (
              <Stack
                spacing={0.5}
                key={status.key}
                direction="row"
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => onChangeStatus(status.key)}
              >
                <Checkbox sx={{ px: 0 }} disableRipple checked={checked} />
                <Box sx={{ width: 10, height: 10, borderRadius: 1, bgcolor: status.color }} />
                <Typography variant="subtitle2">{status.title}</Typography>
              </Stack>
            );
          })}
        </Card>
      </Stack>
    </Stack>
  );
}
