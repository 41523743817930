import { LoadingButton } from '@mui/lab';
import { CalendarEdit, People, Trash } from 'iconsax-react';
import { Stack, Popover, Divider, Backdrop, Typography, PopoverPosition } from '@mui/material';
//
import useCalendarStore from 'src/store/calendar';
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';

interface Props {
  hiddenFromPlan?: boolean;
  hiddenRemoveAssignment?: boolean;
  //
  onClose(): void;
  anchorEl: HTMLElement;
  anchorPosition: PopoverPosition;
  calendarRightItem: CalendarStoreResponseRightItem;
}

export default function ProgramItemActions(props: Props) {
  const {
    onClose,
    anchorEl,
    anchorPosition,
    calendarRightItem,
    //
    hiddenFromPlan,
    hiddenRemoveAssignment,
  } = props;

  const handleRemoveFromPlan = () => {
    useCalendarStore.setState((s) => {
      return {
        responseRight: s.responseRight
          .map((item) => {
            if (item.id === calendarRightItem.id) {
              if (item.disabled) {
                return null as unknown as CalendarStoreResponseRightItem;
              }

              return { ...item, status: TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED };
            }

            return item;
          })
          .filter(Boolean),
      };
    });
  };

  const handleRemoveAssignment = () => {
    useCalendarStore.setState((s) => {
      return {
        responseRight: s.responseRight
          .map((item) => {
            if (item.id === calendarRightItem.id) {
              if (item.disabled) {
                return null as unknown as CalendarStoreResponseRightItem;
              }

              return { ...item, status: TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED };
            }
            return item;
          })
          .filter(Boolean),
      };
    });
  };

  const handleDeleteTask = () => {
    useCalendarStore.setState((s) => {
      return {
        responseRight: s.responseRight
          .map((item) => {
            if (item.id === calendarRightItem.id) {
              if (item.disabled) {
                return null as unknown as CalendarStoreResponseRightItem;
              }

              return { ...item, status: TaskDefinitionFormStoreTaskStatusEnum.REMOVED };
            }
            return item;
          })
          .filter(Boolean),
      };
    });

    //
    onClose();
  };

  return (
    <Backdrop open sx={{ zIndex: 10 }}>
      <Popover
        open
        onClose={onClose}
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        anchorReference="anchorPosition"
        sx={{ transform: 'translateY(-16px)' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Stack
          sx={{
            width: 284,
            borderRadius: 1,
            overflow: 'hidden',
            bgcolor: 'background.default',
            '& > button': {
              py: 1,
              px: 2,
              gap: 0.25,
              color: 'text.primary',
              justifyContent: 'flex-start',
              '&:hover': { color: 'primary.main' },
            },
          }}
        >
          <Typography py={1.5} variant="subtitle2" fontWeight="700" textAlign="center">
            {calendarRightItem.point.name}
          </Typography>
          <Divider />
          {!hiddenFromPlan && (
            <LoadingButton
              startIcon={<CalendarEdit variant="Bulk" size={20} />}
              onClick={handleRemoveFromPlan}
            >
              Plandan Çıkar
            </LoadingButton>
          )}
          {!hiddenRemoveAssignment && (
            <LoadingButton
              onClick={handleRemoveAssignment}
              startIcon={<People variant="Bulk" size={20} />}
            >
              Atamayı Kaldır
            </LoadingButton>
          )}
          <LoadingButton onClick={handleDeleteTask} startIcon={<Trash variant="Bulk" size={20} />}>
            Taskı Sil
          </LoadingButton>
        </Stack>
      </Popover>
    </Backdrop>
  );
}
