import { alpha } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    lighter?: string;
    darker?: string;
    25?: string;
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    500_12?: string;
  }

  interface TypeBackground {
    neutral: string;
  }
}

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#8E33FF',
  dark: '#5119B7',
  darker: '#27097A',
  25: alpha('#8E33FF', 0.08),
  50: alpha('#8E33FF', 0.12),
  100: alpha('#8E33FF', 0.16),
  200: alpha('#8E33FF', 0.2),
  300: alpha('#8E33FF', 0.24),
  400: alpha('#8E33FF', 0.32),
  500: alpha('#8E33FF', 0.32),
  600: alpha('#8E33FF', 0.48),
};
const SECONDARY = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#00A76F',
  dark: '#007867',
  darker: '#004B50',
  25: alpha('#00A76F', 0.08),
  50: alpha('#00A76F', 0.12),
  100: alpha('#00A76F', 0.16),
  200: alpha('#00A76F', 0.2),
  300: alpha('#00A76F', 0.24),
  400: alpha('#00A76F', 0.32),
  500: alpha('#00A76F', 0.32),
  600: alpha('#00A76F', 0.48),
};
const INFO = {
  lighter: '#ffe5ec',
  light: '#fb6f92',
  main: '#ff006e',
  dark: '#f72585',
  darker: '#ff0054',
  25: alpha('#ff006e', 0.08),
  50: alpha('#ff006e', 0.12),
  100: alpha('#ff006e', 0.16),
  200: alpha('#ff006e', 0.2),
  300: alpha('#ff006e', 0.24),
  400: alpha('#ff006e', 0.32),
  500: alpha('#ff006e', 0.32),
  600: alpha('#ff006e', 0.48),
};
const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  25: alpha('#22C55E', 0.08),
  50: alpha('#22C55E', 0.12),
  100: alpha('#22C55E', 0.16),
  200: alpha('#22C55E', 0.2),
  300: alpha('#22C55E', 0.24),
  400: alpha('#22C55E', 0.32),
  500: alpha('#22C55E', 0.32),
  600: alpha('#22C55E', 0.48),
};
const WARNING = {
  lighter: '#FEF0C7',
  light: '#FEC84B',
  main: '#F79009',
  dark: '#93370D',
  darker: '#7A2E0E',
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
};
const ERROR = {
  lighter: '#FEE4E2',
  light: '#FDA29B',
  main: '#F04438',
  dark: '#912018',
  darker: '#7A271A',
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
};
const GREY = {
  25: '#FFFFFF',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
  500_8: alpha('#667085', 0.08),
  500_12: alpha('#667085', 0.12),
  500_16: alpha('#667085', 0.16),
  500_24: alpha('#667085', 0.24),
  500_32: alpha('#667085', 0.32),
  500_48: alpha('#667085', 0.48),
  500_56: alpha('#667085', 0.56),
  500_80: alpha('#667085', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[700], disabled: GREY[500] },
    background: { paper: GREY[50], default: GREY[25], neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: '#192439' },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
