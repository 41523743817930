import React from 'react';
import { useDrag } from 'react-dnd';
import { ArrowRight, Clock, InfoCircle } from 'iconsax-react';
import { Box, Grid, PopoverPosition, Stack, Tooltip, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';
import ProgramItemDetail from '../../customize/components/program-item-detail';
import ProgramItemActions from '../../customize/components/program-item-actions';

interface Props {
  index: number;
  item: CalendarStoreResponseRightItem;
}

type State = {
  anchorEl: HTMLElement | null;
  anchorPosition: PopoverPosition | null;
};

export default function CalendarBottomUnasignedTasksItem(props: Props) {
  const { index, item } = props;
  const [state, setState] = React.useState<State>({
    anchorEl: null,
    anchorPosition: null,
  });

  const id = index.toString();

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: DraggablesTypes.CALENDAR_ITEM,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.8 : 1,
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
      item: { task: item.task, point: item.point, unAssignedItem: item },
    }),
    [item]
  );

  const handleRightClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setState({
      anchorEl: event.currentTarget,
      anchorPosition: { left: event.clientX, top: event.clientY },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setState({ anchorPosition: null, anchorEl: event.currentTarget });
  };

  const onClose = () => {
    setState({ anchorEl: null, anchorPosition: null });
  };

  const emptyTaskItem = typeof item.id === 'string';

  return (
    <Grid item xs={12} md={6}>
      <Stack
        id={id}
        ref={drag}
        spacing={0.75}
        direction="row"
        alignItems="center"
        onClick={handleClick}
        onContextMenu={handleRightClick}
        onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
        sx={{
          opacity,
          pr: 0.75,
          zIndex: 1,
          height: 32,
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          bgcolor: 'background.default',
          position: state.anchorEl ? 'relative' : 'initial',
        }}
      >
        <Box width={10} height={1} bgcolor={`${item.relatedTaskItemId ? 'error' : 'info'}.main`} />
        <Clock variant="Broken" size={16} />
        <Typography
          flex={1}
          overflow="hidden"
          variant="subtitle2"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {item.point.name} - {item.task.name}
        </Typography>
        {item.relatedTaskItemId && (
          <>
            <Typography variant="caption" fontWeight="700" color="error.main">
              #{item.relatedTaskItemId}
            </Typography>
            <ArrowRight size={20} variant="Broken" />
          </>
        )}
        {emptyTaskItem ? (
          <Tooltip title="Oluşturulmamış Task">
            <InfoCircle size={20} variant="Bulk" />
          </Tooltip>
        ) : (
          <Typography variant="caption" fontWeight="700" color="text.primary">
            #{item.id}
          </Typography>
        )}
      </Stack>
      {state.anchorEl && (
        <>
          {state.anchorPosition ? (
            <ProgramItemActions
              onClose={onClose}
              hiddenFromPlan
              hiddenRemoveAssignment
              calendarRightItem={item}
              anchorEl={state.anchorEl}
              anchorPosition={state.anchorPosition}
            />
          ) : (
            <ProgramItemDetail
              onClose={onClose}
              anchorEl={state.anchorEl}
              calendarRightItem={item}
            />
          )}
        </>
      )}
    </Grid>
  );
}
