import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, Stack, Typography, useTheme } from '@mui/material';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import { Trash, ProgrammingArrows, UserRemove, CloseCircle, ArrowUp2 } from 'iconsax-react';

export const MapAsideSubWrapperMultipleProcessesSelectboxOptions: MultipleProcessesOptionType[] = [
  {
    id: 'delete',
    title: 'Taskı Sil',
    icon: <Trash size={16} variant="Bulk" />,
  },
  // {
  //   id: 'transfer',
  //   title: 'Transfer Et',
  //   icon: <ProgrammingArrows size={16} variant="Bulk" />,
  // },
  // {
  //   id: 'unasigned',
  //   title: 'Unasigned Yap',
  //   icon: <UserRemove size={16} variant="Bulk" />,
  // },
];

export type MultipleProcessesOptionType = {
  title: string;
  icon: JSX.Element;
  id: 'delete' | 'transfer' | 'unasigned';
};

interface Props {
  disabled?: boolean;
  loading?: boolean;
  onClick?(type: MultipleProcessesOptionType['id']): void;
}

export default function MapAsideSubWrapperMultipleProcessesSelectbox(props: Props) {
  const { onClick, disabled, loading } = props;
  const { palette } = useTheme();
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const anchorProps = useClick(isOpen, setOpen);
  const [selected, setSelected] = React.useState<MultipleProcessesOptionType | null>(null);

  return (
    <Stack direction="row" spacing={1.5}>
      <Card
        ref={ref}
        {...anchorProps}
        direction="row"
        component={Stack}
        alignItems="center"
        justifyContent="center"
        sx={{
          flex: 1,
          p: 0,
          pl: 1.5,
          pr: 0.75,
          borderRadius: 0.75,
          cursor: 'pointer',
          opacity: disabled || loading ? 0.5 : 1,
          pointerEvents: disabled || loading ? 'none' : 'auto',
          border: `1px solid ${isOpen ? palette.primary.lighter : palette.background.default}`,
        }}
      >
        <Typography flex={1} variant="subtitle2" fontSize={12}>
          {selected?.title || 'Toplu İşlemler'}
        </Typography>
        {selected ? (
          <CloseCircle
            size={16}
            variant="Bulk"
            color={palette.error.main}
            onClick={() => setSelected(null)}
          />
        ) : (
          <ArrowUp2 size={16} color={palette.text.secondary} />
        )}
      </Card>
      <ControlledMenu
        shift={0}
        align="start"
        anchorRef={ref}
        direction="bottom"
        theming={palette.mode}
        onClose={() => setOpen(false)}
        state={isOpen ? 'open' : 'closed'}
        menuStyle={{
          padding: 0,
          minWidth: 256,
          overflow: 'hidden',
          transform: 'translateY(7px)',
          border: `1px solid ${palette.primary.lighter}`,
        }}
      >
        {MapAsideSubWrapperMultipleProcessesSelectboxOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => setSelected(option)}
            style={{ padding: '6px 16px', gap: 12 }}
          >
            {option.icon}
            <Typography variant="subtitle2">{option.title}</Typography>
          </MenuItem>
        ))}
      </ControlledMenu>
      <LoadingButton
        size="small"
        loading={loading}
        variant="contained"
        disabled={!selected || disabled}
        onClick={() => {
          if (onClick) {
            onClick(selected!.id);
            setSelected(null);
          }
        }}
      >
        Uygula
      </LoadingButton>
    </Stack>
  );
}
