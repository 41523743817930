import React from 'react';
import 'moment/locale/tr';
import { jwtDecode } from 'jwt-decode';
import { LoadingButton } from '@mui/lab';
import { HelmetProvider } from 'react-helmet-async';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { Card, Stack, TextField, Typography } from '@mui/material';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/theme-dark.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

//
import axios from './utils/axios';
import CustomRouter from './routes';
import CustomThemeProvider from './theme';
import Loading from './components/loading';
import { HOST_AUTH } from './config-global';
import { getBaseWorkspace } from './store/base-workspaces';
import ProgressBar from './components/progress-bar/progress-bar';
import { MapProvider } from './contexts';

function App() {
  const token = localStorage.getItem('token');

  const [loading, setLoading] = React.useState(false);

  const handleLogin = async (event: any) => {
    event.preventDefault();

    const form = new FormData(event.target);

    const username = form.get('username') as string;
    const password = form.get('new-password') as string;

    setLoading(true);

    try {
      const res = await axios.post(HOST_AUTH, {
        realm: 'gvg',
        username,
        password,
      });
      const decoded: any = jwtDecode(res.data.access_token);
      if (!decoded.customerId) {
        enqueueSnackbar('Kullanıcıya ait bir müşteri bulunamadı', { variant: 'error' });
        return;
      }

      localStorage.setItem('token', res.data.access_token);

      await new Promise((resolve, reject) =>
        setTimeout(async () => {
          const baseWorkspace = await getBaseWorkspace();
          if (!baseWorkspace) {
            Object.entries(localStorage).forEach(([key]) => {
              if (!['maps-layout', 'theme-mode'].includes(key)) {
                localStorage.removeItem(key);
              }
            });
            reject('no-workspace');
          } else {
            resolve(true);
          }
        }, 1000)
      );
    } catch (error) {
      if (error === 'no-workspace') {
        enqueueSnackbar('Kullanıcıya ait bir Workspace bulunamadı.', { variant: 'error' });
      } else {
        enqueueSnackbar('Giriş yaparken bir hata oluştu', { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (token) {
      return (
        <MapProvider>
          <CustomRouter />
        </MapProvider>
      );
    }

    return (
      <Stack
        width={1}
        height="100dvh"
        component="form"
        alignItems="center"
        onSubmit={handleLogin}
        justifyContent="center"
      >
        <Stack component={Card} width={1} maxWidth={320} spacing={2}>
          <Typography variant="subtitle1" textAlign="center">
            Giriş Yap
          </Typography>
          <TextField fullWidth name="username" size="small" type="text" placeholder="E-Posta" />
          <TextField
            fullWidth
            name="new-password"
            size="small"
            type="password"
            placeholder="Parola"
          />
          <LoadingButton type="submit" variant="contained" color="primary" loading={loading}>
            Giriş Yap
          </LoadingButton>
        </Stack>
      </Stack>
    );
  };

  return (
    <React.Suspense fallback={<Loading />}>
      <CustomThemeProvider>
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <HelmetProvider>
              <ProgressBar />
              {renderContent()}
            </HelmetProvider>
          </SnackbarProvider>
        </ConfirmProvider>
      </CustomThemeProvider>
    </React.Suspense>
  );
}

export default App;
