import { create } from 'zustand';
//
import { DashboardStore } from './types';
import axios, { endpoints } from 'src/utils/axios';

const initialState: DashboardStore = {
  loading: false,
  response: {
    totalReport: {
      name: '',
      count: 0,
      ratio: null,
    },
    closedReport: {
      name: '',
      count: 0,
      ratio: null,
    },
    openReport: {
      name: '',
      count: 0,
      ratio: null,
    },
    taskReports: [
      {
        name: 'Veri Yok',
        count: 0,
        ratio: null,
      },
      {
        name: 'Veri Yok',
        count: 0,
        ratio: null,
      },
      {
        name: 'Veri Yok',
        count: 0,
        ratio: null,
      },
      {
        name: 'Veri Yok',
        count: 0,
        ratio: null,
      },
    ],
  },
};

const useDashboardStore = create<DashboardStore>(() => ({
  ...initialState,
}));

export default useDashboardStore;

const { setState } = useDashboardStore;

export const getDashboardData = async () => {
  setState({ loading: true });
  try {
    const res = await axios.get<DashboardStore['response']>(endpoints.task.dashboard);

    const taskReports =
      res.data.taskReports.length > 0 ? res.data.taskReports : initialState.response.taskReports;

    setState({
      response: { ...res.data, taskReports },
    });
  } catch (error) {
  } finally {
    setState({ loading: false });
  }
};
