import React from 'react';
import { CloseSquare } from 'iconsax-react';
import { Avatar, IconButton, Stack, Typography, useTheme } from '@mui/material';
//
import { mapAsideSubOnClosed } from 'src/store/maps-layout';
import { MarkerIllustration } from 'src/assets/illustrations';

interface Props {
  itemPoint?: any;
  itemPersonTeam?: any;
}

export default function MapAsideSubWrapperHeader(props: Props) {
  const { palette } = useTheme();
  const { itemPoint, itemPersonTeam } = props;

  return (
    <Stack direction="row" alignItems="center" sx={{ height: 40, bgcolor: 'background.default' }}>
      <Stack flex={1} direction="row" py={0.5} px={1.75} spacing={0.7} overflow="hidden">
        {itemPersonTeam ? (
          <Avatar sx={{ width: 24, height: 24 }} src="" />
        ) : (
          <MarkerIllustration iconColor={itemPoint?.markerColor} sx={{ width: 20, height: 20 }} />
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {itemPersonTeam?.name || itemPoint?.name}
        </Typography>
      </Stack>
      <IconButton disableRipple onClick={mapAsideSubOnClosed}>
        <CloseSquare size={24} variant="Bulk" color={palette.text.disabled} />
      </IconButton>
    </Stack>
  );
}
