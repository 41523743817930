import { MapProviderUtils } from "../map/providers/map.provider.utils";


export class MapLibInstanceHelper {
  private static _instance: MapLibInstanceHelper;

  private static _mapProviderUtils: MapProviderUtils;

  private constructor() {}
  public static getInstance(): MapLibInstanceHelper {
    if (!MapLibInstanceHelper._instance) {
      MapLibInstanceHelper._instance = new MapLibInstanceHelper();
    }

    return MapLibInstanceHelper._instance;
  }
  public static get MapProviderUtils(): MapProviderUtils {
    return this._mapProviderUtils;
  }
  public static set MapProviderUtils(value: MapProviderUtils) {
    this._mapProviderUtils = value;
  }
}
