import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
//
import CalendarAsideHeader from './header';
import CalendarAsideWrapperPointsWrapper from './points';
import useCalendarAsideStore from 'src/store/calendar-aside';
import useCalendarLayoutStore from 'src/store/calendar-layout';
import CalendarAsideWrapperPersonsTeamsPools from './persons-teams-pools';
import AsideWrapperTabs, { AsideWrapperTabsItem } from 'src/components/aside-tabs';

export const CalendarAsideWrapperWidths = {
  small: 48,
  large: 420,
};

export default function CalendarAsideWrapper() {
  const calendarLayoutStore = useCalendarLayoutStore(useShallow((s) => s));
  const { personsCount, teamsCount } = useCalendarAsideStore((s) => ({
    teamsCount: s.response.teams.length,
    personsCount: s.response.users.length,
  }));

  const tabs: AsideWrapperTabsItem[] = [
    { id: '0', label: 'Kişiler ve Ekipler', count: personsCount + teamsCount },
    { id: '1', label: 'Noktalar', count: 0, countHidden: true },
  ];
  const [activeTab, setActiveTab] = React.useState<AsideWrapperTabsItem>(tabs[0]);

  const handleTabChange = (tab: AsideWrapperTabsItem) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab.id === '0') {
      return <CalendarAsideWrapperPersonsTeamsPools />;
    }

    return <CalendarAsideWrapperPointsWrapper />;
  };

  return (
    <Stack
      sx={{
        flexShrink: 0,
        bgcolor: 'background.neutral',
        width: {
          xs: 1,
          md: calendarLayoutStore.asideVisible
            ? CalendarAsideWrapperWidths.large
            : CalendarAsideWrapperWidths.small,
        },
      }}
    >
      <CalendarAsideHeader
        visible={calendarLayoutStore.asideVisible}
        toggleVisible={() => {
          useCalendarLayoutStore.setState({ asideVisible: !calendarLayoutStore.asideVisible });
        }}
      />
      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: calendarLayoutStore.asideVisible ? 'flex' : 'none',
        }}
      >
        <Divider />
        <AsideWrapperTabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
        <Divider />
        {renderContent()}
      </Stack>
    </Stack>
  );
}
