import React from 'react';
import { Eye } from 'iconsax-react';
import {
  Box,
  Stack,
  Avatar,
  Checkbox,
  useTheme,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
//
import Members from './item-members';
import useFetch from 'src/hooks/use-fetch';
import { endpoints } from 'src/utils/axios';
import { UsersType } from 'src/store/users/types';
import { MapAsideTeamsListItem } from 'src/store/map-aside-teams/types';
import { onChangeMapAsideTeamsChecked } from 'src/store/map-aside-teams';

interface Props {
  checked: boolean;
  item: MapAsideTeamsListItem;
}

export default function MapAsideWrapperTaskAssignmentTeamsItem(props: Props) {
  const { palette } = useTheme();
  const { item, checked } = props;

  const [visible, setVisible] = React.useState(false);

  const res = useFetch<{ data: UsersType[] }>({
    initialStop: true,
    url: endpoints.teams.findListWithUsers(item.id),
    defaultValue: { data: [], count: null, status: true, message: null },
  });

  const onChangeVisible = async () => {
    if (res.loading) {
      return;
    }

    const newVisible = !visible;

    if (newVisible) {
      await res.getData();
    }

    setVisible(newVisible);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', borderRadius: 1 }}>
      <Stack direction="row" alignItems="center" height={58} px={1.25}>
        <Box>
          <Checkbox
            disableRipple
            sx={{ pl: 0 }}
            checked={checked}
            onChange={(e) => {
              onChangeMapAsideTeamsChecked(item, e.target.checked);
            }}
          />
        </Box>
        <Stack flex={1} direction="row" alignItems="center" spacing={1.5}>
          <Avatar sx={{ width: 32, height: 32 }} src="" />
          <Stack>
            <Typography variant="subtitle2">
              {item.name}
              {/* <Typography component="span" variant="subtitle2" color="success.main" pl={0.75}>
                %20
              </Typography> */}
            </Typography>
            <Typography variant="caption" color="text.disabled">
              {item.shortCode}
            </Typography>
          </Stack>
        </Stack>
        <IconButton size="small" onClick={onChangeVisible}>
          {res.loading ? (
            <CircularProgress size={24} />
          ) : (
            <Eye
              size={24}
              variant="Bulk"
              color={visible ? palette.primary.main : palette.text.disabled}
            />
          )}
        </IconButton>
      </Stack>
      <Members visible={visible} users={res.data.data} />
    </Box>
  );
}
