import React from 'react';
import { Box, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';
//
import { layoutMapConfig } from 'src/config-global';
import useMapsLayoutStore from 'src/store/maps-layout';
import useMapActionsStore from 'src/store/map-actions';
import { MapsLayoutBottomTabsEnum } from 'src/store/maps-layout/types';
import {
  MapBottomWrapperTasks,
  MapBottomWrapperTasksToPlanned,
} from 'src/components/map/bottom-wrapper';

const minHeight = 300;
const tableHeaderHeight = 96;
const totalHeadersHeight = layoutMapConfig.headerSize + layoutMapConfig.navbarSize;

export const resizeWindow = () => {
  // İçerik boyutunu değiştirip tekrar eski haline getirmek için
  // Bunu yapmamızın nedeni, Planby'nin içerik boyutunu hesaplamak için
  const originalWidth = window.innerWidth;
  const originalHeight = window.innerHeight;

  // Yüksekliği ve genişliği azalt
  window.dispatchEvent(new Event('resize'));
  document.body.style.width = `${originalWidth - 1}px`;
  document.body.style.height = `${originalHeight - 1}px`;

  // 1 saniye sonra eski haline getir
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
    document.body.style.width = '100%';
    document.body.style.height = '100%';
  }, 1000);
};

export default function LayoutMapBottom() {
  const mapsLayoutStore = useMapsLayoutStore(useShallow((s) => s));
  const mapActionsStore = useMapActionsStore(useShallow((s) => s));
  const contentHeight = (window.innerHeight - totalHeadersHeight) / 2.5;
  const [scrollHeight, setScrollHeight] = React.useState(contentHeight);
  const [wrapperHeight, setWrapperHeight] = React.useState(contentHeight);
  const [visibleSupportLine, setVisibleSupportLine] = React.useState(false);

  const tableHeight = wrapperHeight - tableHeaderHeight;

  React.useEffect(() => {
    // Temizleme işlemi
    return () => {
      document.body.style.width = '100%';
      document.body.style.height = '100%';
    };
  }, []);

  const handleResize = React.useCallback(
    (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
      setScrollHeight(size.height);

      //
      resizeWindow();
    },
    []
  );

  const handleResizeStop = React.useCallback(
    (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
      setWrapperHeight(size.height);
    },
    []
  );

  const newMinHeight = mapActionsStore.fullScreen ? 0 : minHeight;
  const newScrollHeight = mapActionsStore.fullScreen ? 0 : scrollHeight;

  const renderContent = () => {
    if (mapsLayoutStore.bottomActiveTab === MapsLayoutBottomTabsEnum.TASKS) {
      return <MapBottomWrapperTasks tableHeight={tableHeight} />;
    }

    return <MapBottomWrapperTasksToPlanned tableHeight={tableHeight} />;
  };

  return (
    <Resizable
      width={Infinity}
      resizeHandles={['n']}
      onResize={handleResize}
      height={newScrollHeight}
      onResizeStop={handleResizeStop}
      minConstraints={[Infinity, newMinHeight]}
      maxConstraints={[Infinity, window.innerHeight - totalHeadersHeight]}
      handle={(_, ref) => {
        return (
          <Stack
            ref={ref}
            className="react-resizable-handle"
            onMouseEnter={() => setVisibleSupportLine(true)}
            onMouseLeave={() => setVisibleSupportLine(false)}
            style={{
              top: 5,
              height: 8,
              width: '100%',
              cursor: 'ns-resize',
              alignItems: 'center',
              placeContent: 'center',
            }}
          >
            {visibleSupportLine && (
              <Box sx={{ height: 8, width: 200, borderRadius: 1, bgcolor: 'background.neutral' }} />
            )}
          </Stack>
        );
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          overflowY: 'hidden',
          minHeight: newMinHeight,
          height: newScrollHeight,
        }}
      >
        {renderContent()}
      </Box>
    </Resizable>
  );
}
