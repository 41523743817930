import React from 'react';
//
import LayoutMap from 'src/layouts/map';

//
const MapPage = React.lazy(() => import('src/pages/map'));

export const mapRoutes = [
  {
    path: 'map',
    element: <LayoutMap />,
    children: [{ element: <MapPage />, index: true }],
  },
];
