import React from 'react';
import { useShallow } from 'zustand/react/shallow';
//
import useCalendarStore from 'src/store/calendar';
import {
  CalendarAsideResponseTaskMap,
  CalendarAsideResponseItemPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  task: CalendarAsideResponseTaskMap;
  point: CalendarAsideResponseItemPoints;
};

export default function useRemainingCountCalc(props: Props) {
  const calendarStore = useCalendarStore(useShallow((s) => s));
  const { task, point } = props;

  const createdCount = React.useMemo(() => {
    return calendarStore.responseRight.filter((item) => {
      return item.originItems?.pointId === point.id && item.originItems?.taskId === task.id;
    }).length;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarStore.responseRight]);

  return task.count - point.createdTaskCount - createdCount;
}
