import React from 'react';
import { Stack, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import Collapse from './collapse';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';
import { MapAsidePersonsListItem } from 'src/store/map-aside-persons/types';

export default function MapAsideSubWrapperPersonTeamDetailCollapses() {
  const { personDetail, personDetailPoints } = useMapAsideSubPersonsStore((s) => ({
    personDetail: s.personDetail,
    personDetailPoints: s.personDetailPoints,
  }));

  const fetchData = () => {};

  return (
    <InfiniteScroll
      hasMore={false}
      next={fetchData}
      dataLength={personDetailPoints.length}
      scrollableTarget="person-team-detail-scrollable-div"
      loader={
        <Stack height={20} overflow="hidden" alignItems="center">
          <CircularProgress size={20} />
        </Stack>
      }
    >
      {personDetailPoints.map((point, index) => (
        <Collapse
          key={index}
          point={point}
          personDetail={personDetail as MapAsidePersonsListItem}
        />
      ))}
    </InfiniteScroll>
  );
}
