export enum MapsLayoutEnum {
  L = 'L',
  U = 'U',
}

export enum MapsLayoutBottomTabsEnum {
  TASKS = 'TASK',
  TASKS_TO_PLANNED = 'TASKS_TO_PLANNED',
}
export interface MapsLayoutStore {
  activeType: MapsLayoutEnum;
  bottomActiveTab: MapsLayoutBottomTabsEnum;
  asideSubVisible: 'person' | 'point' | 'pool' | null;
}
