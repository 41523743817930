import React from 'react';
import { Eye } from 'iconsax-react';
import {
  Box,
  Stack,
  Avatar,
  Checkbox,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
//
import { StyledBadge } from 'src/layouts/dashboard/navbar/profile-box';
import { MapAsidePoolsListItem } from 'src/store/map-aside-pools/types';
import { onChangeMapAsidePoolsChecked } from 'src/store/map-aside-pools';
import useMapAsideSubPoolsStore, { getMapAsideSubPools } from 'src/store/map-aside-sub-pools';

interface Props {
  checked: boolean;
  item: MapAsidePoolsListItem;
}

export default function MapAsideWrapperTaskAssignmentPoolsItem(props: Props) {
  const { item, checked } = props;
  const { poolDetail, loading } = useMapAsideSubPoolsStore((s) => ({
    loading: s.loading,
    poolDetail: s.poolDetail,
  }));

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 1.25,
        flexShrink: 0,
        minHeight: 58,
        borderRadius: 1,
        bgcolor: 'background.default',
      }}
    >
      <Box>
        <Checkbox
          disableRipple
          sx={{ pl: 0 }}
          checked={checked}
          onChange={(e) => {
            onChangeMapAsidePoolsChecked(item, e.target.checked);
          }}
        />
      </Box>
      <Stack flex={1} direction="row" alignItems="center" spacing={1.5}>
        <StyledBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Avatar sx={{ width: 32, height: 32 }} src="" />
        </StyledBadge>
        <Stack flex={1}>
          <Typography variant="subtitle2">{item.name}</Typography>
          <Typography variant="caption" color="text.disabled">
            {item.shortCode}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {/* <Chip size="small" variant="ghost" label="20%" /> */}
        <IconButton
          size="small"
          disabled={Boolean(loading)}
          onClick={() => getMapAsideSubPools(item)}
          color={poolDetail?.id === item.id ? 'primary' : 'default'}
        >
          {loading === item.id ? <CircularProgress size={24} /> : <Eye variant="Bulk" size={24} />}
        </IconButton>
      </Stack>
    </Stack>
  );
}
