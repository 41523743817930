import * as React from 'react';
import { Box, Divider } from '@mui/material';
//
import CalendarAsideTeamsWrapper from './teams';
import CalendarAsidePersonsWrapper from './persons';
import CalendarAsideItemsWrapperEmpty from './empty';
import useCalendarAsideStore from 'src/store/calendar-aside';
import { AsideWrapperTabsItem } from 'src/components/aside-tabs';
import AsideWrapperTabsCustom from 'src/components/aside-tabs-custom';

export default function CalendarAsideWrapperPersonsTeamsPools() {
  const { personsCount, teamsCount } = useCalendarAsideStore((s) => ({
    teamsCount: s.response.teams.length,
    personsCount: s.response.users.length,
  }));

  const tabs = [
    { id: 'kisiler', label: 'Kişiler', count: personsCount },
    { id: 'ekipler', label: 'Ekipler', count: teamsCount },
    { id: 'havuz', label: 'Havuz', count: 0 },
  ];
  const [activeTab, setActiveTab] = React.useState<AsideWrapperTabsItem>(tabs[0]);

  const isKisiler = activeTab.id === 'kisiler';
  const isEkipler = activeTab.id === 'ekipler';
  const isHavuz = activeTab.id === 'havuz';

  const handleTabChange = (tab: AsideWrapperTabsItem) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (isKisiler) {
      return <CalendarAsidePersonsWrapper />;
    }

    if (isEkipler) {
      return <CalendarAsideTeamsWrapper />;
    }

    // if (isHavuz)
    return (
      <CalendarAsideItemsWrapperEmpty
        title="Seçili tüm tasklarınız planlandı!"
        description="Planlanmamış görevleriniz varsa, bu görevler bu alanda görünecektir. Ayrıca, takvimdeki
      herhangi bir kişiye atanmış bir görevi ‘Unplaned’ olarak işaretlediğinizde, görev otomatik
      olarak bu alana taşınacaktır."
      />
    );
  };

  return (
    <>
      <Box px={0.75}>
        <AsideWrapperTabsCustom
          tabs={tabs}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Box>
      <Divider />
      <Box
        id="persons-teams-pools-scrollable-div"
        sx={{
          p: 1.5,
          flex: 1,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { display: 'flex', flexDirection: 'column', gap: 1.25 },
        }}
      >
        {renderContent()}
      </Box>
    </>
  );
}
