import _ from 'lodash';
import moment from 'moment';
import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import requests from 'src/utils/requests';
import { UsersType } from '../users/types';
import axios, { endpoints } from 'src/utils/axios';
import { getWeekRangeStringArray } from 'src/utils';
import useCalendarAsideStore from '../calendar-aside';
import useCalendarLayoutStore from '../calendar-layout';
import { handleManuelPlan } from '../maps-bottom-tasks-to-planned';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';
import useCalendarAsideUnAssignedStore, {
  getCalendarAsideUnAssigned,
} from '../calendar-aside-unassigned';
import useCalendarAsideUnPlannedStore, {
  getCalendarAsideUnPlanned,
} from '../calendar-aside-unplanned';

import {
  CalendarStore,
  CalendarOnDnDMouseUp,
  CalendarCreateBulkPayload,
  CalendarStoreResponseLeftItem,
  CalendarStoreResponseRightItem,
} from './types';

const initialState: CalendarStore = {
  loading: false,
  responseLeft: [],
  responseRight: [],
  responseRightDatesVisited: [],
  createBulkLoading: false,
  //
  loadingWeekData: false,
  responseWeekData: {},
};

const useCalendarStore = create<CalendarStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useCalendarStore;

const { setState, getState } = useCalendarStore;

function responseTaskItemsFiltered(list: CalendarStoreResponseRightItem[]) {
  return list.filter((item) => {
    return ![
      TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED,
      TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED,
    ].includes(item.status);
  });
}

export const getCalendarData = async (date?: Date) => {
  const calendarLayoutStore = useCalendarLayoutStore.getState();

  const newDate = date || calendarLayoutStore.date;

  const calendarDate = moment(newDate).format('YYYY-MM-DD');

  useCalendarLayoutStore.setState({ date: newDate });

  setState({ loading: true, loadingWeekData: true });

  const teams = calendarLayoutStore.filters.selectedsTeams;
  const employees = calendarLayoutStore.filters.selectedsEmployees;

  const responseLeft: CalendarStoreResponseLeftItem[] = [];
  const responseRight: CalendarStoreResponseRightItem[] = [];

  try {
    for (const employee of employees) {
      responseLeft.push({
        uuid: employee.id,
        title: employee.fullName || employee.userName,
        origin: { employee, team: null, id: employee.id, type: 'EMPLOYEE' },
      });
      const taskItems = await requests.getTaskItemsForEmployee({ employee, calendarDate });

      responseRight.push(
        ...responseTaskItemsFiltered(taskItems.list).map((item) => ({
          ...item,
          // customize for calendar
          since: item.targetDate,
          channelUuid: employee.id,
          till: item.targetCompleteDate,
          disabled: Boolean(item.assignmentData.teamId),
        }))
      );
    }

    for (const team of teams) {
      const taskItems = await requests.getTaskItemsForTeam({ team, calendarDate });

      responseRight.push(
        ...responseTaskItemsFiltered(taskItems.list).map((item) => ({
          ...item,
          // customize for calendar
          disabled: false,
          channelUuid: team.id,
          since: item.targetDate,
          till: item.targetCompleteDate,
        }))
      );

      responseLeft.push({
        isOpen: true,
        uuid: team.id,
        groupTree: true,
        title: team.name,
        origin: { team, id: team.id, type: 'TEAM', employee: null },
      });

      const teamInUsers = await axios.get<{ data: UsersType[] }>(
        endpoints.teams.findListWithUsers(team.id)
      );

      for (const employee of teamInUsers.data.data) {
        responseLeft.push({
          parentChannelUuid: team.id,
          uuid: `${team.id}-${employee.id}`,
          title: employee.fullName || employee.userName,
          origin: { team, employee, id: employee.id, type: 'EMPLOYEE' },
        });

        const taskItemsEmployee = await requests.getTaskItemsForEmployee({
          calendarDate,
          employee: { ...employee, teams: [] },
        });

        responseRight.push(
          ...responseTaskItemsFiltered(taskItemsEmployee.list).map((item) => ({
            ...item,
            // customize for calendar
            disabled: true,
            since: item.targetDate,
            till: item.targetCompleteDate,
            channelUuid: `${team.id}-${employee.id}`,
          }))
        );
      }
    }

    setState({ responseLeft, responseRight });
    await getCalendarAsideUnPlanned();
    await getCalendarAsideUnAssigned();
  } catch (error) {
    enqueueSnackbar('Veriler getirilemedi.', { variant: 'error' });
  } finally {
    setState({ loading: false });
  }

  // Burası calendarın haftalık verilerini getirir
  getCalendarWeeksData();
};

export const calendarOnDnDMouseUp = (body: CalendarOnDnDMouseUp): Boolean => {
  return true;
};

export const calendarOnDnDSuccess = async (newItem: CalendarStoreResponseRightItem) => {
  const state = getState();

  // Hareket eden itemi heryerden sil.
  const responseRight = [...state.responseRight].filter((item) => item.id !== newItem.id);
  // Hareket eden itemin gittiği channel.
  const newChannel = state.responseLeft.find((item) => item.uuid === newItem.channelUuid);
  // Hareket eden itemin gittiği channelin kopyaları varsa bulacağız.
  const newChannelCopyOthers = state.responseLeft.filter((item) => {
    return item.origin.id === newChannel?.origin.id;
  });
  // Kopyaları olan her channela itemin kopyasını ekleyeceğiz.
  newChannelCopyOthers.forEach((channel) => {
    responseRight.push({
      ...newItem,
      channelUuid: channel.uuid,
      targetDate: newItem.since as string,
      targetCompleteDate: newItem.till as string,
      disabled: Boolean(channel.parentChannelUuid),
      assignmentData: {
        poolId: null,
        poolName: null,
        roleId: null,
        roleName: null,
        teamId: newChannel?.origin.team?.id || null,
        teamName: newChannel?.origin.team?.name || null,
        employeeId: newChannel?.origin.employee?.id || null,
        employeeName: newChannel?.origin.employee?.fullName || null,
      },
    });
  });

  // Yeni itemin hareketi sonrası yolculuk sürelerini hesapla.
  calendarPointsBetweenWayCalc(newItem);

  setState({ responseRight });
};

// ----------------------------------------------- WEEK PROCESS
export const getCalendarWeeksData = async () => {
  const calendarLayoutStore = useCalendarLayoutStore.getState();
  const weekDaysArr = getWeekRangeStringArray(calendarLayoutStore.date);
  const teams = calendarLayoutStore.filters.selectedsTeams;
  const users = calendarLayoutStore.filters.selectedsEmployees;

  const assignees = [
    ...users.map((user) => ({
      employeeId: user.id,
      teams: user.teams.map((team) => team.id),
    })),
    ...teams.map((team) => ({ teamId: team.id })),
  ];

  try {
    const res = await requests.assigneesTaskItems({
      dates: weekDaysArr,
      assignees,
    });

    setState({ responseWeekData: res, loadingWeekData: false });
  } catch (error) {
    enqueueSnackbar('Haftalık Veriler getirilemedi.', { variant: 'error' });
  }
};

// ----------------------------------------------- Calendar Items Request
export const calendarCreateBulk = async () => {
  setState({ createBulkLoading: true });
  const calendarAsideStore = useCalendarAsideStore.getState();

  const sendData = getCalendarCreateBulkPayloadData();

  try {
    await axios.post(`${endpoints.taskItem.createBulk}?planMode=MANUEL`, sendData);
    setState({ responseRight: [], responseRightDatesVisited: [] });
    await getCalendarData();
    await handleManuelPlan(Object.keys(calendarAsideStore.response.taskMap), true);
    enqueueSnackbar('Plan oluşturuldu.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Plan oluşturulamadı.', { variant: 'error' });
  } finally {
    setState({ createBulkLoading: false });
  }
};

export const getCalendarCreateBulkPayloadData = () => {
  const state = getState();
  const calendarAsideUnPlannedStore = useCalendarAsideUnPlannedStore.getState();
  const calendarAsideUnAssignedStore = useCalendarAsideUnAssignedStore.getState();

  // Bunların haricinde olanlar requeste gidecek.
  const filteredAccepted = [...state.responseRight].filter((item) => {
    return ![
      TaskDefinitionFormStoreTaskStatusEnum.COMPLETED,
      TaskDefinitionFormStoreTaskStatusEnum.POSTPONED,
      TaskDefinitionFormStoreTaskStatusEnum.CANCELLED,
    ].includes(item.status);
  });

  const filteredRightItems = filteredAccepted.filter((item) => {
    if (
      calendarAsideUnPlannedStore.response.list.some(
        (unPlannedItem) => unPlannedItem.id === item.id && unPlannedItem.status === item.status
      ) ||
      calendarAsideUnAssignedStore.response.list.some(
        (unAssignedItem) => unAssignedItem.id === item.id && unAssignedItem.status === item.status
      )
    ) {
      return false;
    }

    return true;
  });

  const sendData: CalendarCreateBulkPayload[] = [...filteredRightItems]
    .filter((item) => !item.disabled)
    .map((item) => {
      const newItem = {
        task: { id: item.task.id },
        point: { id: item.point.id },
        targetDate: item.since as string,
        assignmentData: item.assignmentData,
        targetCompleteDate: item.till as string,
        id: typeof item.id === 'string' ? null : item.id,
        relatedTaskItemId: item.relatedTaskItemId || null,
        status:
          item.status === TaskDefinitionFormStoreTaskStatusEnum.EMPTY
            ? TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED
            : item.status,
      };

      return newItem;
    });

  return sendData;
};

// ----------------------------------------------- Calendar Points Between Way Calculation
const calendarPointsBetweenWayCalc = (newItem: CalendarStoreResponseRightItem) => {
  const avaregeSpeed = 50; // km/saat
  const haversineDistance = (x1: number, y1: number, x2: number, y2: number) => {
    const R = 6371; // Dünya'nın yarıçapı (kilometre cinsinden)

    // Enlem ve boylamları radian cinsine çevir
    const dLat = ((x2 - x1) * Math.PI) / 180;
    const dLon = ((y2 - y1) * Math.PI) / 180;

    // Başlangıç noktası enlemi ve varış noktası enlemini radian cinsine çevir
    const x1Rad = (x1 * Math.PI) / 180;
    const x2Rad = (x2 * Math.PI) / 180;

    // Haversine formülü
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(x1Rad) * Math.cos(x2Rad);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Mesafe
    const distance = R * c;
    return distance;
  };

  const state = getState();
  const calendarLayoutStore = useCalendarLayoutStore.getState();
  //
  const filteredRightItems = [...state.responseRight]
    .filter((item) => {
      return ![
        TaskDefinitionFormStoreTaskStatusEnum.REMOVED,
        TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED,
        TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED,
      ].includes(item.status);
    })
    .filter((item) => {
      return (
        moment(item.since).format('DD-MM-YYYY') ===
        moment(calendarLayoutStore.date).format('DD-MM-YYYY')
      );
    });

  const currentChannel = [...state.responseLeft].find((item) => item.uuid === newItem.channelUuid);
  const currentChannelRightItems = [...filteredRightItems].filter((item) => {
    return item.channelUuid === currentChannel?.uuid;
  });

  const currentChannelRightItemsSorted = _.map(
    _.sortBy(currentChannelRightItems, (o) => moment(o.since).unix())
  );

  const currentChannelRightItemsSortedIndex = currentChannelRightItemsSorted.findIndex(
    (item) => item.id === newItem.id
  );

  const previousItem = currentChannelRightItemsSorted[currentChannelRightItemsSortedIndex - 1];
  const nextItem = currentChannelRightItemsSorted[currentChannelRightItemsSortedIndex + 1];

  if (previousItem) {
    const distance = haversineDistance(
      previousItem.point.COORDINATE_X,
      previousItem.point.COORDINATE_Y,
      newItem.point.COORDINATE_X,
      newItem.point.COORDINATE_Y
    );

    enqueueSnackbar(
      `${previousItem.point.name} - ${newItem.point.name} arası yolculuk süresi: ${Math.ceil(
        (distance / avaregeSpeed) * 60
      )} dakika (${distance.toFixed(1)} km)`,
      {
        autoHideDuration: 2500,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      }
    );
  }

  if (nextItem) {
    const distance = haversineDistance(
      newItem.point.COORDINATE_X,
      newItem.point.COORDINATE_Y,
      nextItem.point.COORDINATE_X,
      nextItem.point.COORDINATE_Y
    );

    enqueueSnackbar(
      `${newItem.point.name} - ${nextItem.point.name} arası yolculuk süresi: ${Math.ceil(
        (distance / avaregeSpeed) * 60
      )} dakika (${distance.toFixed(1)} km)`,
      {
        autoHideDuration: 2500,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      }
    );
  }
};
