import * as React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Grid, Stack } from '@mui/material';
//
import Loading from 'src/components/loading';
import useCalendarStore from 'src/store/calendar';
import CalendarBottomUnasignedTasksItem from './item';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';
import useCalendarAsideUnAssignedStore, {
  getCalendarAsideUnAssigned,
} from 'src/store/calendar-aside-unassigned';

export default function CalendarBottomUnasignedTasks() {
  const { responseRight, createBulkLoading } = useCalendarStore(useShallow((s) => s));
  const calendarAsideUnAssignedStore = useCalendarAsideUnAssignedStore(useShallow((s) => s));

  const nextFetch = () => {
    if (calendarAsideUnAssignedStore.nextLoading) {
      return;
    }

    useCalendarAsideUnAssignedStore.setState({ nextLoading: true });
    getCalendarAsideUnAssigned(true);
  };

  const responseRightUnAssigned = React.useMemo(() => {
    return [...responseRight].filter(
      (item) => item.status === TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED
    );
  }, [responseRight]);

  if (createBulkLoading) {
    return (
      <Stack flex={1} height={1} position="relative" overflow="hidden">
        <Loading size={30} />
      </Stack>
    );
  }

  return (
    <InfiniteScroll
      next={nextFetch}
      endMessage={null}
      scrollableTarget="scrollable-div"
      dataLength={calendarAsideUnAssignedStore.response.list.length}
      hasMore={calendarAsideUnAssignedStore.response.totalPages > calendarAsideUnAssignedStore.page}
      loader={
        calendarAsideUnAssignedStore.nextLoading ? (
          <Stack height={40} alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
    >
      <Grid container spacing={1.5}>
        {responseRightUnAssigned.map((item, index) => {
          return <CalendarBottomUnasignedTasksItem key={index} index={index} item={item} />;
        })}
      </Grid>
    </InfiniteScroll>
  );
}
