import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { useShallow } from 'zustand/react/shallow';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowDown2, ArrowUp2, Calendar } from 'iconsax-react';
//
import useCalendarStore from 'src/store/calendar';
import CalendarBottomUnasignedTasksToPlanner from './unasigned-tasks-to-planner';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';

interface Props {
  visible: boolean;
  toggleVisible(): void;
}

export default function CalendarBottomHeader(props: Props) {
  const { visible, toggleVisible } = props;
  const [visibleModal, setVisibleModal] = React.useState(false);
  const calendarStore = useCalendarStore(useShallow((s) => s));

  const unassignedTasks = React.useMemo(() => {
    const unAssignedInRightList = calendarStore.responseRight.filter((item) => {
      return item.status === TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED;
    });

    return unAssignedInRightList;
  }, [calendarStore.responseRight]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        fullWidth
        size="small"
        color="inherit"
        sx={{ pl: 19.75 }}
        onClick={toggleVisible}
        endIcon={
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{ px: 1.5, pr: 1, height: 24, bgcolor: 'background.neutral', borderRadius: 1 }}
          >
            <Typography variant="caption" fontWeight="600">
              {unassignedTasks.length}
            </Typography>
            {visible ? <ArrowDown2 size={14} /> : <ArrowUp2 size={14} />}
          </Stack>
        }
      >
        Unasigned Taskler
      </Button>
      <LoadingButton
        size="small"
        variant="contained"
        sx={{ minWidth: 150 }}
        disabled={unassignedTasks.length === 0}
        onClick={() => setVisibleModal(true)}
        endIcon={<Calendar variant="Bulk" size={18} />}
      >
        Hepsini Planla
      </LoadingButton>
      {visibleModal && (
        <CalendarBottomUnasignedTasksToPlanner onClose={() => setVisibleModal(false)} />
      )}
    </Stack>
  );
}
