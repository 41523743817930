import React from 'react';
import { CloseCircle } from 'iconsax-react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import { Card, IconButton, Radio, Stack, Typography, TextField, Autocomplete } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  //
  radioName: string;
  radioValue?: string;
  clearAll?(): void;
}

export default function RHFAutocompleteRadio<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { name, label, type, helperText, placeholder, radioName, radioValue, clearAll, ...other } =
    props;
  const { control, setValue, register, clearErrors } = useFormContext();

  const checked = name === radioValue;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Card>
            <Stack pb={2} direction="row" alignItems="center">
              <Typography flex={1} variant="subtitle1" fontWeight="500">
                {label}
              </Typography>
              {checked ? (
                <IconButton
                  color="error"
                  disableRipple
                  onClick={() => {
                    setValue(radioName, '');
                    setValue(name, []);
                    clearErrors();
                  }}
                >
                  <CloseCircle size={20} variant="Bulk" />
                </IconButton>
              ) : (
                <Radio
                  size="small"
                  disableRipple
                  name={radioName}
                  checked={checked}
                  onChange={() => {
                    setValue(radioName, name);
                    clearErrors();
                    clearAll?.();
                  }}
                />
              )}
            </Stack>
            <Autocomplete
              {...field}
              disabled={!checked}
              ref={register(name).ref} // overriding field.ref
              sx={{ bgcolor: checked ? 'transparent' : 'background.neutral' }}
              onChange={(event, newValue) => setValue(name, newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  error={!!error}
                  placeholder={placeholder}
                  helperText={error ? error?.message : helperText}
                  inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                />
              )}
              {...other}
            />
          </Card>
        );
      }}
    />
  );
}
