import React from 'react';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import { Box, Stack, Divider, Collapse, IconButton, Typography } from '@mui/material';
//
import { TaskItem, TaskItemPoint } from 'src/store/tasks/types';
import MapAsideSubWrapperPoolDetailListTaskItem from './task-item';

interface Props {
  tasks: TaskItem[];
  point: TaskItemPoint;
}

export default function MapAsideSubWrapperPoolDetailListItem(props: Props) {
  const { point, tasks } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <Typography variant="caption" fontWeight="600" color="text.secondary">
          {point.name}
        </Typography>
        <Divider sx={{ flex: 1 }} orientation="horizontal" />
        <IconButton size="small" onClick={() => setVisible(!visible)}>
          {visible ? <ArrowUp2 size={16} /> : <ArrowDown2 size={16} />}
        </IconButton>
      </Stack>
      <Collapse in={visible} unmountOnExit>
        <Stack spacing={0.75} pt={1.25}>
          {tasks.map((item) => {
            return <MapAsideSubWrapperPoolDetailListTaskItem key={item.id} item={item} />;
          })}
        </Stack>
      </Collapse>
    </Box>
  );
}
