import moment from 'moment';
import numeral from 'numeral';
import { Theme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
//
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';

export function shortenFilename(filename: string, maxLength: number) {
  // Dosya adının uzunluğunu alın
  const length = filename.length;

  // Dosya adı 5 karakterden uzunsa
  if (length > maxLength) {
    // Dosya adının ilk 5 karakterini ve son 5 karakterini alın
    const shortened = `${filename.substring(0, 5)}...` + filename.substring(length - 5);
    return shortened;
  }

  // Dosya adı 5 karakterden kısa veya eşitse, değiştirme yapmadan döndürün
  return filename;
}

export function fileSizeData(number: number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  function result(format: string, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
  }

  return result(format, '.0');
}

export const urlRegex =
  /((http|https):\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?/;

export const clipboardCopy = (text: string, infoText: string) => {
  navigator.clipboard.writeText(text).then(() => {
    enqueueSnackbar(infoText || 'Kopyalandı', { variant: 'success' });
  });
};

export const getWeekRangeStringArray = (startOfWeek: Date) => {
  const weekDays: string[] = [];
  let day = moment(startOfWeek).startOf('isoWeek');

  // foreach loop kullanarak hafta günlerini array'e ekliyorum.
  Array.from({ length: 7 }).forEach(() => {
    weekDays.push(day.format('YYYY-MM-DD'));
    day = day.add(1, 'days');
  });

  return weekDays;
};

export const getTaskItemCompleteDuration = (
  taskItems: CalendarStoreResponseRightItem[],
  theme: Theme
): { percent: number; percentColor: string } => {
  const filteredTaskItems = [...taskItems].filter((item) => {
    return ![
      TaskDefinitionFormStoreTaskStatusEnum.REMOVED,
      TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED,
      TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED,
    ].includes(item.status);
  });

  const totalComplateDuration = filteredTaskItems.reduce((acc, item) => {
    return acc + moment(item.till).diff(moment(item.since), 'minutes');
  }, 0);

  const maxComplateDuration = 480;
  const percent = Number(((totalComplateDuration / maxComplateDuration) * 100).toFixed(1));

  const percentColors = () => {
    if (percent >= 100) {
      return theme.palette.error.main;
    }

    if (percent >= 70) {
      return theme.palette.warning.main;
    }

    return theme.palette.success.main;
  };

  return { percent, percentColor: percentColors() };
};

export const dateQuarterMinAndHour = (date: Date) => {
  const newDate = moment(date);

  const minRounded = Math.ceil(newDate.get('minutes') / 15) * 15;

  newDate.set('minutes', minRounded);

  return newDate;
};
