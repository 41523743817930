export enum FrequencyUnits {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const dayLabelTranslate: Record<FrequencyUnits, string> = {
  [FrequencyUnits.DAY]: 'Gün',
  [FrequencyUnits.WEEK]: 'Hafta',
  [FrequencyUnits.MONTH]: 'Ay',
  [FrequencyUnits.YEAR]: 'Yıl',
};

const frequencyUnitOptions = [
  { label: dayLabelTranslate[FrequencyUnits.DAY], value: FrequencyUnits.DAY },
  { label: dayLabelTranslate[FrequencyUnits.WEEK], value: FrequencyUnits.WEEK },
  { label: dayLabelTranslate[FrequencyUnits.MONTH], value: FrequencyUnits.MONTH },
  // { label: dayLabelTranslate[FrequencyUnits.YEAR], value: FrequencyUnits.YEAR },
];

export default frequencyUnitOptions;
