import React from 'react';
import { enqueueSnackbar } from 'notistack';
import { Card, Stack, Dialog, TextField, Typography, Autocomplete, Chip } from '@mui/material';
//
import ModalFooter from '../footer';
import ModalHeader from '../header';
import ModalContent from '../content';
import useFetch from 'src/hooks/use-fetch';
import { endpoints } from 'src/utils/axios';
import { SelectedEmploye, SelectedTeam } from '.';
import { UsersResponseType } from 'src/store/users/types';
import { TeamsResponseType } from 'src/store/teams/types';
import { AutoPlanningAssignedPreviewState } from 'src/components/map/bottom-wrapper/tasks-to-planned/header';

interface Props {
  onClose(): void;
  data: AutoPlanningAssignedPreviewState['plannerAssignResponse'];
  //
  selectedsTeam: SelectedTeam;
  setSelectedsTeam: React.Dispatch<React.SetStateAction<SelectedTeam>>;
  selectedsEmployee: SelectedEmploye;
  setSelectedsEmployee: React.Dispatch<React.SetStateAction<SelectedEmploye>>;
}

const defaultValue = {
  status: true,
  message: null,
  data: { count: 0, items: [], totalPages: 0, totalItems: 0 },
};

export default function ModalAutoPlanningAssignedPreviewEmployeeTeamChoose(props: Props) {
  const {
    data,
    onClose,
    selectedsTeam,
    setSelectedsTeam,
    selectedsEmployee,
    setSelectedsEmployee,
  } = props;
  const searchTime = React.useRef<any>(null);

  const [searchTeam, setSearchTeam] = React.useState<string>('');
  const [searchEmployee, setSearchEmployee] = React.useState<string>('');

  const resTeams = useFetch<{ data: TeamsResponseType }>({
    defaultValue,
    url: endpoints.teams.findList,
    query: { page: '0', size: '10', search: searchTeam },
  });

  const resEmployees = useFetch<{ data: UsersResponseType }>({
    defaultValue,
    url: endpoints.users.findList,
    query: { page: '0', size: '10', search: searchEmployee },
  });

  const onChangeSearchTeam = (val: string, type: 'team' | 'employee') => {
    if (type === 'team') {
      setSearchTeam(val);
    } else {
      setSearchEmployee(val);
    }

    if (searchTime.current) {
      clearTimeout(searchTime.current);
    }
  };

  React.useEffect(() => {
    searchTime.current = setTimeout(() => resTeams.getData(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTeam]);

  React.useEffect(() => {
    searchTime.current = setTimeout(() => resEmployees.getData(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmployee]);

  const teamOptions = resTeams.data.data.items;
  const employeOptions = resEmployees.data.data.items;

  const selectedTeamIds = Object.values(selectedsTeam).map((item) => item.id);
  const selectedEmployeeIds = Object.values(selectedsEmployee).map((item) => item.id);

  return (
    <Dialog open fullWidth maxWidth="md">
      <ModalHeader title="Kişi ve Takım Atamaları" />
      <ModalContent>
        <Stack spacing={1}>
          {data.map((emptyItem, index) => {
            const selectedTeam = selectedsTeam?.[emptyItem.assignee_id];
            const selectedEmploye = selectedsEmployee?.[emptyItem.assignee_id];

            const selectedName = selectedTeam?.name || selectedEmploye?.fullName;

            return (
              <Card
                spacing={1}
                direction="row"
                component={Stack}
                alignItems="center"
                sx={{ borderRadius: 1 }}
                key={emptyItem.assignee_id}
              >
                <Stack flex={1} direction="row" spacing={1}>
                  <Typography variant="subtitle2" fontWeight="700">{`Rota ${
                    index + 1
                  }`}</Typography>
                  {selectedName && (
                    <Chip size="small" variant="ghost" color="primary" label={selectedName} />
                  )}
                </Stack>
                <Stack flex={1} direction="row" alignItems="center" spacing={1}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={teamOptions}
                    loading={resTeams.loading}
                    value={selectedTeam || null}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={searchTeam || 'Takım bulunamadı'}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionDisabled={(option) => selectedTeamIds.includes(option.id)}
                    renderInput={(i) => (
                      <TextField
                        {...i}
                        value={searchTeam}
                        placeholder="Takım ara..."
                        onBlur={() => onChangeSearchTeam('', 'team')}
                        onChange={(e) => onChangeSearchTeam(e.target.value, 'team')}
                      />
                    )}
                    onChange={(_, value) => {
                      // Seçilmiş kişiyi sıfırla
                      enqueueSnackbar('Seçilmiş kişi sıfırlandı.', { variant: 'info' });
                      setSelectedsEmployee((prev) => {
                        const newPrev = { ...prev };

                        delete newPrev[emptyItem.assignee_id];

                        return newPrev;
                      });

                      if (value) {
                        setSelectedsTeam((prev) => ({
                          ...prev,
                          [emptyItem.assignee_id]: value,
                        }));
                      } else {
                        setSelectedsTeam((prev) => {
                          const newPrev = { ...prev };

                          delete newPrev[emptyItem.assignee_id];

                          return newPrev;
                        });
                      }
                    }}
                  />
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={employeOptions}
                    loading={resEmployees.loading}
                    noOptionsText="Kişi bulunamadı"
                    value={selectedEmploye || null}
                    getOptionLabel={(option) => option.fullName || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionDisabled={(option) => selectedEmployeeIds.includes(option.id)}
                    renderInput={(i) => (
                      <TextField
                        {...i}
                        value={searchEmployee}
                        placeholder={searchEmployee || 'Kişi ara...'}
                        onBlur={() => onChangeSearchTeam('', 'employee')}
                        onChange={(e) => onChangeSearchTeam(e.target.value, 'employee')}
                      />
                    )}
                    onChange={(_, value) => {
                      // Seçilmiş takımı sıfırla
                      enqueueSnackbar('Seçilmiş takım sıfırlandı.', { variant: 'info' });
                      setSelectedsTeam((prev) => {
                        const newPrev = { ...prev };

                        delete newPrev[emptyItem.assignee_id];

                        return newPrev;
                      });

                      if (value) {
                        setSelectedsEmployee((prev) => ({
                          ...prev,
                          [emptyItem.assignee_id]: value,
                        }));
                      } else {
                        setSelectedsEmployee((prev) => {
                          const newPrev = { ...prev };

                          delete newPrev[emptyItem.assignee_id];

                          return newPrev;
                        });
                      }
                    }}
                  />
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </ModalContent>
      <ModalFooter onClose={onClose} onCloseTitle="Tamam" />
    </Dialog>
  );
}
