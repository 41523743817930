import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useShallow } from 'zustand/react/shallow';
import { CalendarTick, Filter } from 'iconsax-react';
import { alpha, Box, Stack, Tooltip, useTheme } from '@mui/material';
//
import useCalendarLayoutStore from 'src/store/calendar-layout';
import useCalendarStore, { calendarCreateBulk } from 'src/store/calendar';

interface Props {}

export default function LayoutCalendarOptionsRightSide(props: Props) {
  const { palette } = useTheme();
  const calendarStore = useCalendarStore(useShallow((s) => s));
  const calendarLayoutStore = useCalendarLayoutStore(useShallow((s) => s));
  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        onClick={() => useCalendarLayoutStore.setState({ filtersVisible: true })}
        sx={{
          px: 1,
          height: 36,
          borderRadius: 1,
          cursor: 'pointer',
          bgcolor: 'background.paper',
          border: `1px solid ${alpha(palette.grey[500], 0.12)}`,
        }}
      >
        <Tooltip title="Filtre">
          <Filter variant="Bulk" size={20} />
        </Tooltip>
        <Stack direction="row" spacing={1}>
          {calendarLayoutStore.filters.statuses.map((status, index) => {
            return (
              <Tooltip key={index} title={status.title}>
                <Box
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: 2,
                    bgcolor: status.color,
                    opacity: status.checked ? 1 : 0.1,
                  }}
                ></Box>
              </Tooltip>
            );
          })}
        </Stack>
      </Stack>
      <LoadingButton
        variant="contained"
        loadingPosition="end"
        onClick={calendarCreateBulk}
        loading={calendarStore.createBulkLoading}
        endIcon={<CalendarTick variant="Bulk" size={20} />}
      >
        Planı Kaydet
      </LoadingButton>
    </>
  );
}
