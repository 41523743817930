import { ChannelWithPosition, DragMouseUp } from '@nessprim/planby-pro/dist/Epg/helpers/types';
//
import { UsersType } from '../users/types';
import { TeamsType } from '../teams/types';
import { TaskItemAssignmentData } from '../tasks/types';
import { AssigneesTaskItemsResponse } from 'src/utils/requests/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';

export type CalendarStore = {
  loading: boolean;
  createBulkLoading: boolean;
  responseRightDatesVisited: string[];
  responseLeft: CalendarStoreResponseLeftItem[];
  responseRight: CalendarStoreResponseRightItem[];
  //
  loadingWeekData: boolean;
  responseWeekData: AssigneesTaskItemsResponse;
};

export type CalendarStoreResponse = {
  totalPages: number;
  totalElements: number;
  list: CalendarStoreResponseRightItem[];
};

export type CalendarStoreResponseRightItem = {
  id: number | string; // Soldan empty itemleri sürüklerken takvime ID alanını string yapırıyoruz.
  targetDate: string;
  createdDate: string;
  name: string | null;
  taskTemplateId: number;
  taskTemplateName: string;
  targetCompleteDate: string;
  rejectReasonId: number | null;
  relatedTaskItemId: number | null;
  assignmentData: TaskItemAssignmentData;
  type: CalendarStoreResponseRightItemTypesEnum;
  status: TaskDefinitionFormStoreTaskStatusEnum;
  task: { id: number; name: string; completeDuration: number };
  point: {
    id: string;
    name: string;
    COORDINATE_X: number;
    COORDINATE_Y: number;
    createdTaskCount: number;
  };
  // customize for calendar
  till?: string;
  since?: string;
  index?: number;
  disabled: boolean;
  channelUuid?: string;
  taskMaxCount?: number;
  channelIndex?: number;
  channelPosition?: ChannelWithPosition['position'];
  originItems?: {
    taskId: CalendarStoreResponseRightItem['task']['id'];
    pointId: CalendarStoreResponseRightItem['point']['id'];
  };
};
export enum CalendarStoreResponseRightItemTypesEnum {
  TEAM = 'TEAM',
  EMPTY = 'EMPTY',
  EMPLOYEE = 'EMPLOYEE',
  UNASSIGNED = 'UNASSIGNED',
}
//
export interface CalendarStoreResponseLeftItem {
  uuid: string;
  title: string;
  isOpen?: boolean;
  groupTree?: boolean;
  parentChannelUuid?: string;
  origin: {
    id: string;
    type: 'TEAM' | 'EMPLOYEE';
    team: TeamsType | null;
    employee: UsersType | null;
  };
}

export type CalendarOnDnDMouseUp = {
  id: DragMouseUp['id'];
  till: DragMouseUp['till'];
  since: DragMouseUp['since'];
  data: CalendarStoreResponseRightItem;
};
export type CalendarCreateBulkPayload = {
  id: number | null;
  targetDate: string;
  task?: { id: number };
  point: { id: string };
  targetCompleteDate: string;
  assignmentData: TaskItemAssignmentData;
};
