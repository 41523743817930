import { LayerTypes } from "../helpers/enums/layer.enum";


export const hereTraficLayer = {
  base: "traffic",
  type: "traffic",
  scheme: "tiles",
  apiKey : "LSgbPUjXZRkDEfDHFe6DLQnOhNYZHSmP3pGo7wdsbPo",
 /*  app_code: appCode, */
};
  const namingSplitter="-";
  export const BASE_LAYER_NAME_REGISTRATION={
    OSM:{
    name:LayerTypes.OSM,
    title:"Open Street Map",
    subs:{
      DEFAULT:{ 
        name:LayerTypes.OSM+namingSplitter+"default",
        title:"Default"}
    }
    },
    HERE:{
      name:LayerTypes.Here,
      title:"Here",
      apiKey : "LSgbPUjXZRkDEfDHFe6DLQnOhNYZHSmP3pGo7wdsbPo",
  
     subs: {
      NORMAL_DAY: {
        base: "base",
        type: "maptile",
        scheme: "normal.day",
        name:LayerTypes.Here+namingSplitter+"NORMAL_DAY",
        title:"NORMAL DAY"
      },
      NORMAL_DAY_TRANSIT: {
        base: "base",
        type: "maptile",
        scheme: "normal.day.transit",
        name:LayerTypes.Here+namingSplitter+"NORMAL_DAY_TRANSIT",
        title:"NORMAL DAY TRANSIT"
      },
      PEDESTRIAN_DAY: {
        base: "base",
        type: "maptile",
        scheme: "pedestrian.day",
        name:LayerTypes.Here+namingSplitter+"PEDESTRIAN_DAY",
        title:"PEDESTRIAN DAY"
      },
      TERRAIN_DAY: {
        base: "aerial",
        type: "maptile",
        scheme: "terrain.day",
        name:LayerTypes.Here+namingSplitter+"TERRAIN_DAY",
        title:"TERRAIN DAY"
      },
      SATELLITE_DAY: {
        base: "aerial",
        type: "maptile",
        scheme: "satellite.day",
        name:LayerTypes.Here+namingSplitter+"SATELLITE_DAY",
        title:"SATELLITE DAY"
      },
      HYBRID_DAY: {
        base: "aerial",
        type: "maptile",
        scheme: "hybrid.day",
        name:LayerTypes.Here+namingSplitter+"HYBRID_DAY",
        title:"HYBRID DAY"
      },
    }
    },
    BING:{
      name:LayerTypes.Bing,
      title:"Bing",
      apiKey :"AtjsyebM44SOtspYEYu1G56bnclRFRTVHZwI54xwH7vUiD8JA9KIWWSapOiQ0Euc",
      subs:{
        ROAD: {name:LayerTypes.Bing+namingSplitter+"Road",title:"Road",bingImagerySet:"Road"},
        ROADONDEMAND:{name: LayerTypes.Bing+namingSplitter+"RoadOnDemand",title:"RoadOnDemand",bingImagerySet:"RoadOnDemand"},
        AERIAL: {name:LayerTypes.Bing+namingSplitter+"Aerial",title:"Aerial",bingImagerySet:"Aerial"},
        AERIALWITHLABES:{name: LayerTypes.Bing+namingSplitter+"AerialWithLabels",title:"AerialWithLabels",bingImagerySet:"AerialWithLabels"},
        COLLINSBART: {name:LayerTypes.Bing+namingSplitter+"collinsBart",title:"collinsBart",bingImagerySet:"collinsBart"},
        ORDNANCESURVEY:{name: LayerTypes.Bing+namingSplitter+"ordnanceSurvey",title:"ordnanceSurvey",bingImagerySet:"ordnanceSurvey"},
      }

    },
    GOOGLE:{
      name:LayerTypes.Google,
      title:"Google",
      apiKey :"AIzaSyDz1R0NyCsHC9wFKIBYNusdXU_HZn1BYTc",
      subs:{
        ROADMAP: {name:LayerTypes.Google+namingSplitter+"roadmap",title:"Road Map",mapTypeId:"roadmap"},
        SATELLITE: {name:LayerTypes.Google+namingSplitter+"satellite",title:"Satellite",mapTypeId:"satellite"},
        HYBRID: {name:LayerTypes.Google+namingSplitter+"hybrid",title:"Hybrid",mapTypeId:"hybrid"},
        TERRAIN: {name:LayerTypes.Google+namingSplitter+"terrain",title:"Terrain",mapTypeId:"terrain"}
      }

    },
    YANDEX:{
      name:LayerTypes.Yandex,
      title:"Yandex",
      apiKey :"f88f94a2-9752-435a-8a33-332e0b272222",
      subs:{
        DEFAULT:{ 
          name:LayerTypes.Yandex+namingSplitter+"default",
          title:"Default"}
      }
    }
  }
 
/**
 * google props
 */
/* export const googleLayers = {
  ROADMAP: "roadmap",
  SATELLITE: "satellite",
  HYBRID: "hybrid",
  TERRAIN: "terrain",
}; */
//export const googleApiKey = "AIzaSyDz1R0NyCsHC9wFKIBYNusdXU_HZn1BYTc";
/**
 * Here props
 */
/* 
export const hereLayers = {
  NORMAL_DAY: {
    base: "base",
    type: "maptile",
    scheme: "normal.day",
    app_id: appId,
    app_code: appCode,
  },
  NORMAL_DAY_TRANSIT: {
    base: "base",
    type: "maptile",
    scheme: "normal.day.transit",
    app_id: appId,
    app_code: appCode,
  },
  PEDESTRIAN_DAY: {
    base: "base",
    type: "maptile",
    scheme: "pedestrian.day",
    app_id: appId,
    app_code: appCode,
  },
  TERRAIN_DAY: {
    base: "aerial",
    type: "maptile",
    scheme: "terrain.day",
    app_id: appId,
    app_code: appCode,
  },
  SATELLITE_DAY: {
    base: "aerial",
    type: "maptile",
    scheme: "satellite.day",
    app_id: appId,
    app_code: appCode,
  },
  HYBRID_DAY: {
    base: "aerial",
    type: "maptile",
    scheme: "hybrid.day",
    app_id: appId,
    app_code: appCode,
  },
}; */

/**
 * bing
 */
/* export const bingApiKey =
  "AtjsyebM44SOtspYEYu1G56bnclRFRTVHZwI54xwH7vUiD8JA9KIWWSapOiQ0Euc";

export const bingImagerySets = {
  ROAD: "Road",
  ROADONDEMAND: "RoadOnDemand",
  AERIAL: "Aerial",
  AERIALWITHLABES: "AerialWithLabels",
  COLLINSBART: "collinsBart",
  ORDNANCESURVEY: "ordnanceSurvey",
}; */
/**
 * yandex
 */
//export const yandexApiKey = "f88f94a2-9752-435a-8a33-332e0b272222";
