import React from 'react';
import { useDrag } from 'react-dnd';
import { InfoCircle } from 'iconsax-react';
import { ControlCamera } from '@mui/icons-material';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';

type Props = {
  index: number;
  item: CalendarStoreResponseRightItem;
};

export default function CalendarAsideSubWrapperListItemPointsUnPlanned(props: Props) {
  const { index, item } = props;

  const id = item.id.toString();

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: DraggablesTypes.CALENDAR_ITEM,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        opacity: monitor.isDragging() ? 0.8 : 1,
      }),
      item: { task: item.task, point: item.point, unPlannedItem: item },
    }),
    [item]
  );

  const emptyTaskItem = typeof item.id === 'string';

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ p: 1, opacity, borderRadius: 1, bgcolor: 'background.default' }}
    >
      <Stack
        sx={{
          width: 16,
          height: 17,
          textAlign: 'center',
          justifyContent: 'center',
          bgcolor: (t) => t.palette.grey[400],
          borderRadius: '50% 50% 50% 50% / 38% 38% 62% 62%',
        }}
      >
        <Typography variant="subtitle2" fontSize={12}>
          {index + 1}
        </Typography>
      </Stack>
      <Stack flex={1}>
        <Typography variant="subtitle2" color="text.secondary" fontSize={12}>
          {item.task.name}
        </Typography>
        <Typography flex={1} variant="subtitle2">
          {item.point.name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        {emptyTaskItem ? (
          <Tooltip title="Oluşturulmamış Task">
            <InfoCircle size={20} variant="Bulk" />
          </Tooltip>
        ) : (
          <Chip size="small" color="primary" variant="ghost" label={`#${item.id}`} />
        )}

        <IconButton
          id={id}
          ref={drag}
          size="small"
          disableRipple
          sx={{ cursor: 'move' }}
          onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
        >
          <ControlCamera fontSize="small" color="disabled" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
