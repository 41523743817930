import React from 'react';
import { DialogContent, SxProps } from '@mui/material';

type Props = {
  sx?: SxProps;
};

export default function ModalContent(props: React.PropsWithChildren<Props>) {
  const { sx, children } = props;
  return <DialogContent sx={{ p: { xs: 1.5, lg: 3 }, ...sx }}>{children}</DialogContent>;
}
