import React from 'react';
import { enqueueSnackbar } from 'notistack';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import {
  Box,
  Stack,
  Divider,
  Collapse,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
//
import requests from 'src/utils/requests';
import CollapseItem from './collapse-item';
import axios, { endpoints } from 'src/utils/axios';
import { TaskItemsDeletePayload } from 'src/utils/requests/types';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';
import { MapAsidePersonsListItem } from 'src/store/map-aside-persons/types';
import { MultipleProcessesOptionType } from '../multiple-processes-select-box';
import { MapAsideSubPersonsStoreDetailPoints } from 'src/store/map-aside-sub-persons/types';
import { CalendarStoreResponse, CalendarStoreResponseRightItem } from 'src/store/calendar/types';

interface Props {
  personDetail: MapAsidePersonsListItem;
  point: MapAsideSubPersonsStoreDetailPoints;
}

export default function MapAsideSubWrapperPersonTeamDetailCollapse(props: Props) {
  const { point, personDetail } = props;
  const visibles = useMapAsideSubPersonsStore((s) => s.visibles);
  const [data, setData] = React.useState<CalendarStoreResponse>({
    list: [],
    totalPages: 0,
    totalElements: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const visible = visibles.includes(point.id);

  const handleVisible = async () => {
    const newVisible = !visible;

    useMapAsideSubPersonsStore.setState((state) => {
      return {
        visibles: newVisible
          ? [...state.visibles, point.id]
          : state.visibles.filter((id) => id !== point.id),
      };
    });

    if (visible) {
      setData({ list: [], totalPages: 0, totalElements: 0 });
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter(), {
        pointId: point.id,
        employeeId: personDetail.id,
      });
      setData(res.data);
    } catch (error) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onClick = React.useCallback(
    async (type: MultipleProcessesOptionType['id'], item: CalendarStoreResponseRightItem) => {
      if (type !== 'delete') {
        return;
      }

      setLoading(true);

      const payload: TaskItemsDeletePayload[] = [
        {
          id: Number(item.id),
          task: { id: item.task.id },
          point: { id: item.point.id },
        },
      ];

      try {
        await requests.taskItemsDelete(payload);

        setData((state) => {
          return {
            totalPages: state.totalPages,
            totalElements: state.totalElements - 1,
            list: state.list.filter((i) => i.id !== item.id),
          };
        });
      } catch (error) {
        enqueueSnackbar('Seçilen tasklar silinirken bir hata oluştu.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <Typography variant="caption" fontWeight="600" color="text.secondary">
          {point.name}
        </Typography>
        <Divider sx={{ flex: 1 }} orientation="horizontal" />
        <IconButton size="small" onClick={handleVisible} disabled={loading}>
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            <>{visible ? <ArrowUp2 size={16} /> : <ArrowDown2 size={16} />}</>
          )}
        </IconButton>
      </Stack>
      <Collapse in={visible} unmountOnExit>
        <Stack spacing={0.75} pt={1.25}>
          {data.list.map((item) => {
            return (
              <CollapseItem key={item.id} item={item} onClick={(type) => onClick(type, item)} />
            );
          })}
        </Stack>
      </Collapse>
    </Box>
  );
}
