import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { CircularProgress, Stack } from '@mui/material';
//
import InfiniteScroll from 'react-infinite-scroll-component';
import useCalendarAsideStore from 'src/store/calendar-aside';
import CalendarAsideWrapperListItemPoints from '../persons-teams-pools/item-points';

export default function CalendarAsideWrapperPointsPlanned() {
  const calendarAsideStore = useCalendarAsideStore(useShallow((s) => s));

  const nextFetch = () => {
    // mapAsidePersonsStore.paginationLoading
    if (false) {
      return;
    }

    // useMapAsidePersonsStore.setState({ paginationLoading: true });
    // getMapsAsidePersonsSearch(mapAsidePersonsStore.search, true);
  };

  return (
    <InfiniteScroll
      hasMore={false}
      next={nextFetch}
      scrollableTarget="persons-teams-pools-scrollable-div"
      dataLength={10}
      loader={
        false ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      // endMessage={
      //   <Typography variant="subtitle2" textAlign="center" color="text.disabled">
      //     Listenin sonuna geldiniz...
      //   </Typography>
      // }
    >
      {calendarAsideStore.response.points.map((point, index) => {
        return <CalendarAsideWrapperListItemPoints key={index} index={index} point={point} />;
      })}
    </InfiniteScroll>
  );
}
