import React from 'react';
import { CircularProgress, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import MapAsideSubWrapperPoolDetailListItem from './list-item';
import useMapAsideSubPoolsStore from 'src/store/map-aside-sub-pools';

export default function MapAsideSubWrapperPoolDetailList() {
  const mapAsideSubPoolsStore = useMapAsideSubPoolsStore((s) => ({
    poolDetail: s.poolDetail,
    response: s.response,
  }));

  const fetchData = () => {};

  const list = React.useMemo(() => {
    const data: Record<string, { point: any; tasks: any[] }> = {};

    mapAsideSubPoolsStore.response.list.forEach((task) => {
      task.points.forEach((point) => {
        if (data[point.id]) {
          data[point.id].tasks.push(task);
          return;
        }

        data[point.id] = { point, tasks: [task] };
      });
    });

    return Object.entries(data);
  }, [mapAsideSubPoolsStore.response.list]);

  return (
    <InfiniteScroll
      hasMore={false}
      next={fetchData}
      scrollableTarget="scrollable-div"
      dataLength={mapAsideSubPoolsStore.response.list.length}
      loader={
        <Stack height={20} overflow="hidden" alignItems="center">
          <CircularProgress size={20} />
        </Stack>
      }
    >
      {list.map(([pointId, item]) => {
        return (
          <MapAsideSubWrapperPoolDetailListItem
            key={pointId}
            tasks={item.tasks}
            point={item.point}
          />
        );
      })}
    </InfiniteScroll>
  );
}
