import React from 'react';
import { Flash, ArrowUp2, ArrowDown2 } from 'iconsax-react';
import {
  Box,
  Chip,
  Stack,
  Avatar,
  Divider,
  Collapse,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
//
import { StyledBadge } from 'src/layouts/dashboard/navbar/profile-box';
import MapAsideSubWrapperPersonTeamDetailCollapseItem from '../person-team-detail/collapse-item';

interface Props {
  item: any;
}

export default function MapAsideSubWrapperPointDetailCollapse(props: Props) {
  const { item } = props;
  const [tasks, setTasks] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  // TODO: Bunların hepsi zustand ile yapılacak. Şu anda sadece test amaçlı bu şekilde yapıldı.
  const visible = tasks.length > 0;

  const handleVisible = async () => {
    if (visible) {
      setTasks([]);
      return;
    }

    setLoading(true);
    try {
      // const res: PersonTeamDetailCollepseTaskItem[] = await new Promise((resolve) =>
      //   setTimeout(() => {
      //     const resTasks = [...new Array(6)].map((_, index) => ({
      //       id: `${index}`,
      //       checked: false,
      //       name: `Task ${index + 1}`,
      //     }));
      //     return resolve(resTasks);
      //   }, 1000)
      // );

      // setTasks(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <StyledBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Avatar sx={{ width: 24, height: 24 }} src={item.user.avatar} />
        </StyledBadge>
        <Typography variant="caption" fontWeight="600" color="text.secondary">
          {item.user.name}
        </Typography>
        <Divider sx={{ flex: 1 }} orientation="horizontal" />
        <Stack
          spacing={0.2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          color={item.tasksPercent < 25 ? 'success.main' : 'primary.main'}
        >
          <Flash size={12} variant="Bulk" />
          <Typography variant="subtitle2" fontSize={12} fontWeight="700">
            %{item.tasksPercent}
          </Typography>
        </Stack>
        <Chip
          size="small"
          label={item.tasksCount}
          sx={{
            px: 0.5,
            py: 0.25,
            fontSize: 10,
            height: 'auto',
            borderRadius: 2,
            fontWeight: '500',
          }}
        />
        <IconButton size="small" onClick={handleVisible} disabled={loading}>
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            <>{visible ? <ArrowUp2 size={16} /> : <ArrowDown2 size={16} />}</>
          )}
        </IconButton>
      </Stack>
      <Collapse in={visible} unmountOnExit>
        <Stack spacing={0.75} pt={1.25}>
          {tasks.map((task) => {
            return (
              <MapAsideSubWrapperPersonTeamDetailCollapseItem
                key={task.id}
                item={task}
                onClick={() => {}}
              />
            );
          })}
        </Stack>
      </Collapse>
    </Box>
  );
}
