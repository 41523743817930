import React from 'react';
import { Eye } from 'iconsax-react';
import { enqueueSnackbar } from 'notistack';
import { Stack, IconButton, Typography, useTheme, Box, Checkbox } from '@mui/material';
//
import { handlePointSelected } from 'src/store/map-events';
import { MapLibHelper } from 'src/libs/mapLibInterface/map.lib.helper';
import { MapPointSearchResponseListItem } from 'src/store/map-aside-points/types';

interface Props {
  selected: boolean;
  item: MapPointSearchResponseListItem;
}

const MapAsideWrapperTaskAssignmentPointsItem = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { item, selected } = props;
    const { palette } = useTheme();

    const handleChecked = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        await handlePointSelected(item.id, !selected);
      } catch (error) {
        enqueueSnackbar('Seçim yaparken bir hata oluştu.', { variant: 'error' });
      }
    };

    return (
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        sx={{
          px: 1.25,
          height: 58,
          borderRadius: 1,
          overflow: 'hidden',
          bgcolor: 'background.default',
        }}
      >
        <Box>
          <Checkbox disableRipple sx={{ pl: 0 }} onChange={handleChecked} checked={selected} />
        </Box>
        <Stack flex={1} overflow="hidden">
          <Typography
            overflow="hidden"
            variant="subtitle2"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.SIGN_NAME}
          </Typography>
          <Typography
            variant="caption"
            overflow="hidden"
            whiteSpace="nowrap"
            color="text.disabled"
            textOverflow="ellipsis"
          >
            {item.ADDRESS || '-'}
          </Typography>
        </Stack>
        <IconButton
          size="small"
          onClick={() => {
            MapLibHelper.goToCoordinateWithCircleOut([item.COORDINATE_X, item.COORDINATE_Y], 20);
          }}
        >
          <Eye variant="Bulk" size={24} color={palette.text.disabled} />
        </IconButton>
      </Stack>
    );
  }
);

export default MapAsideWrapperTaskAssignmentPointsItem;
