import React from 'react';
import { useDrop } from 'react-dnd';
import { Flash } from 'iconsax-react';
import { useShallow } from 'zustand/react/shallow';
import { Stack, StackProps, Typography } from '@mui/material';
//
import { TaskItem } from 'src/store/tasks/types';
import { DraggablesTypes } from 'src/utils/draggables';
import useMapsBottomTasksStore from 'src/store/maps-bottom-tasks';

interface Props extends StackProps {}

export default function TaskSelectedBox(props: Props) {
  const mapsBottomTasksStore = useMapsBottomTasksStore(useShallow((s) => s));

  const [actions, drop] = useDrop({
    accept: DraggablesTypes.TABLE_TASK,
    drop: (items: TaskItem[]) => {},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isInside = actions.isOver && actions.canDrop;

  // Seçili task yoksa ve drop alanı aktif değilse gösterme
  if (mapsBottomTasksStore.rowSelectionCount === 0 && !actions.canDrop) {
    return null;
  }

  return (
    <Stack
      ref={drop}
      spacing={1.5}
      direction="row"
      alignItems="center"
      sx={{
        px: 1.5,
        height: 64,
        flexShrink: 0,
        borderRadius: 1,
        cursor: 'pointer',
        transition: '.4s ease',
        bgcolor: isInside ? 'background.default' : 'background.neutral',
        border: ({ palette }) => `1px dashed ${palette.text.disabled}50`,
        '&:hover': { bgcolor: 'background.default' },
      }}
      {...props}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: 5,
          color: 'text.primary',
          bgcolor: ({ palette }) => `${palette.background.default}75`,
        }}
      >
        <Flash size={20} />
      </Stack>
      <Typography flex={1} variant="body2" color="text.secondary" textAlign="left" fontSize={12}>
        Seçilen taskleri bu alana sürükleyebilirsin.{' '}
        {mapsBottomTasksStore.rowSelectionCount > 0 ? (
          <Typography variant="caption" fontSize={10} fontWeight="700">
            (Yada tıkla ve ata)
          </Typography>
        ) : null}
      </Typography>
    </Stack>
  );
}
