import React from 'react';
import { Card, Divider, Stack, Typography } from '@mui/material';
//
import { TaskItem } from 'src/store/tasks/types';
import frequencyUnitOptions from 'src/helpers/frequency-unit-options';

interface Props {
  item: TaskItem;
}

export default function MapAsideSubWrapperPoolDetailListTaskItem(props: Props) {
  const { item } = props;

  const frequencyUnit =
    frequencyUnitOptions.find((option) => option.value === item.timeData.frequencyUnit)?.label ||
    '';

  return (
    <Card spacing={0.75} component={Stack} sx={{ p: 1, borderRadius: 0.75 }}>
      <Stack direction="row">
        <Typography variant="subtitle2" fontSize={12} flex={1}>
          {item.name}
        </Typography>
        <Typography variant="subtitle2" fontSize={12}>
          {frequencyUnit} - {item.timeData.frequencyValue}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} divider={<Divider flexItem orientation="vertical" />}>
        <Typography variant="caption" fontSize={10}>
          {item.timeData.startDate}
        </Typography>
        <Typography variant="caption" fontSize={10}>
          {item.timeData.endDate}
        </Typography>
      </Stack>
    </Card>
  );
}
