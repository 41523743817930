import { create } from 'zustand';
//
import useCalendarStore from '../calendar';
import axios, { endpoints } from 'src/utils/axios';
import { CalendarAsideUnPlannedStore } from './types';
import { CalendarStoreResponse } from '../calendar/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';

const initialState: CalendarAsideUnPlannedStore = {
  page: 1,
  loading: false,
  nextLoading: false,
  response: {
    list: [],
    totalPages: 0,
    totalElements: 0,
  },
};

const useCalendarAsideUnPlannedStore = create<CalendarAsideUnPlannedStore>((set) => ({
  ...initialState,
}));

export default useCalendarAsideUnPlannedStore;

const { getState, setState } = useCalendarAsideUnPlannedStore;

export const getCalendarAsideUnPlanned = async (pagination?: boolean) => {
  const state = getState();

  const page = pagination ? state.page + 1 : 1;

  if (pagination) {
    setState({ page });
  } else {
    setState({ loading: true, page });
  }

  try {
    const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter(page, 20), {
      ascending: true,
      sortBy: 'targetDate',
      status: TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED,
    });

    const data = res.data.list.map((item) => ({
      ...item,
      since: item.targetDate,
      till: item.targetCompleteDate,
    }));

    const newList = pagination ? [...state.response.list, ...data] : data;

    setState({
      response: {
        list: newList,
        totalPages: res.data.totalPages,
        totalElements: res.data.totalElements,
      },
    });
    //
    useCalendarStore.setState((s) => ({ responseRight: [...s.responseRight, ...newList] }));
  } catch (error) {
  } finally {
    setState({ loading: false, nextLoading: false });
  }
};
