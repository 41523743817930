import React from 'react';
import moment from 'moment';
import { Clock } from 'iconsax-react';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, OverlayView, OverlayViewF } from '@react-google-maps/api';
import {
  Box,
  Tab,
  Chip,
  Tabs,
  Stack,
  Table,
  Avatar,
  Dialog,
  Tooltip,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material';
//
import ModalFooter from '../footer';
import { paths } from 'src/routes/paths';
import useThemeStore from 'src/store/theme';
import { dateQuarterMinAndHour } from 'src/utils';
import axios, { endpoints } from 'src/utils/axios';
import { getCalendarData } from 'src/store/calendar';
import DarkMapStyle from 'src/assets/map-style.json';
import { useMapProvider } from 'src/contexts/map-provider';
import { mapActionsCenteredMap } from 'src/store/map-actions';
import useCalendarLayoutStore from 'src/store/calendar-layout';
import { FrequencyUnits } from 'src/helpers/frequency-unit-options';
import { UsersResponseType, UsersType } from 'src/store/users/types';
import { TeamsResponseType, TeamsType } from 'src/store/teams/types';
import { CalendarCreateBulkPayload } from 'src/store/calendar/types';
import ModalAutoPlanningAssignedPreviewEmployeeTeamChoose from './employee-team-choose';
import { MapsBottomTasksToPlannedItem } from 'src/store/maps-bottom-tasks-to-planned/types';
import { AutoPlanningAssignedPreviewState } from 'src/components/map/bottom-wrapper/tasks-to-planned/header';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TableBody
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </TableBody>
  );
}

interface Props {
  onClose(): void;
  responses: AutoPlanningAssignedPreviewState;
  selectedsTasks: MapsBottomTasksToPlannedItem[];
}

export type SelectedTeam = Record<string, TeamsType>;
export type SelectedEmploye = Record<string, UsersType>;

export default function ModalAutoPlanningAssignedPreview(props: Props) {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const mapProvider = useMapProvider();
  const isDark = useThemeStore((s) => s.isDark);
  const { responses, onClose, selectedsTasks } = props;
  const [visibleEmptyUserAndTeam, setVisibleEmptyUserAndTeam] = React.useState(false);
  const [selectedsTeam, setSelectedsTeam] = React.useState<SelectedTeam>({});
  const [selectedsEmployee, setSelectedsEmployee] = React.useState<SelectedEmploye>({});

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const plannerAssignResponseFiltered = responses.plannerAssignResponse.filter(
    (i) => i.assignee_id !== 'NON_ROUTED'
  );

  const emptyUserAndTeamList = plannerAssignResponseFiltered.filter((assign) => {
    const getAssign = responses.assigneesTaskItemsResponse[assign.assignee_id];

    return !getAssign?.team && !getAssign?.employee;
  });

  const createBulkPayload = async () => {
    setLoading(true);

    const userIds: string[] = [];
    const teamIds: string[] = [];

    const payload: CalendarCreateBulkPayload[] = plannerAssignResponseFiltered.flatMap((item) => {
      return item.tasks.map((assign) => {
        const assignItem = responses.assigneesTaskItemsResponse[item.assignee_id];
        const assignItemTeam = selectedsTeam[item.assignee_id];
        const assignItemEmployee = selectedsEmployee[item.assignee_id];

        const assignmentData = {
          teamId: '',
          teamName: '',
          poolId: '',
          poolName: '',
          roleId: '',
          roleName: '',
          employeeId: '',
          employeeName: '',
        };

        if (assignItem?.employee) {
          userIds.push(assignItem.employee.id);
          assignmentData.employeeId = assignItem.employee.id;
          assignmentData.employeeName = assignItem.employee.fullName;
        } else if (assignItem?.team) {
          teamIds.push(assignItem.team.id);
          assignmentData.teamId = assignItem.team.id;
          assignmentData.teamName = assignItem.team.name;
        } else if (assignItemTeam) {
          teamIds.push(assignItemTeam.id);
          assignmentData.teamId = assignItemTeam.id;
          assignmentData.teamName = assignItemTeam.name;
        } else if (assignItemEmployee) {
          userIds.push(assignItemEmployee.id);
          assignmentData.employeeId = assignItemEmployee.id;
          assignmentData.employeeName = assignItemEmployee.fullName;
        }

        const startDate = dateQuarterMinAndHour(assign.start_date);
        const endDate = dateQuarterMinAndHour(assign.end_date);

        const idChartAtT = assign.task_id.charAt(0) === 'T';
        const id = idChartAtT ? null : Number(assign.task_id.split('EXISTING_ID')[0]);

        return {
          id,
          assignmentData,
          point: { id: assign.point_id },
          task: {
            id: idChartAtT
              ? Number(assign.task_id.split('T-')[1])
              : Number(assign.task_id.split('EXISTING_ID')[1]),
          },
          targetDate: moment(startDate).format('YYYY-MM-DDTHH:mm'),
          targetCompleteDate: moment(endDate).format('YYYY-MM-DDTHH:mm'),
        };
      });
    });

    try {
      await axios.post(`${endpoints.taskItem.createBulk}?planMode=AUTO`, payload);
      // Get selecteds persons and teams (Pools will be added)
      const resTeams = await axios.post<{ data: TeamsResponseType }>(endpoints.teams.findList, {
        teamIds: teamIds,
      });
      const resEmployees = await axios.post<{ data: UsersResponseType }>(endpoints.users.findList, {
        employeeIds: userIds,
      });
      useCalendarLayoutStore.setState((s) => ({
        filters: {
          ...s.filters,
          selectedsTeams: resTeams.data.data.items,
          selectedsEmployees: resEmployees.data.data.items,
        },
      }));

      if (!selectedsTasks[0].timeData.startDate) {
        enqueueSnackbar('Taskın başlangıç tarihi bulunamadı.', { variant: 'error' });
        return;
      }

      let firstPlannedDate = new Date(selectedsTasks[0].timeData.startDate);

      plannerAssignResponseFiltered.forEach((item) => {
        const newTaskFilterT = item.tasks.filter((item) => item.task_id.charAt(0) === 'T');
        const sortedTasks = newTaskFilterT.sort(
          (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix()
        );
        if (sortedTasks.length > 0) {
          firstPlannedDate = sortedTasks[0].start_date;
        }
      });

      useCalendarLayoutStore.setState({ date: firstPlannedDate, dayView: FrequencyUnits.DAY });

      //
      await getCalendarData(firstPlannedDate);
      navigate(paths.calendar);
    } catch (error) {
      enqueueSnackbar('Takvimleme işlemi başarısız oldu.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const pointsForMap = React.useMemo(() => {
    return plannerAssignResponseFiltered.flatMap((item) => {
      const newTaskFilterT = item.tasks.filter((item) => item.task_id.charAt(0) === 'T');

      const sortedTasks = newTaskFilterT.sort(
        (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix()
      );

      return sortedTasks
        .map((task) => {
          const selectedTask = selectedsTasks.find(
            (selectedTask) => `T-${selectedTask.id}` === task.task_id
          );

          const point = selectedTask?.points.find((p) => p.id === task.point_id);

          if (!point) {
            return null;
          }

          return { ...point, task };
        })
        .filter(Boolean);
    });
  }, [plannerAssignResponseFiltered, selectedsTasks]);

  React.useEffect(() => {
    // @ts-ignore
    mapActionsCenteredMap(pointsForMap);
  }, [pointsForMap]);

  const disabledBtn =
    emptyUserAndTeamList.filter((assign) => {
      return selectedsTeam[assign.assignee_id] || selectedsEmployee[assign.assignee_id];
    }).length !== emptyUserAndTeamList.length;

  return (
    <Dialog open fullWidth maxWidth="xl">
      <Stack spacing={2}>
        <Box p={2} pb={0}>
          <Box
            sx={{
              height: 315,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
              bgcolor: 'background.neutral',
            }}
          >
            <GoogleMap
              onLoad={mapProvider.onLoad}
              onUnmount={mapProvider.onUnmount}
              center={{ lat: 41.0082, lng: 28.9784 }}
              mapContainerStyle={{ inset: 0, position: 'absolute' }}
              options={{
                zoom: 12,
                zoomControl: false,
                mapTypeControl: false,
                clickableIcons: false,
                streetViewControl: false,
                fullscreenControl: false,
                styles: isDark ? DarkMapStyle : null,
                backgroundColor: palette.background.default,
              }}
            >
              {pointsForMap.map((point, index) => {
                if (!point) {
                  return null;
                }
                return (
                  <OverlayViewF
                    key={index}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    position={{ lat: point.COORDINATE_Y, lng: point.COORDINATE_X }}
                  >
                    <Tooltip title={point.name} placement="right">
                      <Stack
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 2,
                          cursor: 'pointer',
                          textAlign: 'center',
                          justifyContent: 'center',
                          bgcolor: 'background.default',
                          border: (t) => `1px solid ${t.palette.text.secondary}`,
                        }}
                      >
                        <Typography variant="caption" fontFamily="700">
                          {point.task.order}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </OverlayViewF>
                );
              })}
            </GoogleMap>
          </Box>
        </Box>
        <Box>
          <Box sx={{ px: 2 }}>
            <Tabs
              scrollButtons
              value={value}
              variant="scrollable"
              onChange={(_, newValue) => setValue(newValue)}
              TabIndicatorProps={{ sx: { display: 'none' } }}
            >
              {plannerAssignResponseFiltered.map((assign, index) => {
                const getAssign = responses.assigneesTaskItemsResponse[assign.assignee_id];

                const emptyLabel =
                  selectedsTeam[assign.assignee_id]?.name ||
                  selectedsEmployee[assign.assignee_id]?.fullName;

                const label = getAssign?.employee
                  ? getAssign?.employee.fullName
                  : getAssign?.team
                  ? getAssign?.team.name
                  : emptyLabel || `Rota ${index + 1}`;

                const activeTab = value === index;

                const totalDistance = assign.tasks.reduce((acc, task) => acc + task.distance, 0);

                const totalWorkingDuration =
                  (selectedsTasks[0]?.completeDuration || 60) * assign.tasks.length;

                const totalWorkTime = totalDistance + totalWorkingDuration;

                return (
                  <Tab
                    key={assign.assignee_id}
                    sx={{ mr: '0!important' }}
                    label={
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                          p: 1,
                          borderTopLeftRadius: 1.5,
                          borderTopRightRadius: 1.5,
                          bgcolor: activeTab ? 'background.neutral' : 'background.paper',
                        }}
                      >
                        <Avatar sx={{ width: 24, height: 24 }}>{label.charAt(0)}</Avatar>
                        <Typography fontSize={12} fontWeight={activeTab ? '700' : '500'}>
                          {label}
                        </Typography>
                        <Chip
                          variant="ghost"
                          label={(totalWorkTime / 60).toFixed(1) + 's.'}
                          sx={{
                            fontSize: 12,
                            borderRadius: 3,
                            fontWeight: '700',
                            color: 'text.disabled',
                          }}
                          icon={<Clock size={20} variant="Bulk" />}
                        />
                      </Stack>
                    }
                  />
                );
              })}
            </Tabs>
          </Box>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table>
              <TableHead
                sx={{ '& th': { bgcolor: 'background.neutral' }, position: 'sticky', top: 0 }}
              >
                <TableRow>
                  <TableCell>Sıra</TableCell>
                  <TableCell>Task Adı</TableCell>
                  <TableCell>Task Tipi Adı</TableCell>
                  <TableCell>Nokta Adı</TableCell>
                  <TableCell>Saat</TableCell>
                </TableRow>
              </TableHead>
              {plannerAssignResponseFiltered.map((item, index) => {
                const newTaskFilterT = item.tasks.filter((item) => item.task_id.charAt(0) === 'T');

                const sortedTasks = newTaskFilterT.sort(
                  (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix()
                );

                return (
                  <CustomTabPanel key={index} value={value} index={index}>
                    {sortedTasks.map((task, index) => {
                      const selectedTask = selectedsTasks.find(
                        (selectedTask) => `T-${selectedTask.id}` === task.task_id
                      );
                      const point = selectedTask?.points.find((p) => p.id === task.point_id);

                      const startDate = dateQuarterMinAndHour(task.start_date);
                      const endDate = dateQuarterMinAndHour(task.end_date);

                      return (
                        <TableRow key={index}>
                          <TableCell>{task.order}</TableCell>
                          <TableCell>{selectedTask?.name}</TableCell>
                          <TableCell>{selectedTask?.taskTemplate.name}</TableCell>
                          <TableCell>{point?.name || ''}</TableCell>
                          <TableCell>
                            <Stack>
                              <Typography variant="caption">
                                {moment(task.start_date).format('DD-MM-YYYY')}
                              </Typography>
                              <Typography variant="caption">
                                ({moment(startDate).format('HH:mm')}-
                                {moment(endDate).format('HH:mm')})
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </CustomTabPanel>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </Stack>
      <ModalFooter
        onClose={onClose}
        actions={
          <>
            {emptyUserAndTeamList.length > 0 && (
              <LoadingButton
                variant="outlined"
                color={disabledBtn ? 'error' : 'success'}
                onClick={() => setVisibleEmptyUserAndTeam(true)}
              >
                Kişi & Takım Atamaları
              </LoadingButton>
            )}
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={disabledBtn}
              onClick={createBulkPayload}
            >
              Takvimle
            </LoadingButton>
          </>
        }
      />

      {visibleEmptyUserAndTeam && (
        <ModalAutoPlanningAssignedPreviewEmployeeTeamChoose
          data={emptyUserAndTeamList}
          selectedsTeam={selectedsTeam}
          setSelectedsTeam={setSelectedsTeam}
          selectedsEmployee={selectedsEmployee}
          setSelectedsEmployee={setSelectedsEmployee}
          onClose={() => setVisibleEmptyUserAndTeam(false)}
        />
      )}
    </Dialog>
  );
}
