import React from 'react';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { Box, Button, Chip, Dialog, Stack, Typography } from '@mui/material';
import { Calendar, Calendar2, CalendarAdd, SearchNormal } from 'iconsax-react';
//
import { paths } from 'src/routes/paths';
import MapBottomWrapperTasksHeaderTabs from '../tabs';
import ModalHeader from 'src/components/modals/header';
import ModalFooter from 'src/components/modals/footer';
import ModalContent from 'src/components/modals/content';
import { FrequencyUnits } from 'src/helpers/frequency-unit-options';
import ModalAutoPlanningModel from 'src/components/modals/auto-planning-model';
import { AssigneesTaskItemsResponse, PlannerAssignResponse } from 'src/utils/requests/types';
import ModalAutoPlanningAssignedPreview from 'src/components/modals/auto-planning-assigned-preview';
import useMapsBottomTasksToPlannedStore, {
  handleManuelPlan,
  getMapsBottomAllTasksToPlanned,
} from 'src/store/maps-bottom-tasks-to-planned';

export type AutoPlanningAssignedPreviewState = {
  plannerAssignResponse: PlannerAssignResponse;
  assigneesTaskItemsResponse: AssigneesTaskItemsResponse;
};

export default function MapBottomWrapperTasksToPlannedHeader() {
  const searchRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const [visibleAutoPlanningModal, setVisibleAutoPlanningModal] = React.useState(false);
  const [visibleModal, setVisibleModal] = React.useState<'manuel' | 'auto' | null>(null);
  const [autoPlanningAssignedPreview, setAutoPlanningAssignedPreview] =
    React.useState<AutoPlanningAssignedPreviewState | null>(null);
  const mapsBottomTasksToPlannedStore = useMapsBottomTasksToPlannedStore(useShallow((s) => s));

  const isManuel = visibleModal === 'manuel';

  const selectedsTasksIDS = React.useMemo(() => {
    return Object.entries(mapsBottomTasksToPlannedStore.rowSelection)
      .map((t) => t[0])
      .filter(Boolean);
  }, [mapsBottomTasksToPlannedStore.rowSelection]);

  const selectedsTasks = React.useMemo(() => {
    return mapsBottomTasksToPlannedStore.response.list.filter((t) =>
      selectedsTasksIDS.includes(`${t.id}`)
    );
  }, [mapsBottomTasksToPlannedStore.response.list, selectedsTasksIDS]);

  const totalCounts = React.useMemo(() => {
    return selectedsTasks.reduce(
      (acc, task) => {
        return {
          teamId: acc.teamId + task.assignments.filter((t) => t.teamId).length,
          poolId: acc.poolId + task.assignments.filter((t) => t.poolId).length,
          roleId: acc.roleId + task.assignments.filter((t) => t.roleId).length,
          employeeId: acc.employeeId + task.assignments.filter((t) => t.employeeId).length,
        };
      },
      { teamId: 0, poolId: 0, roleId: 0, employeeId: 0 }
    );
  }, [selectedsTasks]);

  const onChangeSearch = (search: string) => {
    useMapsBottomTasksToPlannedStore.setState({ search });

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      getMapsBottomAllTasksToPlanned({
        page: 1,
        searchBy: 'name',
        searchKey: search,
        size: mapsBottomTasksToPlannedStore.pagination.pageSize,
      });
    }, 500);
  };

  const handleOnSubmit = async () => {
    try {
      if (isManuel) {
        await handleManuelPlan(selectedsTasksIDS);
        navigate(paths.calendar);
      } else {
        setVisibleAutoPlanningModal(true);
      }

      //
      setVisibleModal(null);
    } catch (error) {
      const message = isManuel ? 'Manuel' : 'Otomatik';
      enqueueSnackbar(`${message} planlama işlemi başarısız oldu.`, { variant: 'error' });
    }
  };

  const renderContent = () => {
    if (visibleModal === 'manuel') {
      return (
        <Typography variant="subtitle2">
          {totalCounts.employeeId} Kişi, {totalCounts.teamId} Ekip ve {totalCounts.poolId} Havuz'a
          atadığınız toplam <b>{selectedsTasks.length} Task </b>
          <b>"Manuel" </b>
          planlanacaktır. Devam etmek istiyor musunuz?
        </Typography>
      );
    }

    if (selectedsTasks.length === 0) {
      return;
    }

    const selectedTask = selectedsTasks[0];

    let assignTitle = 'Kişi';
    const assignCount = selectedTask.assignments.length;

    if (assignCount > 0) {
      if (selectedTask.assignments[0].employeeName) {
        assignTitle = 'Kişi';
      } else if (selectedTask.assignments[0].teamName) {
        assignTitle = 'Ekip';
      } else if (selectedTask.assignments[0].poolName) {
        assignTitle = 'Havuz';
      }
    }

    // Seçilen taskın başlangıç tarihi şu anki tarihten önce ise
    const isStartDateCurrentBefore =
      moment(selectedTask.timeData.startDate).unix() < moment().unix();

    // Seçilen taskın başlangıç tarihi şu anki tarihten önce ise ise şu anki tarihi al, değilse seçilen taskın başlangıç tarihini al
    const startDate = isStartDateCurrentBefore ? moment() : moment(selectedTask.timeData.startDate);

    let endDate = selectedTask.timeData.endDate;

    if (selectedTask.timeData.frequencyUnit === FrequencyUnits.YEAR) {
      endDate = moment(startDate).add(27, 'days').toString();
    }

    return (
      <Typography variant="subtitle2">
        <b>{selectedTask.name}</b> taskı, {assignCount} {assignTitle} ile birlikte{', '}
        <b>
          {startDate.format('DD.MM.YYYY')} - {moment(endDate).format('DD.MM.YYYY')}
        </b>{' '}
        tarihleri arasında <b>"Otomatik"</b> olarak planlanacaktır. Devam etmek istiyor musunuz?
      </Typography>
    );
  };

  const disabledManuelBtn = mapsBottomTasksToPlannedStore.rowSelectionCount === 0;

  return (
    <Stack height={96} flexShrink={0} px={3} pt={1.5} justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Stack flex={1} direction="row" alignItems="center">
          <SearchNormal size={20} variant="Bulk" />
          <Box
            type="text"
            component="input"
            placeholder="Planlanacak task adına göre ara..."
            value={mapsBottomTasksToPlannedStore.search}
            onChange={(e) => onChangeSearch(e.target.value)}
            sx={{
              px: 1,
              width: 210,
              height: 36,
              fontSize: 12,
              border: 'none',
              outline: 'none',
              fontWeight: 600,
              minWidth: 'auto',
              color: 'text.primary',
              bgcolor: 'background.default',
              '::placeholder': { color: 'text.primary' },
            }}
          />
          <Chip
            size="small"
            variant="ghost"
            color="primary"
            label={`Listelenen: ${mapsBottomTasksToPlannedStore.response.list.length}`}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <Button
            variant="contained"
            disabled={disabledManuelBtn}
            onClick={() => setVisibleModal('manuel')}
            endIcon={<Calendar2 size={20} variant="Bulk" />}
          >
            Manuel Planla
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setVisibleModal('auto')}
            endIcon={<Calendar size={20} variant="Bulk" />}
            disabled={mapsBottomTasksToPlannedStore.rowSelectionCount === 0}
          >
            Otomatik Planla
          </Button>
        </Stack>
      </Stack>
      <MapBottomWrapperTasksHeaderTabs />
      {visibleModal !== null && (
        <Dialog open fullWidth maxWidth="sm">
          <ModalHeader
            icon={CalendarAdd}
            iconVariant="Bulk"
            title={`Seçimleriniz ${isManuel ? 'Manuel' : 'Otomatik'} Planlanacaktır`}
          />
          <ModalContent>{renderContent()}</ModalContent>
          <ModalFooter
            onClose={() => setVisibleModal(null)}
            actions={
              <LoadingButton
                variant="contained"
                loadingPosition="end"
                onClick={handleOnSubmit}
                endIcon={<Calendar size={20} variant="Bulk" />}
                loading={mapsBottomTasksToPlannedStore.handlePlannedLoading}
              >
                Devam Et
              </LoadingButton>
            }
          />
        </Dialog>
      )}
      {visibleAutoPlanningModal && (
        <ModalAutoPlanningModel
          selectedsTasks={selectedsTasks}
          onClose={() => setVisibleAutoPlanningModal(false)}
          setAutoPlanningAssignedPreview={setAutoPlanningAssignedPreview}
        />
      )}
      {autoPlanningAssignedPreview && (
        <ModalAutoPlanningAssignedPreview
          selectedsTasks={selectedsTasks}
          responses={autoPlanningAssignedPreview}
          onClose={() => setAutoPlanningAssignedPreview(null)}
        />
      )}
    </Stack>
  );
}
