import { useDrag } from 'react-dnd';
import { ControlCamera } from '@mui/icons-material';
import { Calendar, TickCircle } from 'iconsax-react';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import useRemainingCountCalc from 'src/hooks/remaining-count-calc';
import {
  CalendarAsideResponseTaskMap,
  CalendarAsideResponseItemPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  task: CalendarAsideResponseTaskMap;
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsideWrapperListItemPointsTasksItem(props: Props) {
  const { task, point } = props;

  const id = task.id.toString();

  const remainingCount = useRemainingCountCalc({ task, point });

  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: DraggablesTypes.CALENDAR_ITEM,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.8 : 1,
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
      item: { task, point },
    }),
    [task, point]
  );

  return (
    <Stack
      ref={preview}
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        opacity,
        borderRadius: 1,
        transition: '.4s ease',
        '&:hover': { bgcolor: (t) => t.palette.background.neutral },
      }}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={1}>
        <Chip size="small" variant="ghost" label={`#${task.id}`} />
        <Typography
          flex={1}
          overflow="hidden"
          variant="subtitle2"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {task.name}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Tooltip title={`Frekansa Uygun: ${task.count}`}>
          <Chip label={task.count} size="small" />
        </Tooltip>
        {remainingCount > 0 ? (
          <>
            <Tooltip title={`Kalan Atama Sayısı: ${remainingCount}`}>
              <Chip
                size="small"
                color="primary"
                variant="ghost"
                label={remainingCount}
                icon={<Calendar variant="Bulk" size={16} />}
              />
            </Tooltip>
            <IconButton
              id={id}
              ref={drag}
              size="small"
              disableRipple
              sx={{ cursor: 'move' }}
              onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
            >
              <ControlCamera fontSize="small" color="disabled" />
            </IconButton>
          </>
        ) : (
          <IconButton size="small" disabled sx={{ '& svg': { color: 'success.main' } }}>
            <TickCircle variant="Bulk" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
