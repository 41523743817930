import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import * as Icon from 'iconsax-react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Dialog, Divider, Grid, InputAdornment, Stack, useTheme } from '@mui/material';
//
import ModalHeader from '../header';
import ModalFooter from '../footer';
import ModalContent from '../content';
import requests from 'src/utils/requests';
import axios, { endpoints } from 'src/utils/axios';
import InputWrapper from 'src/components/input-wrapper';
import { UsersResponseType } from 'src/store/users/types';
import ModalCollapseSeparator from '../collapse-separator';
import FormProvider from 'src/components/hook-form/form-provider';
import { FrequencyUnits } from 'src/helpers/frequency-unit-options';
import { RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import { PlannerAssignPayload, PlannerAssignPayloadTasks } from 'src/utils/requests/types';
import { MapsBottomTasksToPlannedItem } from 'src/store/maps-bottom-tasks-to-planned/types';
import CustomTextMaskCustom, { hourMinRegex } from 'src/components/hook-form/custom-text-mask';
import { AutoPlanningAssignedPreviewState } from 'src/components/map/bottom-wrapper/tasks-to-planned/header';
import {
  AutoPlanningAlgorithmTypeEnum,
  AutoPlanningWayCalculationEnum,
  AutoPlanningModelOptimizationEnum,
  AutoPlanningModelPointPlanedChoiceEnum,
} from './types';

type Props = {
  onClose(): void;
  selectedsTasks: MapsBottomTasksToPlannedItem[];
  setAutoPlanningAssignedPreview: React.Dispatch<AutoPlanningAssignedPreviewState>;
};

export default function ModalAutoPlanningModel(props: Props) {
  const { palette } = useTheme();
  const { selectedsTasks, onClose, setAutoPlanningAssignedPreview } = props;

  const defaultValues = {
    //
    pointPlanedChoiceValue: 1,
    pointPlanedChoice: AutoPlanningModelPointPlanedChoiceEnum.OPTIMIZE,
    //
    shortWay: AutoPlanningWayCalculationEnum.SHORTEST, // Yol hesaplama seçeneği
    // Günler
    monday: 100,
    tuesday: 100,
    wednesday: 100,
    thursday: 100,
    friday: 100,
    saturday: 0,
    sunday: 0,
    // Saatler
    endedTime: '18:00', // Çalışma Saati
    startedTime: '08:00', // Başlangıç Saati
    mealEndTime: '13:00', // Yemek bitiş saati
    mealStartTime: '12:00', // Yemek başlangıç saati
  };

  const schema = Yup.object().shape({
    pointPlanedChoiceValue: Yup.number().required(
      "Manuel olarak noktaları planlamak için 'Rota Sayısı' giriniz."
    ),
    pointPlanedChoice: Yup.string<AutoPlanningModelPointPlanedChoiceEnum>().required(
      'Noktaları planlamak için seçim yapınız'
    ),
    //
    shortWay: Yup.string<AutoPlanningWayCalculationEnum>().required(
      'Yol hesaplama seçeneği zorunludur'
    ),
    //
    monday: Yup.number().min(0).max(100),
    tuesday: Yup.number().min(0).max(100),
    wednesday: Yup.number().min(0).max(100),
    thursday: Yup.number().min(0).max(100),
    friday: Yup.number().min(0).max(100),
    saturday: Yup.number().min(0).max(100),
    sunday: Yup.number().min(0).max(100),
    // Saatler
    startedTime: Yup.string()
      .required('Başlangıç saati alanı zorunlu.')
      .test({
        name: 'startedTimeTest',
        test: function (value: string) {
          if (!hourMinRegex.test(value)) {
            return this.createError({
              path: 'startedTime',
              message: "Başlangıç saati alanı '00:00 - 23:59' formatında olmalıdır.",
            });
          }

          return true;
        },
      }),
    mealStartTime: Yup.string()
      .required('Yemek başlangıç saati alanı zorunlu.')
      .test({
        name: 'mealStartTimeTest',
        test: function (value: string) {
          if (!hourMinRegex.test(value)) {
            return this.createError({
              path: 'mealStartTime',
              message: "Yemek başlangıç alanı '00:00 - 23:59' formatında olmalıdır.",
            });
          }

          return true;
        },
      }),
    endedTime: Yup.string()
      .required('Çalışma saati alanı zorunlu.')
      .test({
        name: 'endedTime',
        test: function (value: string) {
          if (!hourMinRegex.test(value)) {
            return this.createError({
              path: 'endedTime',
              message: "Çalışma saati alanı '00:00 - 23:59' formatında olmalıdır.",
            });
          }

          return true;
        },
      }),
    mealEndTime: Yup.string()
      .required('Yemek bitiş saati alanı zorunlu.')
      .test({
        name: 'mealEndTimeTest',
        test: function (value: string) {
          if (!hourMinRegex.test(value)) {
            return this.createError({
              path: 'mealEndTime',
              message: "Yemek bitiş saati alanı '00:00 - 23:59' formatında olmalıdır.",
            });
          }

          return true;
        },
      }),
  });

  const methods = useForm({ resolver: yupResolver(schema), defaultValues });

  const { watch, formState, handleSubmit } = methods;

  const values = watch();

  const dates: string[] = [];
  const payloadAssignees: PlannerAssignPayload['assignees'] = [];

  const onSubmit = handleSubmit(async (data) => {
    try {
      const optimize = data.pointPlanedChoice === AutoPlanningModelPointPlanedChoiceEnum.OPTIMIZE;

      const territoryCount = optimize ? -1 : data.pointPlanedChoiceValue;

      const tasks: PlannerAssignPayload['tasks'] = [];

      const assignees = [];

      for (const task of selectedsTasks) {
        for (const assignment of task.assignments) {
          if (assignment.employeeId) {
            const res = await axios.post<{ data: UsersResponseType }>(endpoints.users.findList, {
              employeeIds: [assignment.employeeId],
            });

            res.data.data.items.forEach((item) => {
              assignees.push({ employeeId: item.id, teams: item.teams.map((team) => team.id) });
            });
          } else if (assignment.teamId) {
            assignees.push({ teamId: assignment.teamId });
          }
        }

        // Seçilen taskın başlangıç tarihi şu anki tarihten önce ise
        const isStartDateCurrentBefore = moment(task.timeData.startDate).unix() < moment().unix();

        // Seçilen taskın başlangıç tarihi şu anki tarihten önce ise ise şu anki tarihi al, değilse seçilen taskın başlangıç tarihini al
        const startDate = isStartDateCurrentBefore ? moment() : moment(task.timeData.startDate);

        let endDate = moment(task.timeData.endDate);

        if (task.timeData.frequencyUnit === FrequencyUnits.YEAR) {
          endDate = moment(startDate).add(27, 'days');
        }

        const dayCount = endDate.diff(startDate, 'days') + 1;

        const dates = Array.from({ length: dayCount }, (_, i) =>
          startDate.clone().add(i, 'days').format('YYYY-MM-DD')
        );

        dates.push(...dates);

        const frequencyValue = task.timeData.frequencyValue || 1;

        let frequency = frequencyValue;

        if (task.timeData.frequencyUnit === FrequencyUnits.DAY) {
          frequency = frequencyValue * 28;
        } else if (task.timeData.frequencyUnit === FrequencyUnits.WEEK) {
          frequency = frequencyValue * 4;
        } else if (task.timeData.frequencyUnit === FrequencyUnits.YEAR) {
          frequency = 1;
          endDate = moment(startDate).add(27, 'day');
        }

        const roleIds: PlannerAssignPayloadTasks['role_id'] = [];

        task.assignments.forEach((assignment) => {
          if (assignment.roleId) {
            roleIds.push(assignment.roleId);
          }
          if (assignment.employeeId) {
            roleIds.push(assignment.employeeId);
          }
        });

        tasks.push({
          frequency,
          role_id: roleIds,
          id: `T-${task.id}`,
          end_date: endDate.format('YYYY-MM-DD'),
          service_time: task.completeDuration || 60,
          start_date: startDate.format('YYYY-MM-DD'),
          points: task.points.map((point) => point.id),
        });
      }

      const res = await requests.assigneesTaskItems({
        dates,
        assignees,
        exclusiveTaskIds: [...selectedsTasks.map((item) => item.id)],
      });

      Object.entries(res).forEach(([assigneeId, value]) => {
        const roleIds: string[] = [
          ...(value.employee?.roles.map((role) => role.id) || []),
          assigneeId,
        ];

        const days = Object.entries(value.days);

        const daysItems = days.flatMap(([day, tasks]) => tasks);

        payloadAssignees.push({
          role_id: roleIds,
          assignee_id: assigneeId,
          existing_tasks: daysItems.map((item) => {
            const dateStart = moment(item.targetDate).format('YYYY-MM-DDTHH:mm:ss');
            const dateEnd = moment(item.targetCompleteDate).format('YYYY-MM-DDTHH:mm:ss');

            return {
              end_date: dateEnd,
              start_date: dateStart,
              point_id: item.point.id,
              task_id: `${item.id}EXISTING_ID${item.task.id}`,
            };
          }),
        });
      });

      const payload = {
        tasks,
        assignees: payloadAssignees,
        session_territory: {
          depot_key: '',
          country_code: 'tr',
          is_pre_routed: false,
          force_to_assign: false,
          not_optimize_routes: true,
          territory_count: territoryCount,
          working_time_end: data.endedTime,
          break_time_end: data.mealEndTime,
          break_time_start: data.mealStartTime,
          working_time_start: data.startedTime,
          distance_calculate_type: data.shortWay,
          algorithm_depot_option: 'NOT_USE_DEPOT',
          algorithm_type: AutoPlanningAlgorithmTypeEnum.BY_TERRITORY_COUNT,
          balancing_field: AutoPlanningModelOptimizationEnum.COUNT_PER_TERRITORY,
          day_capacity: [
            data.monday,
            data.tuesday,
            data.wednesday,
            data.thursday,
            data.friday,
            data.saturday,
            data.sunday,
          ] as number[],
        },
      };

      const plannerAssignRes = await requests.plannerAssign(payload);
      // Atama yapma işlemi başarılı
      onClose();

      setAutoPlanningAssignedPreview({
        assigneesTaskItemsResponse: res,
        plannerAssignResponse: plannerAssignRes,
      });
    } catch (error) {
      enqueueSnackbar('Atama yaparken bir sorun oluştu.', { variant: 'error' });
    }
  });

  return (
    <Dialog open fullWidth maxWidth="lg">
      <ModalHeader title="Otomatik Planlama Modeli" description="" />
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <Stack spacing={1.5}>
            <ModalCollapseSeparator title="Noktaları Planlamak için seçim yapınız">
              <Stack spacing={1.5}>
                <RHFRadioGroup
                  name="pointPlanedChoice"
                  options={[
                    {
                      label: 'Optimize Rota Sayısına Böl',
                      value: AutoPlanningModelPointPlanedChoiceEnum.OPTIMIZE,
                    },
                    {
                      label: 'Rota Sayısını Manuel Belirle',
                      value: AutoPlanningModelPointPlanedChoiceEnum.MANUEL,
                    },
                  ]}
                />
                {values.pointPlanedChoice === AutoPlanningModelPointPlanedChoiceEnum.MANUEL && (
                  <InputWrapper
                    title="Rota Sayısı"
                    sx={{ bgcolor: 'background.default', p: 1, borderRadius: 1.5 }}
                  >
                    <RHFTextField
                      fullWidth
                      size="small"
                      type="number"
                      placeholder="Rota Sayısı"
                      name="pointPlanedChoiceValue"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  </InputWrapper>
                )}
              </Stack>
            </ModalCollapseSeparator>
            <ModalCollapseSeparator title="Bölge Oluşturma Seçenekleri">
              <RHFRadioGroup
                name="shortWay"
                options={[
                  {
                    label: 'En Kısa Yola Göre Hesapla',
                    value: AutoPlanningWayCalculationEnum.SHORTEST,
                  },
                  {
                    label: 'En Hızlı Yola Göre Hesapla',
                    value: AutoPlanningWayCalculationEnum.FASTEST,
                  },
                ]}
              />
            </ModalCollapseSeparator>
            <ModalCollapseSeparator title="Günlük Çalışma Kapasitesi (%)">
              <Card sx={{ borderRadius: 1 }}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12} md={3} lg={3}>
                    <InputWrapper title="Pazartesi">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="monday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <InputWrapper title="Salı">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="tuesday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <InputWrapper title="Çarşamba">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="wednesday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <InputWrapper title="Perşembe">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="thursday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <InputWrapper title="Cuma">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="friday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <InputWrapper title="Cumartesi">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="saturday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={6}>
                    <InputWrapper title="Pazar">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="sunday"
                        placeholder="100"
                        inputProps={{ maxLength: 3 }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider flexItem />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper title="Başlangıç Saati">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="startedTime"
                        placeholder="08:00"
                        InputProps={{
                          inputProps: { mask: '00:00' },
                          inputComponent: CustomTextMaskCustom as any,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon.Timer1 size="20" variant="Bulk" color={palette.primary.main} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper title="Yemek Başlangıç Saati">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="mealStartTime"
                        placeholder="12:00"
                        InputProps={{
                          inputProps: { mask: '00:00' },
                          inputComponent: CustomTextMaskCustom as any,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon.Timer1 size="20" variant="Bulk" color={palette.primary.main} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper title="Çalışma Bitiş Saati">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="endedTime"
                        placeholder="23:00"
                        InputProps={{
                          inputProps: { mask: '00:00' },
                          inputComponent: CustomTextMaskCustom as any,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon.Timer1 size="20" variant="Bulk" color={palette.primary.main} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputWrapper title="Yemek Bitiş Saati">
                      <RHFTextField
                        fullWidth
                        size="small"
                        name="mealEndTime"
                        placeholder="13:00"
                        InputProps={{
                          inputProps: { mask: '00:00' },
                          inputComponent: CustomTextMaskCustom as any,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon.Timer1 size="20" variant="Bulk" color={palette.primary.main} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Card>
            </ModalCollapseSeparator>
          </Stack>
        </ModalContent>
        <ModalFooter
          onClose={onClose}
          actions={
            <LoadingButton type="submit" variant="contained" loading={formState.isSubmitting}>
              Kaydet
            </LoadingButton>
          }
        />
      </FormProvider>
    </Dialog>
  );
}
