import Map from 'ol/Map';

export class MapInstanceHelper {
  private static _instance: MapInstanceHelper;

  private static _map: Map;

  private constructor() {}

  public static getInstance(): MapInstanceHelper {
    if (!MapInstanceHelper._instance) {
      MapInstanceHelper._instance = new MapInstanceHelper();
    }

    return MapInstanceHelper._instance;
  }
  public static get Map(): Map {
    /* console.log('get',this._map) */
    return this._map;
  }
  public static set Map(value: Map) {
    /* console.log('set',this._map) */
    this._map = value;
  }
}
