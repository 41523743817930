import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';

type Props = {
  visible: boolean;
  toggleVisible(): void;
};

export default function CalendarAsideHeader(props: Props) {
  const { visible, toggleVisible } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      onClick={toggleVisible}
      sx={{
        px: 1.5,
        flexShrink: 0,
        cursor: 'pointer',
        height: visible ? 48 : 1,
        bgcolor: 'background.default',
      }}
    >
      {visible ? (
        <Typography variant="subtitle2" flex={1}>
          Unplaned Taskler
        </Typography>
      ) : null}

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: 24, height: 24, bgcolor: 'background.neutral', borderRadius: 1 }}
      >
        {visible ? <ArrowLeft2 size={16} /> : <ArrowRight2 size={16} />}
      </Stack>
    </Stack>
  );
}
