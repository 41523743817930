import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from '../axios';
import { CalendarStoreResponse } from 'src/store/calendar/types';
import {
  AssigneesTaskItemsPayload,
  AssigneesTaskItemsResponse,
  //
  PlannerAssignPayload,
  PlannerAssignResponse,
  TaskItemsDeletePayload,
  //
  GetTaskItemsForTeamBody,
  GetTaskItemsForEmployeeBody,
} from './types';

class Requests {
  async assigneesTaskItems(
    payload: AssigneesTaskItemsPayload
  ): Promise<AssigneesTaskItemsResponse> {
    try {
      const res = await axios.post(endpoints.taskItem.assigneesTaskItems, payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  async plannerAssign(payload: PlannerAssignPayload): Promise<PlannerAssignResponse> {
    try {
      const res = await axios.post(endpoints.planner.assign, payload);

      return res.data;
    } catch (error) {
      throw error;
    }
  }
  async taskItemsDelete(items: TaskItemsDeletePayload[]) {
    try {
      await axios.delete(endpoints.taskItem.deleteBulk, { data: items });
      enqueueSnackbar('Tasklar başarıyla silindi.', { variant: 'success' });
    } catch (error) {
      throw error;
    }
  }
  async getTaskItemsForTeam(body: GetTaskItemsForTeamBody): Promise<CalendarStoreResponse> {
    try {
      const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter(), {
        teamId: body.team.id,
        calendarDate: body.calendarDate,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
  async getTaskItemsForEmployee(body: GetTaskItemsForEmployeeBody): Promise<CalendarStoreResponse> {
    try {
      const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter(), {
        employeeId: body.employee.id,
        calendarDate: body.calendarDate,
        teams: body.employee.teams.map((team) => team.id),
      });

      return res.data;
    } catch (error) {
      throw error;
    }
  }
}

const requests = new Requests();

export default requests;
