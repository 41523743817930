import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
//
import useCalendarAsideStore from 'src/store/calendar-aside';
import { CalendarAsideResponseItemPoints } from 'src/store/calendar-aside/types';
import CalendarAsideWrapperListItemPointsTasksItem from './item-points-tasks-item';

type Props = {
  index: number;
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsideWrapperListItemPoints(props: Props) {
  const { index, point } = props;

  const taskMap = useCalendarAsideStore((s) => s.response.taskMap);

  const tasks = point.taskIds.map((taskId) => taskMap[taskId]);

  return (
    <Stack sx={{ p: 0.75, borderRadius: 1, bgcolor: 'background.default' }}>
      <Stack flex={1} spacing={1} direction="row" alignItems="center" sx={{ px: 1, py: 0.5 }}>
        <Stack
          sx={{
            width: 16,
            height: 17,
            textAlign: 'center',
            justifyContent: 'center',
            bgcolor: (t) => t.palette.grey[400],
            borderRadius: '50% 50% 50% 50% / 38% 38% 62% 62%',
          }}
        >
          <Typography variant="subtitle2" fontSize={12}>
            {index + 1}
          </Typography>
        </Stack>
        <Typography flex={1} variant="subtitle2">
          {point.name}
        </Typography>
      </Stack>
      <Box sx={{ ml: (t) => `${t.spacing(4)}!important` }}>
        {tasks.map((task) => {
          return (
            <CalendarAsideWrapperListItemPointsTasksItem key={task.id} task={task} point={point} />
          );
        })}
      </Box>
    </Stack>
  );
}
