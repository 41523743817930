import { UseFormReturn, FormProvider as Form } from 'react-hook-form';

import { Box, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  boxProps?: SxProps;
};

export default function FormProvider({ children, onSubmit, methods, boxProps }: Props) {
  return (
    <Form {...methods}>
      <Box component="form" onSubmit={onSubmit} sx={boxProps}>
        {children}
      </Box>
    </Form>
  );
}
