enum OBSERVER_ACTION_TYPES {
  MAP = 'map',
  INTERACTION = 'interaction',
  EVENT = 'event',
  LAYER_SWITCHER='layerswitcher'
}
enum OBSERVER_SUB_ACTION_TYPES_MAP {
  CREATE_MAP = 'createMap',
}
enum OBSERVER_SUB_ACTION_TYPES_EVENT {
  ZOOM = 'zoom',
  SINGLE_CLICK='single_click',
  CLICK='click',
  CLICK_DOWN='click_down'
}
enum OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER {
  ADD_LAYER = 'addlayer',
  REMOVE_LAYER = 'removelayer',
}
enum OBSERVER_SUB_ACTION_TYPES_INTERACTION {
  MODIFY_END = 'modifyend',
  DRAW_END = 'drawend',
  TRANSLATE_END='translateend',
  SELECTION_CHANGE='selectionchange'
}
enum OBSERVER_ACTION_STATE {
  SUCCESS = 'success',
  ERROR = 'error',
}
export {
  OBSERVER_ACTION_STATE,
  OBSERVER_ACTION_TYPES,
  OBSERVER_SUB_ACTION_TYPES_MAP,
  OBSERVER_SUB_ACTION_TYPES_EVENT,
  OBSERVER_SUB_ACTION_TYPES_INTERACTION,
  OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER
};
