import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Stack,
  Divider,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
//
import Item from './item';
import MapAsideWrapperSearch from '../../search';
import useMapEventsStore from 'src/store/map-events';
import LoadingItems from 'src/components/loading-items';
import MapAsideWrapperEmptyDetail from '../../empty-detail';
import MapAsideWrapperTaskAssignmentPointsItemEmpty from './item/empty';
import useGeomarketingFiltersStore from 'src/store/geomarketing-filters';
import useMapAsidePointsStore, {
  getMapsSearchPoint,
  onChangeMapsFilters,
} from 'src/store/map-aside-points';

export default function MapAsideWrapperTaskAssignmentPointsWrapper() {
  const mapEventsStore = useMapEventsStore(useShallow((s) => s));
  const mapPointsStore = useMapAsidePointsStore(useShallow((s) => s));
  const geomarketingFiltersStore = useGeomarketingFiltersStore(useShallow((s) => s));
  const searchRef = React.useRef<any>(null);

  const hasList = mapPointsStore.response.count > 0;

  const onChangeSearch = React.useCallback((value: string) => {
    useMapAsidePointsStore.setState({ search: value, searchLoading: true });

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      getMapsSearchPoint(value);
    }, 1000);
  }, []);

  const nextFetch = () => {
    if (mapPointsStore.paginationLoading) {
      return;
    }

    useMapAsidePointsStore.setState({ paginationLoading: true });
    getMapsSearchPoint(mapPointsStore.search, true);
  };

  const renderContent = () => {
    if (mapPointsStore.searchLoading) {
      return (
        <LoadingItems flex={1} m={1.5} count={20} spacing={1.5}>
          <MapAsideWrapperTaskAssignmentPointsItemEmpty />
        </LoadingItems>
      );
    }

    if (!hasList) {
      return <MapAsideWrapperEmptyDetail />;
    }

    const hasMore = mapPointsStore.response.page < mapPointsStore.response.totalPages;
    const endMessage = mapPointsStore.response.data.length > 0 && !hasMore;

    return (
      <Box
        id="points-scrollable-div"
        sx={{
          p: 1.5,
          flex: 1,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { display: 'flex', flexDirection: 'column', gap: 1.25 },
        }}
      >
        <InfiniteScroll
          next={nextFetch}
          hasMore={hasMore}
          initialScrollY={0}
          scrollableTarget="points-scrollable-div"
          dataLength={mapPointsStore.response.data.length}
          endMessage={
            endMessage && (
              <Typography variant="subtitle2" textAlign="center" color="text.disabled">
                Listenin sonuna geldiniz...
              </Typography>
            )
          }
          loader={
            mapPointsStore.paginationLoading ? (
              <Stack
                bottom={0}
                height={40}
                position="static"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={20} />
              </Stack>
            ) : null
          }
        >
          {mapPointsStore.response.data.map((item) => {
            const selected = mapEventsStore.response.features.some((i) => i.id === item.id);

            return <Item key={item.id} item={item} selected={selected} />;
          })}
        </InfiniteScroll>
      </Box>
    );
  };

  return (
    <Stack flex={1} overflow="hidden">
      <MapAsideWrapperSearch
        setSearch={onChangeSearch}
        search={mapPointsStore.search}
        count={mapPointsStore.response.count}
        loading={mapPointsStore.searchLoading}
        placeholder="Nokta listesinde arama yapabilirsiniz."
      />
      <Divider />
      <Box sx={{ px: 1.5, py: 1, textAlign: 'center' }}>
        <Autocomplete
          size="small"
          options={geomarketingFiltersStore.list}
          loading={geomarketingFiltersStore.loading}
          value={mapPointsStore.selectedGeomarketingFilter}
          renderInput={(i) => (
            <TextField
              {...i}
              sx={{ bgcolor: 'background.default', borderRadius: 1 }}
              placeholder="Geomarketing filtrelerinden seçim yapabilirsiniz."
            />
          )}
          renderOption={(props, option) => {
            return (
              <Stack
                {...props}
                spacing={1}
                component="li"
                key={option.id}
                direction="row"
                alignItems="center"
              >
                <Box sx={{ width: 12, height: 12, borderRadius: 1, bgcolor: option.color }} />
                <Typography variant="subtitle2">{option.label}</Typography>
              </Stack>
            );
          }}
          onChange={(_, selectValue) => onChangeMapsFilters(selectValue)}
        />
      </Box>
      <Divider />
      {renderContent()}
    </Stack>
  );
}
