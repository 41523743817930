import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import { MapAsideSubPoolsStore } from './types';
import { mapAsideSubOnOpen } from '../maps-layout';
import axios, { endpoints } from 'src/utils/axios';
import { MapAsidePoolsListItem } from '../map-aside-pools/types';

const initialState: MapAsideSubPoolsStore = {
  loading: null,
  poolDetail: null,
  response: {
    list: [],
    totalPages: 0,
    totalElements: 0,
  },
};

const useMapAsideSubPoolsStore = create<MapAsideSubPoolsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsideSubPoolsStore;

const { setState } = useMapAsideSubPoolsStore;

export const getMapAsideSubPools = async (pool: MapAsidePoolsListItem) => {
  setState({ loading: pool.id });

  try {
    const res = await axios.post(endpoints.task.list, {
      page: 1,
      size: 1000,
      poolId: pool.id,
      endDateTime: new Date(),
    });

    setState({ poolDetail: pool, response: res.data });

    mapAsideSubOnOpen('pool');
  } catch (error) {
    enqueueSnackbar('Havuza ait noktalar getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: null });
  }
};
