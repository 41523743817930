import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import { createSld } from '../map-actions';
import axios, { endpoints } from 'src/utils/axios';
import useBaseWorkspaceStore from '../base-workspaces';
import { MapPointSearchResponse, MapAsidePointsStore } from './types';
import { GeomarketingFiltersType } from '../geomarketing-filters/types';

const initialState: MapAsidePointsStore = {
  search: '',
  searchLoading: true,
  paginationLoading: false,
  selectedGeomarketingFilter: null,
  //
  response: {
    page: 0,
    data: [],
    count: 0,
    size: 20,
    totalPages: 0,
  },
};
const useMapAsidePointsStore = create<MapAsidePointsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsidePointsStore;

const { getState, setState } = useMapAsidePointsStore;

export const getMapsSearchPoint = async (search: string, hasPagination?: boolean) => {
  const state = getState();

  const baseWorkspaceStore = useBaseWorkspaceStore.getState();

  const filterQuery = state.selectedGeomarketingFilter?.filter_query;

  const json = filterQuery ? JSON.stringify({ query: JSON.parse(filterQuery).query }) : '';

  const newPage = hasPagination ? state.response.page + 1 : 1;
  const payloadIndex = `gvg.${baseWorkspaceStore.baseWorkspace?.availability.collectionName}`;

  try {
    const res = await axios.post<MapPointSearchResponse>(
      endpoints.geomarketingSearchPoints(newPage, state.response.size),
      {
        json,
        term: `*${search}*`,
        index: payloadIndex,
      }
    );

    if (hasPagination) {
      setState((prev) => ({
        response: {
          ...res.data,
          size: state.response.size,
          data: [...prev.response.data, ...res.data.data],
        },
      }));
    } else {
      setState({ response: { ...res.data, size: state.response.size } });
    }
  } catch (error) {
    enqueueSnackbar('Noktaları getirme işlemi sırasında bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ searchLoading: false, paginationLoading: false });
  }
};

export const onChangeMapsFilters = async (filter: GeomarketingFiltersType | null) => {
  const state = getState();

  const baseWorkspaceStore = useBaseWorkspaceStore.getState();

  setState({ selectedGeomarketingFilter: filter, searchLoading: true });

  const json = filter ? JSON.stringify({ query: JSON.parse(filter.filter_query).query }) : '';

  const payloadIndex = `gvg.${baseWorkspaceStore.baseWorkspace?.availability.collectionName}`;

  try {
    const res = await axios.post<MapPointSearchResponse>(
      endpoints.geomarketingSearchPoints(1, state.response.size),
      {
        json,
        index: payloadIndex,
        term: `*${state.search}*`,
      }
    );

    if (filter?.filter_query) {
      createSld(`q:${JSON.stringify(JSON.parse(filter.filter_query).query)}`);
    } else {
      createSld();
    }

    setState({ response: { ...res.data, size: state.response.size } });
  } catch (error) {
    enqueueSnackbar('Filtreye Göre Noktaları getirme işlemi sırasında bir hata oluştu.', {
      variant: 'error',
    });
  } finally {
    setState({ searchLoading: false, paginationLoading: false });
  }
};
