import React from 'react';
import { Stack } from '@mui/material';
//
import MapAsideSubWrapperPoolDetailList from './list';
import TaskSelectedBox from '../../task-selected-box';
import MapAsideSubWrapperEmptyDetail from '../empty-detail';
import useMapAsideSubPoolsStore from 'src/store/map-aside-sub-pools';

interface Props {}

export default function MapAsideSubWrapperPoolDetail(props: Props) {
  const list = useMapAsideSubPoolsStore((s) => s.response.list);

  if (list.length === 0) {
    return <MapAsideSubWrapperEmptyDetail />;
  }

  return (
    <Stack flex={1} overflow="hidden">
      <Stack
        id="scrollable-div"
        sx={{
          flex: 1,
          p: 1.5,
          gap: 1.5,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { gap: 1.25, display: 'flex', flexDirection: 'column' },
        }}
      >
        <MapAsideSubWrapperPoolDetailList />
      </Stack>
      <TaskSelectedBox position="sticky" bottom={0} m={1.5} />
    </Stack>
  );
}
