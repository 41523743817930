import React from 'react';
import moment from 'moment';
import { CloseSquare } from 'iconsax-react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import {
  Chip,
  Card,
  Stack,
  Divider,
  Popover,
  Backdrop,
  useTheme,
  Typography,
  IconButton,
} from '@mui/material';
//
import { clipboardCopy } from 'src/utils';
import useThemeStore from 'src/store/theme';
import { useMapProvider } from 'src/contexts/map-provider';
import DarkMapStyle from '../../../../assets/map-style.json';
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';
import { taskDefinitionFormTaskStatus } from 'src/store/task-definition-form/types';

interface Props {
  onClose(): void;
  anchorEl: HTMLElement;
  calendarRightItem: CalendarStoreResponseRightItem;
}

export default function ProgramItemDetail(props: Props) {
  const { onClose, anchorEl, calendarRightItem } = props;
  const { palette } = useTheme();
  const mapProvider = useMapProvider();
  const isDark = useThemeStore((s) => s.isDark);

  return (
    <Backdrop open sx={{ zIndex: 10 }}>
      <Popover
        open
        onClose={onClose}
        anchorEl={anchorEl}
        sx={{ transform: 'translateY(-15px)' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              '&:before': {
                left: 0,
                right: 0,
                width: 15,
                height: 15,
                content: '""',
                margin: 'auto',
                display: 'block',
                position: 'absolute',
                top: 'calc(100% - 1px)',
                border: '7.5px solid transparent',
                borderTopColor: 'background.neutral',
              },
            },
          },
        }}
      >
        <Stack
          sx={{ width: 420, borderRadius: 1, bgcolor: 'background.default', overflow: 'hidden' }}
        >
          <Stack direction="row" alignItems="center" spacing={1} py={1} px={1.5}>
            <Stack flex={1}>
              <Typography
                variant="caption"
                fontWeight="700"
                sx={{ cursor: 'copy' }}
                onClick={() => {
                  clipboardCopy(`${calendarRightItem.task.id}`, 'Task ID Kopyalandı');
                }}
              >
                #{calendarRightItem.task.id}
              </Typography>
              <Typography variant="subtitle2">{calendarRightItem.task.name}</Typography>
            </Stack>
            <IconButton color="error" onClick={onClose}>
              <CloseSquare variant="Bulk" />
            </IconButton>
          </Stack>
          <Divider />
          <Card sx={{ height: 160, m: 1.5, borderRadius: 1 }}>
            <GoogleMap
              onLoad={mapProvider.onLoad}
              onUnmount={mapProvider.onUnmount}
              mapContainerStyle={{ inset: 0, position: 'absolute' }}
              center={{
                lat: calendarRightItem.point.COORDINATE_Y,
                lng: calendarRightItem.point.COORDINATE_X,
              }}
              options={{
                zoom: 18,
                minZoom: 18,
                maxZoom: 18,
                zoomControl: false,
                mapTypeControl: false,
                clickableIcons: false,
                streetViewControl: false,
                keyboardShortcuts: false,
                fullscreenControl: false,
                styles: isDark ? DarkMapStyle : null,
                backgroundColor: palette.background.default,
              }}
            >
              <Marker
                position={{
                  lat: calendarRightItem.point.COORDINATE_Y,
                  lng: calendarRightItem.point.COORDINATE_X,
                }}
              />
            </GoogleMap>
          </Card>
          <Divider />
          <Stack p={1.5}>
            <Stack direction="row" alignItems="center">
              <Typography flex={1} variant="subtitle2">
                Nokta Adı
              </Typography>
              <Typography variant="subtitle2" fontWeight="700">
                {calendarRightItem.point.name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography flex={1} variant="subtitle2">
                Task Tipi
              </Typography>
              <Typography variant="subtitle2" fontWeight="700">
                {calendarRightItem.taskTemplateName}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography flex={1} variant="subtitle2">
                Başlangıç
              </Typography>
              <Typography variant="subtitle2" fontWeight="700">
                {moment(calendarRightItem.since).format('HH:mm, DD MMMM dddd YYYY')}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography flex={1} variant="subtitle2">
                Bitiş
              </Typography>
              <Typography variant="subtitle2" fontWeight="700">
                {moment(calendarRightItem.till).format('HH:mm, DD MMMM dddd YYYY')}
              </Typography>
            </Stack>
          </Stack>
          <Chip
            variant="ghost"
            color="default"
            sx={{ borderRadius: 0 }}
            label={taskDefinitionFormTaskStatus[calendarRightItem.status]}
          />
        </Stack>
      </Popover>
    </Backdrop>
  );
}
