import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { GeomarketingFiltersStoreType, GeomarketingFiltersType } from './types';
import useBaseWorkspaceStore from '../base-workspaces';

const initialState: GeomarketingFiltersStoreType = {
  list: [],
  error: false,
  loading: true,
};

const useGeomarketingFiltersStore = create(() => ({
  ...initialState,
}));

export default useGeomarketingFiltersStore;

const { setState, getState } = useGeomarketingFiltersStore;

// REQUEST
export const getAllGeomarketingFilters = async () => {
  setState({ loading: true });
  const baseWorkspaceStore = useBaseWorkspaceStore.getState();

  try {
    const response = await axios.get<GeomarketingFiltersType[]>(
      `${endpoints.geomarketingFilters.list}/${baseWorkspaceStore.baseWorkspace?.workspaceId}`
    );
    setState({ list: response.data });
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};

export const getGeomarketingFilter = (filterId: string) => {
  const state = getState();

  return state.list.find((filter) => filter.id === filterId);
};
