import React from 'react';
import { Stack } from '@mui/material';
//
import LayoutCalendarOptionsLeftSide from './left-side';
import LayoutCalendarOptionsRightSide from './right-side';

export default function LayoutCalendarOptions() {
  return (
    <Stack
      px={1}
      flexShrink={0}
      alignItems="center"
      py={{ xs: 1.5, md: 0 }}
      bgcolor="background.default"
      height={{ xs: 'auto', md: 48 }}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={1}>
        <LayoutCalendarOptionsLeftSide />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <LayoutCalendarOptionsRightSide />
      </Stack>
    </Stack>
  );
}
