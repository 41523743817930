import moment from 'moment';
import React, { UIEvent } from 'react';
import { ArrowRight } from 'iconsax-react';
import { useShallow } from 'zustand/react/shallow';
import { Chip, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_RowVirtualizer,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
//
// import DragCell from './drag-cell';
import { TaskItem } from 'src/store/tasks/types';
import TableEmptyList from '../tasks-to-planned/empty';
import { getGeomarketingFilter } from 'src/store/geomarketing-filters';
import { dayLabelTranslate, FrequencyUnits } from 'src/helpers/frequency-unit-options';
import useMapsBottomTasksStore, { getMapsAllBottomTasks } from 'src/store/maps-bottom-tasks';

interface Props {
  tableHeight: number;
}

export default function MapBottomWrapperTasksTable(props: Props) {
  const { tableHeight } = props;
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = React.useRef<MRT_RowVirtualizer>(null);
  const mapsBottomTasksStore = useMapsBottomTasksStore(useShallow((s) => s));

  const conditionPagination =
    !mapsBottomTasksStore.loading &&
    mapsBottomTasksStore.response.totalPages > mapsBottomTasksStore.pagination.pageIndex;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollTop + clientHeight >= scrollHeight && conditionPagination) {
          getMapsAllBottomTasks(undefined, true);
        }
      }
    },
    [conditionPagination]
  );

  // tablonun zaten aşağıya kaydırılıp kaydırılmadığını ve hemen daha fazla veri getirmesi gerekip gerekmediğini görmek için montajda bir kontrol yapar
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const columns = React.useMemo<MRT_ColumnDef<TaskItem>[]>(
    () => [
      {
        size: 140,
        header: 'Task ID',
        accessorKey: 'id',
        Cell: ({ row }) => {
          const item = row.original;
          if (item.relatedTaskId) {
            return (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography variant="subtitle2">#{item.relatedTaskId}</Typography>
                <ArrowRight variant="Broken" size={20} />
                <Typography variant="subtitle2">#{item.id}</Typography>
              </Stack>
            );
          }

          return <Typography variant="subtitle2">#{item.id}</Typography>;
        },
      },
      {
        header: 'Task Adı',
        accessorKey: 'name',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
      },
      {
        header: 'Task Tipi',
        accessorKey: 'taskTemplate.name',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
      },
      {
        header: 'Kişi/Ekip/Rol/Havuz',
        accessorKey: 'assignmentsAll',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => {
          if (row.original.assignments.length === 0) {
            return <Chip size="small" variant="ghost" label={'-'} />;
          }

          const { employeeId, teamId, poolId, roleId } = row.original.assignments[0];

          let title = '';

          if (employeeId) {
            title = 'Kişi';
          }

          if (teamId) {
            title = 'Ekip';
          }

          if (roleId) {
            title = 'Rol';
          }

          if (poolId) {
            title = 'Havuz';
          }

          if (!title) return <Chip size="small" variant="ghost" label={'-'} />;

          return (
            <Chip
              size="small"
              variant="ghost"
              label={`${title}: ${row.original.assignments.length}`}
            />
          );
        },
      },
      {
        header: 'İlişkili Nokta veya Filtre',
        accessorKey: 'relatedPoint',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => {
          const item = row.original;

          const isUnPlanned =
            item.points?.filter((point) => point.createdTaskCount <= item.maxItemCount).length > 0;

          const title = isUnPlanned ? 'Planlanmamış noktalar var.' : 'Tüm noktalar planlandı.';

          if (item.geoMarketingFilters.length > 0) {
            const filter = getGeomarketingFilter(item.geoMarketingFilters[0].geoMarketFilterId);
            return (
              <Tooltip title={title} placement="right">
                <Chip
                  size="small"
                  variant="ghost"
                  label={`Filtre: ${filter?.label}`}
                  color={isUnPlanned ? 'error' : 'primary'}
                />
              </Tooltip>
            );
          }

          if (item.points?.length > 0) {
            return (
              <Tooltip title={title} placement="right">
                <Chip
                  size="small"
                  variant="ghost"
                  color={isUnPlanned ? 'error' : 'primary'}
                  label={`Nokta Sayısı: ${item.points.length}`}
                />
              </Tooltip>
            );
          }

          return <Chip size="small" label="-" />;
        },
      },
      {
        header: 'Frekans Değer/Birim',
        accessorKey: 'timeData.frequencyValue',
        muiTableHeadCellProps: { align: 'center' },
        muiTableBodyCellProps: { align: 'center' },
        Cell: ({ row }) => {
          const { frequencyUnit, frequencyValue } = row.original.timeData;

          if (!frequencyUnit && !frequencyValue) {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Chip size="small" variant="ghost" label={'-'} />
                <Divider orientation="vertical" flexItem />
                <Chip size="small" variant="ghost" label={'-'} />
              </Stack>
            );
          }

          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip size="small" label={row.original.timeData.frequencyValue} />
              <Divider orientation="vertical" flexItem />
              <Chip
                size="small"
                label={dayLabelTranslate[frequencyUnit as FrequencyUnits] || frequencyUnit}
              />
            </Stack>
          );
        },
      },
      {
        size: 220,
        header: 'Zaman Aralığı',
        accessorKey: 'timeDifference',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => {
          return (
            <Chip
              size="small"
              label={`
              ${moment(row.original.timeData.startDate).format('DD.MM.YYYY')} | ${moment(
                row.original.timeData.endDate
              ).format('DD.MM.YYYY')}
              `}
            />
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    layoutMode: 'grid',
    enableSorting: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    rowVirtualizerInstanceRef,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableColumnResizing: true,
    enableRowVirtualization: true,
    enableColumnVirtualization: true,
    data: mapsBottomTasksStore.response.list,
    getRowId: (originalRow) => `${originalRow.id}`,
    rowCount: mapsBottomTasksStore.response.totalElements,
    onRowSelectionChange: mapsBottomTasksStore.onRowSelectionChange,
    enableRowSelection: (row) => !Boolean(row.original.relatedTaskId),
    renderEmptyRowsFallback: () => <TableEmptyList tableHeight={tableHeight} />,
    rowVirtualizerOptions: { overscan: mapsBottomTasksStore.pagination.pageSize },
    state: {
      density: 'compact',
      isLoading: mapsBottomTasksStore.loading,
      rowSelection: mapsBottomTasksStore.rowSelection,
      columnPinning: { left: ['mrt-row-select'], right: ['actions'] },
      pagination: {
        pageIndex: mapsBottomTasksStore.pagination.pageIndex,
        pageSize: mapsBottomTasksStore.pagination.pageSize,
      },
    },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: tableHeight },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiTablePaperProps: { elevation: 0, sx: { borderRadius: '0', border: '0' } },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 60,
        muiTableHeadCellProps: { align: 'center' },
        muiTableBodyCellProps: { align: 'center' },
      },
    },
    muiTableHeadCellProps: () => ({
      sx: { '& .Mui-TableHeadCell-Content-Wrapper': { whiteSpace: 'nowrap' } },
    }),
  });

  return <MaterialReactTable table={table} />;
}
