import AXIOS from 'axios';
//
import { TaskItem } from 'src/store/tasks/types';
import { BASE_API, HOST_API } from 'src/config-global';
import { RejectReason } from 'src/store/reject-reason/types';
import { TaskDefinitionListType } from 'src/store/task-definition-list/types';

// ----------------------------------------------------------------------

// TODO: Mikrofrontend bu projeye bağlandığında axios alanları kaldırılacak.

const axios = AXIOS.create({
  baseURL: HOST_API,
  headers: { 'Content-Type': 'application/json' },
});

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    const err = error.response ?? error;
    if ([401, 403].includes(err?.status)) {
      window.location.href = '/';
      localStorage.removeItem('token');
      alert('Oturumunuzun süresi dolmuştur. Lütfen tekrar giriş yapınız.');
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axios;

//
export const endpoints = {
  rejectReason: {
    list: 'reject-reason/filter',
    create: 'reject-reason',
    get: (id: RejectReason['id']) => `reject-reason/${id}`,
    update: (id: RejectReason['id']) => `reject-reason/${id}`,
    delete: (id: RejectReason['id']) => `reject-reason/${id}`,
  },
  availableFunctions: {
    list: 'task-template/get-available-functions',
  },
  taskTemplate: {
    create: 'task-template',
    list: 'task-template/filter',
    get: (id: TaskDefinitionListType['id']) => `task-template/${id}`,
    delete: (id: TaskDefinitionListType['id']) => `task-template/${id}`,
    update: (id: TaskDefinitionListType['id']) => `task-template/${id}`,
    updateStatus: (id: TaskDefinitionListType['id']) => `task-template/update-status/${id}`,
    getRejectReasons: (id: TaskDefinitionListType['id']) => `task-template/${id}/reject-reason/all`,
    addRejectReasons: (id: TaskDefinitionListType['id']) => `task-template/${id}/add-reject-reason`,
    deleteRejectReasons: (id: TaskDefinitionListType['id'], rejectReasonId: RejectReason['id']) =>
      `task-template/${id}/reject-reason/${rejectReasonId}`,
    updateDistanceControl: (id: TaskDefinitionListType['id'], rejectReasonId: RejectReason['id']) =>
      `task-template/${id}/reject-reason/${rejectReasonId}`,
  },
  task: {
    create: 'task',
    list: 'task/filter',
    manuelPlan: 'task/plan',
    dashboard: 'task/dashboard',
    update: (id: TaskItem['id']) => `task/${id}`,
    delete: (id: TaskItem['id']) => `task/disable/${id}`,
    pointsAllocation: 'task/tasks-points-allocation',
    willBePlannedList: 'task/will-be-planned/filter',
  },
  users: {
    list: (size = 100, page = 0, search?: string) => {
      return `identity-service/gvg-users?size=${size}&page=${page}${
        search ? `&search=${search}` : ''
      }`;
    },
    findList: 'identity-service/gvg-users',
  },
  teams: {
    list: (size = 100, page = 0, search?: string) => {
      return `identity-service/gvg-teams?size=${size}&page=${page}${
        search ? `&search=${search}` : ''
      }`;
    },
    findList: 'identity-service/gvg-teams',
    findListWithUsers: (teamId: string) => `identity-service/gvg-team-users?teamId=${teamId}`,
  },
  roles: {
    list: () => {
      return `identity-service/gvg-roles?size=100&page=0`;
    },
    findList: 'identity-service/gvg-roles',
  },
  pools: {
    list: (size = 100, page = 0, search?: string) => {
      return `identity-service/gvg-pools?size=${size}&page=${page}${
        search ? `&search=${search}` : ''
      }`;
    },
    findList: 'identity-service/gvg-pools',
  },
  geomarketingFilters: {
    list: `${BASE_API}customer-data-search/api/v1/user`,
  },
  uploads: {
    manuelExcel: 'upload/excel/task',
  },
  geomarketingSearchPoints: (page: number, size: number) => {
    return `${BASE_API}customer-data-search/api/v1/point-app?page=${page}&size=${size}`;
  },
  taskItem: {
    filter: (page: number = 1, size: number = 1000) => {
      return `task-item/filter?size=${size}&page=${page}`;
    },
    deleteBulk: 'task-item/delete-bulk',
    createBulk: 'task-item/create-bulk',
    employePoints: 'task-item/employee-points',
    assigneesTaskItems: 'task-item/assignees-task-items',
  },
  planner: {
    assign: `${BASE_API}planner/api/v1/assign`,
  },
  pointInfo: {
    result: `${BASE_API}planner/api/v1/point-info/info-result`,
    filterQuery: `${BASE_API}planner/api/v1/point-info/filter-query`,
    mapPointSelecteds: `${BASE_API}planner/api/v1/point-info/geo-filter`,
  },
};
