export type TaskDefinitionFormStoreType = {
  functions: TaskDefinitionFormStoreFunctionsType[];
};

export type TaskDefinitionFormStoreFunctionsType = {
  type: string;
  detail: string;
  orderNo: number;
  mandatory: boolean;
  rejectReasonIdList: number[];
  attributes: Array<{
    type?: string;
    unitType?: string;
    unitValue?: number;
    key: string | number;
    value: string | number;
  }>;
};

export enum TaskDefinitionFormStoreTaskStatusEnum {
  EMPTY = '',
  REMOVED = 'REMOVED',
  CREATED = 'CREATED',
  IN_POOL = 'IN_POOL',
  UNPLANNED = 'UNPLANNED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  POSTPONED = 'POSTPONED',
  UNASSIGNED = 'UNASSIGNED',
  CREATED_PLANNED = 'CREATED_PLANNED',
  CREATED_TEAM_ASSIGN = 'CREATED_TEAM_ASSIGN',
  CREATED_USER_ASSIGNED = 'CREATED_USER_ASSIGNED',
  CREATED_USER_ASSIGN_PLANNED = 'CREATED_USER_ASSIGN_PLANNED',
  CREATED_TEAM_ASSIGN_PLANNED = 'CREATED_TEAM_ASSIGN_PLANNED',
  CREATED_POOL_ASSIGN_PLANNED = 'CREATED_POOL_ASSIGN_PLANNED',
  CREATED_ROLE_ASSIGN_PLANNED = 'CREATED_ROLE_ASSIGN_PLANNED',
}

export const taskDefinitionFormTaskStatus: Record<TaskDefinitionFormStoreTaskStatusEnum, string> = {
  [TaskDefinitionFormStoreTaskStatusEnum.EMPTY]: 'Oluşturulmadı',
  [TaskDefinitionFormStoreTaskStatusEnum.REMOVED]: 'Kaldırıldı',
  [TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED]: 'Atanmadı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED]: 'Oluşturuldu',
  [TaskDefinitionFormStoreTaskStatusEnum.POSTPONED]: 'Ertelendi',
  [TaskDefinitionFormStoreTaskStatusEnum.COMPLETED]: 'Tamamlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED]: 'Planlanmadı',
  [TaskDefinitionFormStoreTaskStatusEnum.IN_POOL]: 'Havuza Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CANCELLED]: 'İptal Edildi',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED]: 'Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN]: 'Ekibe Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGNED]: 'Kişiye Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_ROLE_ASSIGN_PLANNED]: 'Role Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN_PLANNED]: 'Ekibe Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGN_PLANNED]: 'Kişiye Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_POOL_ASSIGN_PLANNED]: 'Havuza Atanarak Planlandı',
};
