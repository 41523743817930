import { TextFieldProps } from '@mui/material/TextField';
import { Stack, Typography, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  customLabel?: string;
};

export default function RHFDateField({ name, customLabel, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack width={1}>
          {customLabel && (
            <Typography variant="subtitle2" pb={0.5}>
              {customLabel}
            </Typography>
          )}
          <TextField
            {...field}
            fullWidth
            type="date"
            error={!!error}
            value={field.value}
            helperText={error ? error?.message : helperText}
            onChange={(event) => field.onChange(event.target.value)}
            {...other}
          />
        </Stack>
      )}
    />
  );
}
