import React from 'react';
import {
  Card,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
//
import { IOSSwitch } from './rhf-switch';
import { Controller, useFormContext } from 'react-hook-form';

interface Props extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  subLabel?: string;
  helperText?: React.ReactNode;
}

export default function RHFSwitchBox(props: Props) {
  const { name, label, subLabel, helperText, ...other } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Card>
          <Stack spacing={1} direction="row" alignItems="center">
            <Stack flex={1}>
              <Typography variant="subtitle1">{label}</Typography>
              <Typography variant="body2">{subLabel}</Typography>
            </Stack>
            <FormControlLabel
              label=""
              sx={{ mr: 0 }}
              control={<IOSSwitch {...field} checked={field.value} />}
              {...other}
            />
          </Stack>
          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </Card>
      )}
    />
  );
}
