import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const MinScreenPattern = () => {
  return (
    <Alert
      severity="warning"
      sx={{ height: '100dvh', alignItems: 'center', justifyContent: 'center' }}
    >
      <AlertTitle>Uyarı</AlertTitle>
      Bu sayfa, küçük ekranlar için uygun değildir. Lütfen daha büyük bir ekran kullanın.
    </Alert>
  );
};

export default MinScreenPattern;
