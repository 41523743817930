import React from 'react';
import moment from 'moment';
import { useShallow } from 'zustand/react/shallow';
import { ArrowLeft2, ArrowRight2, Calendar, Calendar2 } from 'iconsax-react';
import {
  Box,
  alpha,
  Button,
  Tooltip,
  useTheme,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
//
import useCalendarLayoutStore from 'src/store/calendar-layout';
import useCalendarStore, { getCalendarData } from 'src/store/calendar';
import { dayLabelTranslate, FrequencyUnits } from 'src/helpers/frequency-unit-options';

export default function LayoutCalendarOptionsLeftSide() {
  const { palette } = useTheme();
  const calendarStore = useCalendarStore(useShallow((s) => s));
  const calendarLayoutStore = useCalendarLayoutStore(useShallow((s) => s));

  const isDayViewed = calendarLayoutStore.dayView === FrequencyUnits.DAY;
  const dayChangeBtnDisabled = calendarStore.loading || calendarStore.createBulkLoading;

  const onChangeDayView = () => {
    if (isDayViewed) {
      useCalendarLayoutStore.setState({ dayView: FrequencyUnits.WEEK });
    } else {
      useCalendarLayoutStore.setState({ dayView: FrequencyUnits.DAY });
    }
  };

  const handlePrevAndNext = async (type: 'prev' | 'next') => {
    const isPrev = type === 'prev';

    // change date
    const unit = isDayViewed ? 'day' : 'week';
    // Günlük görünümde ise gün, haftalık görünümde ise hafta ekler veya çıkarır
    const newDate = isPrev
      ? moment(calendarLayoutStore.date).subtract(1, unit).toDate()
      : moment(calendarLayoutStore.date).add(1, unit).toDate();

    getCalendarData(newDate);
  };

  const renderDate = () => {
    if (isDayViewed) {
      return moment(calendarLayoutStore.date).format('DD MMMM YYYY');
    }

    const startOfWeek = moment(calendarLayoutStore.date).startOf('isoWeek').format('DD');
    const endOfWeek = moment(calendarLayoutStore.date).endOf('isoWeek').format('DD MMMM YYYY');

    return `${startOfWeek} - ${endOfWeek}`;
  };

  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        onClick={onChangeDayView}
        sx={{
          px: 1,
          transition: 'none',
          bgcolor: 'background.paper',
          borderColor: alpha(palette.grey[500], 0.12),
        }}
        startIcon={
          isDayViewed ? (
            <Calendar size={24} variant="Bulk" color={palette.text.secondary} />
          ) : (
            <Calendar2 size={24} variant="Bold" color={palette.text.secondary} />
          )
        }
      >
        {dayLabelTranslate[calendarLayoutStore.dayView]} Görünümü
      </Button>
      <ToggleButtonGroup
        size="small"
        disabled={dayChangeBtnDisabled}
        sx={{ height: 36, alignItems: 'center' }}
        onChange={(_, selectVal) => handlePrevAndNext(selectVal[0])}
      >
        <Tooltip title={`Önceki ${dayLabelTranslate[calendarLayoutStore.dayView]}`}>
          <ToggleButton value="prev" sx={{ m: '2px!important' }}>
            <ArrowLeft2 size={16} />
          </ToggleButton>
        </Tooltip>
        <Box sx={{ height: 1, position: 'relative', alignContent: 'center' }}>
          <Typography variant="subtitle2" minWidth={140} textAlign="center">
            {renderDate()}
          </Typography>
          {isDayViewed && (
            <Tooltip title="Takvimden Seç">
              <Box
                type="date"
                component="input"
                onClick={(e) => e.currentTarget.showPicker()}
                value={moment(calendarLayoutStore.date).format('YYYY-MM-DD')}
                onChange={(e) => {
                  if (e.target.value) {
                    getCalendarData(moment(e.target.value).toDate());
                  }
                }}
                sx={{
                  inset: 0,
                  zIndex: 2,
                  opacity: 0,
                  position: 'absolute',
                  cursor: isDayViewed ? 'pointer' : 'auto',
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Tooltip title={`Sonraki ${dayLabelTranslate[calendarLayoutStore.dayView]}`}>
          <ToggleButton value="next" sx={{ m: '2px!important' }}>
            <ArrowRight2 size={16} />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </>
  );
}
