import _ from 'lodash';
import { create } from 'zustand';
//
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';
import {
  CalendarStoreResponseRightItem,
  CalendarStoreResponseRightItemTypesEnum,
} from '../calendar/types';
import {
  CalendarAsideStore,
  CalendarAsideResponse,
  CalendarAsideResponseItemPoints,
} from './types';

const initialState: CalendarAsideStore = {
  response: {
    users: [],
    teams: [],
    points: [],
    taskMap: {},
    unassignedPoints: [],
    calendarRightItems: [],
  },
};

const useCalendarAsideStore = create<CalendarAsideStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useCalendarAsideStore;

const { setState } = useCalendarAsideStore;

export const setCalendarAsideData = async (body: CalendarAsideResponse) => {
  const calendarRightItemMock: CalendarStoreResponseRightItem = {
    id: '',
    name: '',
    till: '',
    since: '',
    targetDate: '',
    createdDate: '',
    disabled: false,
    taskTemplateId: 0,
    rejectReasonId: 0,
    taskTemplateName: '',
    targetCompleteDate: '',
    relatedTaskItemId: null,
    originItems: { taskId: 0, pointId: '' },
    task: { id: 0, name: '', completeDuration: 0 },
    type: CalendarStoreResponseRightItemTypesEnum.EMPLOYEE,
    status: TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED,
    point: { id: '', name: '', COORDINATE_X: 0, COORDINATE_Y: 0, createdTaskCount: 0 },
    assignmentData: {
      teamId: null,
      teamName: null,
      poolId: null,
      poolName: null,
      roleId: null,
      roleName: null,
      employeeId: null,
      employeeName: null,
    },
  };

  const response = {
    users: body.users,
    teams: body.teams,
    taskMap: body.taskMap,
    unassignedPoints: body.unassignedPoints,
  };

  let calendarRightItems: CalendarStoreResponseRightItem[] = [];

  let points: CalendarAsideResponseItemPoints[] = [];

  response.users.forEach((user) => {
    points.push(...user.points);
    user.points.forEach((point) => {
      point.taskIds.forEach((taskId) => {
        const task = response.taskMap[taskId];
        calendarRightItems.push({
          ...calendarRightItemMock,
          task,
          point,
          originItems: { taskId, pointId: point.id },
        });
      });
    });
  });

  response.teams.forEach((team) => {
    points.push(...team.points);
    team.points.forEach((point) => {
      point.taskIds.forEach((taskId) => {
        const task = response.taskMap[taskId];
        calendarRightItems.push({
          ...calendarRightItemMock,
          task,
          point,
          originItems: { taskId, pointId: point.id },
        });
      });
    });
  });

  response.unassignedPoints.forEach((point) => {
    point.taskIds.forEach((taskId) => {
      const task = response.taskMap[taskId];
      calendarRightItems.push({
        ...calendarRightItemMock,
        task,
        point,
        originItems: { taskId, pointId: point.id },
      });
    });
  });

  setState({
    response: {
      ...response,
      calendarRightItems,
      points: [..._.uniqBy(points, 'id'), ...response.unassignedPoints],
    },
  });
};
