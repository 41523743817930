export enum LayerTypes {
    LayerGroup = 'LayerGroup',
    TileLayer = 'TileLayer',
    ImageLayer='ImageLayer',
    VectorLayer = 'VectorLayer',
    VectorTileLayer='VectorTileLayer',
    OSM='OSM',
    Google='Google',
    Bing='Bing',
    Here='Here',
    HereTrafic='HereTrafic',
    Yandex='Yandex',
    WFS='wfs'
  }
  export enum LayerProperties {
    Opacity = 'opacity',
    Visible = 'visible',
  }
  export enum WFSLayerWorkTypes {
    workWithExtent = 'workWithExtent',
    workWithOutExtent = 'workWithOutExtent',
  }

  export enum StaticLayerNamesAndKeys {
  COSMETIC_LAYER_GROUP_NAME ='cosmeticLayerGroup',
  INTERACTION_LAYER_NAME='drawInteraction', 
  MEASURE_LAYER_NAME='measureInteraction',
  SELECTION_DRAWING_LAYER_NAME='selectionInteraction',
  HERE_TRAFFIC_LAYER_NAME='hereTrafic',
SELECTION_LAYER_KEY = 'selection',
  }
 