import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import { mapAsideSubOnOpen } from '../maps-layout';
import { MapAsideSubPersonsStore } from './types';
import axios, { endpoints } from 'src/utils/axios';
import { MapAsidePersonsListItem } from '../map-aside-persons/types';

const initialState: MapAsideSubPersonsStore = {
  loading: null,
  personDetail: null,
  personDetailPoints: [],

  selecteds: [],
  visibles: [],
};

const useMapAsideSubPersonsStore = create<MapAsideSubPersonsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsideSubPersonsStore;

const { setState } = useMapAsideSubPersonsStore;

export const getMapAsideSubPersons = async (person: MapAsidePersonsListItem) => {
  setState({ loading: person.id });

  try {
    const res = await axios.post(endpoints.taskItem.employePoints, {
      employeeId: person.id,
    });
    setState({ personDetail: person, personDetailPoints: res.data });

    //
    mapAsideSubOnOpen('person');
  } catch (error) {
    enqueueSnackbar('Kişiye ait noktalar getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: null });
  }
};
