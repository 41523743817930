import React from 'react';
import { IMaskInput } from 'react-imask';

export const hourMinRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; // 00:00 - 23:59
export const totalDurationRegex = /^([01]?[0-9]|[1-9][0-9]):[0-5][0-9]$/; // 00:00 - 99:59
interface CustomProps {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
}

const CustomTextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  (props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { onChange, name, ...otherProps } = props;

    const handleChange = (value: string) => {
      if (onChange) {
        onChange({ target: { name: name ?? '', value } });
      }
    };

    return <IMaskInput {...otherProps} inputRef={ref} onAccept={handleChange} overwrite />;
  }
);

export default CustomTextMaskCustom;
