import React, { PropsWithChildren } from 'react';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import { Box, Collapse, Stack, SxProps, Typography } from '@mui/material';

interface Props {
  title: string;
  sx?: SxProps;
  actions?: React.ReactNode;
}

export default function ModalCollapseSeparator(props: PropsWithChildren<Props>) {
  const { title, children, actions, sx } = props;
  const [open, setOpen] = React.useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={sx}>
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        onClick={handleToggle}
        sx={{ cursor: 'pointer', pb: 2 }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        {actions}
        <Box flex={1} height={2} bgcolor="background.neutral" />
        {open ? <ArrowUp2 opacity={0.5} /> : <ArrowDown2 opacity={0.5} />}
      </Stack>
      <Collapse in={open} unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
}
