import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
//
import useMapAsideSubPoolsStore from '../map-aside-sub-pools';
import useMapAsideSubPersonsStore from '../map-aside-sub-persons';
import { MapsLayoutBottomTabsEnum, MapsLayoutEnum, MapsLayoutStore } from './types';

const initialState: MapsLayoutStore = {
  asideSubVisible: null,
  activeType: MapsLayoutEnum.L,
  bottomActiveTab: MapsLayoutBottomTabsEnum.TASKS,
};

const useMapsLayoutStore = create(
  persist<MapsLayoutStore & { reset(): void }>(
    (set) => ({
      ...initialState,
      reset: () => set((s) => ({ ...s, asideSubVisible: null })),
    }),
    {
      name: 'maps-layout',
      partialize: (s) =>
        ({
          activeType: s.activeType,
          bottomActiveTab: s.bottomActiveTab,
        } as MapsLayoutStore & { reset(): void }),

      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useMapsLayoutStore;

const { setState } = useMapsLayoutStore;

export const mapAsideSubOnOpen = (type: MapsLayoutStore['asideSubVisible']) => {
  setState({ asideSubVisible: type });

  if (type === 'pool') {
    useMapAsideSubPersonsStore.getState().reset();
  } else if (type === 'person') {
    useMapAsideSubPersonsStore.setState({ selecteds: [], visibles: [] });
    useMapAsideSubPoolsStore.getState().reset();
  }
};

export const mapAsideSubOnClosed = () => {
  useMapsLayoutStore.getState().reset();
  useMapAsideSubPoolsStore.getState().reset();
  useMapAsideSubPersonsStore.getState().reset();
};
