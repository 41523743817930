import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Stack, CircularProgress, Typography } from '@mui/material';
//
import useCalendarStore from 'src/store/calendar';
import CalendarAsideSubWrapperListItemPointsUnPlanned from './unplanned-item';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';
import useCalendarAsideUnPlannedStore, {
  getCalendarAsideUnPlanned,
} from 'src/store/calendar-aside-unplanned';

export default function CalendarAsideWrapperPointsUnPlanned() {
  const { responseRight } = useCalendarStore(useShallow((s) => s));
  const calendarAsideUnPlannedStore = useCalendarAsideUnPlannedStore(useShallow((s) => s));

  const nextFetch = () => {
    if (calendarAsideUnPlannedStore.nextLoading) {
      return;
    }

    useCalendarAsideUnPlannedStore.setState({ nextLoading: true });
    getCalendarAsideUnPlanned(true);
  };

  const responseRightUnPlanned = React.useMemo(() => {
    return [...responseRight].filter(
      (item) => item.status === TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED
    );
  }, [responseRight]);

  const hasMore =
    calendarAsideUnPlannedStore.response.totalPages > calendarAsideUnPlannedStore.page;
  const endMessage = calendarAsideUnPlannedStore.response.list.length > 0 && !hasMore;

  return (
    <InfiniteScroll
      next={nextFetch}
      hasMore={hasMore}
      scrollableTarget="scrollable-div"
      dataLength={calendarAsideUnPlannedStore.response.list.length}
      loader={
        calendarAsideUnPlannedStore.nextLoading ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      endMessage={
        endMessage && (
          <Typography variant="subtitle2" textAlign="center" color="text.disabled">
            Listenin sonuna geldiniz...
          </Typography>
        )
      }
    >
      {responseRightUnPlanned.map((item, index) => {
        return (
          <CalendarAsideSubWrapperListItemPointsUnPlanned key={index} index={index} item={item} />
        );
      })}
    </InfiniteScroll>
  );
}
