import { Feature } from 'ol';
import { FormatTypes } from '../map/helpers/enums/format.enum';
import {
  OBSERVER_ACTION_TYPES,
  OBSERVER_SUB_ACTION_TYPES_EVENT,
  OBSERVER_SUB_ACTION_TYPES_INTERACTION,
  OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER,
  OBSERVER_SUB_ACTION_TYPES_MAP,
} from '../map/helpers/enums/mapObservable.enum';

import { IAction } from '../map/helpers/interfaces/mapObservable.interfaces';

import {
  hereTraficLayerParams,
  tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean,
} from './map.lib.constants';
import { MapLibInstanceHelper } from './map.lib.Instance.helper';
import { IMl_ITileLayer, IMl_TSldStyles } from './map.lib.interfaces';
import { MeasureTypes } from '../map/helpers/enums/measure.enum';
import { InteractionTypes } from '../map/helpers/enums/interaction.enum';
import { LayerTypes } from '../map/helpers/enums/layer.enum';
import { SourceTypes } from '../map/helpers/enums/source.enum';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import { ILayerReorderEvent } from '../map/helpers/interfaces/layer.interfaces';
import VectorLayer from 'ol/layer/Vector';
import { ErrorHandler } from '../map/helpers/error.handler';

import { IMapEventsCall } from '../../pages/map';

import MVT from 'ol/format/MVT';

import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { Style, Fill, Stroke } from 'ol/style';

import CircleStyle from 'ol/style/Circle';
import { MapInstanceHelper } from '../map/helpers/map/map.instance.helper';

import { Coordinate } from 'ol/coordinate';
import { Subscription } from 'rxjs';

type IMapObservableHandler = {
  action: IAction;
  mapProviderUtils: any;
  mapEventsCall(res: IMapEventsCall): void;
};

export class MapLibHelper {
  public static mapObservableHandler({
    action,
    mapProviderUtils,
    mapEventsCall,
  }: IMapObservableHandler): void {
    switch (action.type) {
      case OBSERVER_ACTION_TYPES.MAP:
        if (action.subType === OBSERVER_SUB_ACTION_TYPES_MAP.CREATE_MAP) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.MAP->OBSERVER_SUB_ACTION_TYPES_MAP.CREATE_MAP',
            action
          );
        } else {
          console.log('ObservableHandlerExample -> OBSERVER_SUB_ACTION_TYPES_MAP.MAP-> UNKNOWN');
        }
        break;

      case OBSERVER_ACTION_TYPES.EVENT:
        if (action.subType === OBSERVER_SUB_ACTION_TYPES_EVENT.ZOOM) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.EVENT->OBSERVER_SUB_ACTION_TYPES_EVENT.ZOOM',
            action
          );
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_EVENT.SINGLE_CLICK) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.EVENT->OBSERVER_SUB_ACTION_TYPES_EVENT.SINGLE_CLICK',
            action
          );
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_EVENT.CLICK) {
          mapEventsCall(action);
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.EVENT->OBSERVER_SUB_ACTION_TYPES_EVENT.CLICK',
            action
          );
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_EVENT.CLICK_DOWN) {
          mapEventsCall(action);
        } else {
          console.log('ObservableHandlerExample -> OBSERVER_ACTION_TYPES.EVENT-> UNKNOWN');
        }
        break;

      case OBSERVER_ACTION_TYPES.INTERACTION:
        if (action.subType === OBSERVER_SUB_ACTION_TYPES_INTERACTION.MODIFY_END) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.INTERACTION->OBSERVER_SUB_ACTION_TYPES_INTERACTION.MODIFY_END',
            action
          );
          /*  let wktText = this.writeFeature(FormatTypes.WKT, action.data.feature);
          console.log(wktText); */

          let GeoJSONText = this.writeFeature(FormatTypes.GeoJSON, action.data.feature);
          console.log(GeoJSONText && JSON.parse(GeoJSONText).geometry);
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_INTERACTION.DRAW_END) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.INTERACTION->OBSERVER_SUB_ACTION_TYPES_INTERACTION.DRAW_END',
            action
          );

          /*  let wktText = this.writeFeature(FormatTypes.WKT, action.data.feature);
          console.log(wktText); */

          let GeoJSONText = this.writeFeature(FormatTypes.GeoJSON, action.data.feature);
          console.log(GeoJSONText && JSON.parse(GeoJSONText).geometry);
          if (GeoJSONText) {
            mapEventsCall(JSON.parse(GeoJSONText).geometry);
          }
          // console.log(GeoJSONText);
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_INTERACTION.TRANSLATE_END) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.INTERACTION->OBSERVER_SUB_ACTION_TYPES_INTERACTION.TRANSLATE_END',
            action
          );

          /* let wktText = this.writeFeature(FormatTypes.WKT, action.data.feature);
          console.log(wktText); */

          let GeoJSONText = this.writeFeature(FormatTypes.GeoJSON, action.data.feature);
          console.log(GeoJSONText && JSON.parse(GeoJSONText).geometry);
          //   console.log(GeoJSONText);
          if (GeoJSONText) {
            mapEventsCall(JSON.parse(GeoJSONText).geometry);
          }
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_INTERACTION.SELECTION_CHANGE) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.INTERACTION->OBSERVER_SUB_ACTION_TYPES_INTERACTION.SELECTION_CHANGE',
            action
          );

          /* let wktText = this.writeFeature(FormatTypes.WKT, action.data.feature);
          console.log(wktText); */

          let GeoJSONText = this.writeFeature(FormatTypes.GeoJSON, action.data.feature);
          console.log(GeoJSONText && JSON.parse(GeoJSONText).geometry);
          if (GeoJSONText) {
            mapEventsCall({ ...JSON.parse(GeoJSONText).geometry, subType: action.subType });
          }
        } else {
          console.log('ObservableHandlerExample -> OBSERVER_ACTION_TYPES.INTERACTION-> UNKNOWN');
        }
        break;
      case OBSERVER_ACTION_TYPES.LAYER_SWITCHER:
        if (action.subType === OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER.ADD_LAYER) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.LAYER_SWITCHER->OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER.ADD_LAYER',
            action
          );
        } else if (action.subType === OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER.REMOVE_LAYER) {
          console.log(
            'ObservableHandlerExample -> OBSERVER_ACTION_TYPES.LAYER_SWITCHER->OBSERVER_SUB_ACTION_TYPES_LAYER_SWITCHER.REMOVE_LAYER',
            action
          );
        } else {
          console.log('ObservableHandlerExample -> OBSERVER_ACTION_TYPES.EVENT-> UNKNOWN');
        }
        break;

      default:
        break;
    }
  }

  // layer metods
  public static vectorLayerExamplePointPolygonImageOBJ = {
    layerType: LayerTypes.VectorLayer,
    name: 'pointPolygonImage',
    title: 'pointPolygonImage',
    visible: true,
    opacity: 1,
    displayInLayerSwitcher: true,
    style: {
      stroke: { color: 'red', width: 2 },
      fill: { color: 'rgba(255, 255, 255, 0.8)' },
      image: {
        circleStyle: {
          radius: 7,
          fill: {
            color: 'blue',
          },
          stroke: { color: 'red', width: 2 },
        },
      },
    },
  };

  public static mapUnSubscription(subscription: Subscription): void {
    MapLibInstanceHelper.MapProviderUtils.mapUnSubscription(subscription);
  }

  public static addLayer(layer: Layer | LayerGroup | undefined): void {
    MapLibInstanceHelper.MapProviderUtils.addLayer(layer);
  }

  public static createLayer(layerProperties: any): Layer | undefined {
    return MapLibInstanceHelper.MapProviderUtils.createLayer(layerProperties);
  }
  public static createVektorLayerWithDrawFeatureFromGeojson(
    vectorLayerParams: any,
    geoJson: any
  ): VectorLayer | undefined {
    try {
      let features = MapLibInstanceHelper.MapProviderUtils.createFeatures(
        FormatTypes.GeoJSON,
        geoJson
      );
      /*     console.log(features); */
      let newLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(vectorLayerParams);
      let newSource = MapLibInstanceHelper.MapProviderUtils.createSource({
        features: features,
        sourceType: SourceTypes.VectorSource,
      });

      MapLibInstanceHelper.MapProviderUtils.setLayerSource(newLayer!, newSource!);
      if (newLayer instanceof VectorLayer) return newLayer;
      else return undefined;
    } catch (error) {
      ErrorHandler.errorReporter(error);
    }
  }

  public static addFeature2ExistingVectorLayerFromGeojson(
    vectorLayer: VectorLayer,
    geoJson: any
  ): void {
    try {
      let features: any = MapLibInstanceHelper.MapProviderUtils.createFeatures(
        FormatTypes.GeoJSON,
        geoJson
      );
      let existingSource = vectorLayer.getSource();
      if (features && Array.isArray(features))
        MapLibInstanceHelper.MapProviderUtils.addFeaturesToVectorSource(existingSource, features);
      else if (features)
        MapLibInstanceHelper.MapProviderUtils.addFeatureToVectorSource(existingSource, features);
    } catch (error) {
      ErrorHandler.errorReporter(error);
    }
  }

  public static createFeatureCollectionFromGeometryArray(geometryArray: [any]): object {
    let geojsontemplate: any = {
      type: 'FeatureCollection',
      features: null,
    };
    let geojsonFeatureTemplate: any = {
      type: 'Feature',
      properties: {},
      geometry: null,
    };
    let features: any[] = [];
    geometryArray.forEach((geom) => {
      let featureTemplate = MapLibInstanceHelper.MapProviderUtils.deepCopy(geojsonFeatureTemplate);
      featureTemplate.geometry = geom;
      features.push(featureTemplate);
    });

    let featureCollectionTemp = MapLibInstanceHelper.MapProviderUtils.deepCopy(geojsontemplate);
    featureCollectionTemp.features = features;
    return featureCollectionTemp;
  }

  public static createRotaLayer(layerName: string, LayerTitle: string, geometry: any): void {
    let geojsontemplate = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: geometry,
        },
      ],
    };

    let features = MapLibInstanceHelper.MapProviderUtils.createFeatures(
      FormatTypes.GeoJSON,
      geojsontemplate
    );
    let vektorLayerTemplate = MapLibInstanceHelper.MapProviderUtils.deepCopy(
      this.vectorLayerExamplePointPolygonImageOBJ
    );
    /*    console.log('createRotaLayer');
    console.log(features); */
    vektorLayerTemplate.name = layerName;
    vektorLayerTemplate.title = LayerTitle;
    let newLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(vektorLayerTemplate);
    let newSource = MapLibInstanceHelper.MapProviderUtils.createSource({
      features: features,
      sourceType: SourceTypes.VectorSource,
    });

    MapLibInstanceHelper.MapProviderUtils.setLayerSource(newLayer!, newSource!);
    MapLibInstanceHelper.MapProviderUtils.addLayer(newLayer!);
  }

  public static getLayerWithLayerName(name: string): any {
    try {
      let existedLayer = MapLibInstanceHelper.MapProviderUtils.getLayerWithLayerName(name);
      return existedLayer;
    } catch (error) {
      console.error('getLayerWithLayerName', error);
      return undefined;
    }
  }
  public static createLayerWmsLayer(wmsParams: any): Layer | undefined {
    try {
      const wmsLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(
        wmsParams //tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean
      );
      return wmsLayer;
    } catch (error) {
      console.error('createLayerWmsLayer', error);
      return undefined;
    }
  }
  public static imageLayerParamsExampleOBJ = {
    active: true,
    layerType: LayerTypes.ImageLayer,
    name: 'SingleTileLayerTest',
    title: 'SingleTileLayerTest',
    visible: true,
    opacity: 1,
    displayInLayerSwitcher: true,
    source: {
      sourceType: SourceTypes.ImageWMS,
      url: 'https://ahocevar.com/geoserver/wms',
      params: { LAYERS: 'topp:states' },
    },
  };
  public static createSingleTileLayer(wmsParams: any): Layer | undefined {
    try {
      const singleTileLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(
        wmsParams //tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean
      );
      return singleTileLayer;
    } catch (error) {
      console.error('createSingleTileLayer', error);
      return undefined;
    }
  }

  public static setBaseLayerToVisibleWithLayerName(layerName: string): void {
    try {
      MapLibInstanceHelper.MapProviderUtils.setBaseLayerToVisibleWithLayerName(layerName);
    } catch (error) {
      console.error('setBaseLayerToVisibleWithLayerName', error);
    }
  }
  public static setLayerVisibilityWithLayerName(layerName: string, visible: boolean): void {
    try {
      MapLibInstanceHelper.MapProviderUtils.setLayerVisibilityWithLayerName(layerName, visible);
    } catch (error) {
      console.error('setLayerVisibilityWithLayerName', error);
    }
  }

  public static createHereTrafficLayer() {
    try {
      let existedLayer = this.getLayerWithLayerName(hereTraficLayerParams.name);

      if (existedLayer) {
        console.log('Here Trafic Layer zaten var yeniden oluşturulmadı.');
        return;
      }
      let newLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(hereTraficLayerParams);
      MapLibInstanceHelper.MapProviderUtils.addLayer(newLayer!);
    } catch (error) {
      console.error('createHereTrafficLayer', error);
    }
  }

  public static changeHereTrafficLayerVisiblity(visible: boolean) {
    try {
      this.setLayerVisibilityWithLayerName(hereTraficLayerParams.name, visible);
    } catch (error) {
      console.error('createHereTrafficLayer', error);
    }
  }

  public static wmsLayerEkle(wmsParams: any): void {
    try {
      const wmsLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(
        wmsParams //tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean
      );
      wmsLayer!.getSource().refresh();
      MapLibInstanceHelper.MapProviderUtils.addLayer(wmsLayer!);
    } catch (error) {
      console.error('wmsLayerEkle', error);
    }
  }
  public static wmsLayerSil(layerName: string): void {
    try {
      let existedLayer = MapLibInstanceHelper.MapProviderUtils.getLayerWithLayerName(layerName);
      if (existedLayer) MapLibInstanceHelper.MapProviderUtils.removeLayer(existedLayer);
    } catch (error) {
      console.error('wmsLayerEkle', error);
    }
  }
  public static mongoPointLayerEkle(): void {
    try {
      const mongoPointLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(
        tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean
      );
      MapLibInstanceHelper.MapProviderUtils.addLayer(mongoPointLayer!);
    } catch (error) {
      console.error('mongoPointLayerEkle', error);
    }
  }
  public static mongoPointLayerEkleWithParam(param: string): void {
    try {
      const deepCopy: IMl_ITileLayer = MapLibInstanceHelper.MapProviderUtils.deepCopyForTileLayer(
        tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean
      );

      deepCopy!.source!.params!.CQL_Filter = param;
      const mongoPointLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(deepCopy);
      MapLibInstanceHelper.MapProviderUtils.addLayer(mongoPointLayer!);
    } catch (error) {
      console.error('mongoPointLayerEkle', error);
    }
  }

  // map actions

  public static goToCoordinate(coordinate: [number, number], zoom?: number): void {
    MapLibInstanceHelper.MapProviderUtils.goToCoordinate(coordinate, zoom);
  }
  public static goToCoordinateWithCircleOut(
    coordinate: [number, number],
    padding_left_px?: number,
    padding_bottom_px?: number,
    zoom?: number,
    delayMS?: number
  ): void {
    MapLibInstanceHelper.MapProviderUtils.goToCoordinateWithCircleOut(
      coordinate,
      padding_left_px,
      padding_bottom_px,
      zoom,
      delayMS
    );
  }
  public static goToExtentUsingFeatures(
    geometryArray: [any],
    padding_left_px?: number,
    padding_bottom_px?: number
  ): void {
    let featureColleciton =
      MapLibInstanceHelper.MapProviderUtils.createFeatureCollectionFromGeometryArray(
        geometryArray as any
      );
    MapLibInstanceHelper.MapProviderUtils.goToExtentUsingFeatures(
      featureColleciton,
      padding_left_px,
      padding_bottom_px
    );
  }

  public static setZoom(zoom: number): void {
    try {
      /*     console.log('setZoom runnnnnnn'); */
      MapLibInstanceHelper.MapProviderUtils.setZoom(zoom);
    } catch (error) {
      console.error('setZoom', error);
    }
  }

  public static increaseZoom(): void {
    try {
      MapLibInstanceHelper.MapProviderUtils.increaseZoom();
    } catch (error) {
      console.error('increaseZoom', error);
    }
  }

  public static getLastPointerSign(): any {
    try {
      return MapLibInstanceHelper.MapProviderUtils.getLastPointerSign();
    } catch (error) {
      console.error('getLastPointerSign', error);
    }
  }

  public static decreaseZoom(): void {
    try {
      return MapLibInstanceHelper.MapProviderUtils.decreaseZoom();
    } catch (error) {
      console.error('decreaseZoom', error);
    }
  }
  // EPSG:4326 formatında dönüş yapar
  public static getExtent(): any {
    try {
      return MapLibInstanceHelper.MapProviderUtils.getExtent();
    } catch (error) {
      console.error('getExtent', error);
    }
  }

  public static getMapSize(): any {
    try {
      return MapLibInstanceHelper.MapProviderUtils.getMapSize();
    } catch (error) {
      console.error('getMapSize', error);
    }
  }
  // EPSG:3857 formatında dönüş yapar
  public static getExtentToBBOX(): any {
    try {
      return MapLibInstanceHelper.MapProviderUtils.getExtentToBBOX();
    } catch (error) {
      console.error('getExtentToBBOX', error);
    }
  }

  public static getProjection(): any {
    try {
      return MapLibInstanceHelper.MapProviderUtils.getProjection();
    } catch (error) {
      console.error('getProjection', error);
    }
  }
  // helper metods
  public static generateSld(layer_name: string, sldStyles: IMl_TSldStyles): string {
    return MapLibInstanceHelper.MapProviderUtils.generateSld(layer_name, sldStyles);
  }

  ///////////////////////////////////       Observable metods   /////////////////////////////

  private static writeFeature(
    formatType: FormatTypes.WKT | FormatTypes.GeoJSON,
    feature: Feature
  ): string | undefined {
    try {
      const text = MapLibInstanceHelper.MapProviderUtils.writeFeature(formatType, feature);
      //     console.log(text);
      return text;
    } catch (error) {
      console.error('mongoPointLayerEkle', error);
    }
  }
  private static writeFeatures(
    formatType: FormatTypes.WKT | FormatTypes.GeoJSON,
    features: Feature[]
  ): string | undefined {
    try {
      const text = MapLibInstanceHelper.MapProviderUtils.writeFeatures(formatType, features);
      //     console.log(text);
      return text;
    } catch (error) {
      console.error('mongoPointLayerEkle', error);
    }
  }
  public static getLayerList(): any {
    try {
      const text = MapLibInstanceHelper.MapProviderUtils.getMapLayerList();
      /*   console.log(text); */
      return text;
    } catch (error) {
      console.error('getLayerList', error);
    }
  }
  public static setReorderedLayers(layerReorderEvent: ILayerReorderEvent): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.setReorderedLayers(layerReorderEvent);
    } catch (error) {
      console.error('setReorderedLayers', error);
    }
  }
  /////////////////////////////////measureInteraction/////////////////////////////
  /* 
ölçüm için kullanılan 2 metod ve silmek için kulşlanılan bir metod var.
*/
  public static measureInteractionForDistanceStart(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.measureInteractionCreateLayer();
      MapLibInstanceHelper.MapProviderUtils.measureInteractionOnChangeType(MeasureTypes.LineString);
    } catch (error) {
      console.error('startInteractionMeasureForDistance', error);
    }
  }

  public static measureInteractionForAreaStart(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.measureInteractionCreateLayer();
      MapLibInstanceHelper.MapProviderUtils.measureInteractionOnChangeType(MeasureTypes.Polygon);
    } catch (error) {
      console.error('startInteractionMeasureForArea', error);
    }
  }

  public static measureInteractionOnDestroy(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.measureInteractionOnDestroy();
    } catch (error) {
      console.error('measureInteractionOnDestroy', error);
    }
  }

  public static measureInteractionOnClearSource(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.measureInteractionOnClearSource();
    } catch (error) {
      console.error('measureInteractionOnClearSource', error);
    }
  }

  ///////////////////////////////// Selections //////////////////////////////////////////
  /* 
polygon çiziminde shift ile serbst çizim aktivate olur
alt ile düğüm silinir
translate ve modify hep aktif
*/

  public static drawInteractionPointStart(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.drawInteractionCreateLayer();
      MapLibInstanceHelper.MapProviderUtils.drawInteractionOnChangeType({
        drawType: InteractionTypes.Point,
      });
    } catch (error) {
      console.error('drawInteractionPointStart', error);
    }
  }

  public static drawInteractionPolygonStart(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.drawInteractionCreateLayer();
      MapLibInstanceHelper.MapProviderUtils.drawInteractionOnChangeType({
        drawType: InteractionTypes.Polygon,
      });
    } catch (error) {
      console.error('drawInteractionPolygonStart', error);
    }
  }

  public static drawInteractionLineStringStart(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.drawInteractionCreateLayer();
      MapLibInstanceHelper.MapProviderUtils.drawInteractionOnChangeType({
        drawType: InteractionTypes.LineString,
      });
    } catch (error) {
      console.error('drawInteractionPolygonStart', error);
    }
  }
  public static drawInteractionOnDestroy(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.drawInteractionOnDestroy();
    } catch (error) {
      console.error('drawInteractionOnDestroy', error);
    }
  }

  public static drawInteractionOnClear(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.drawInteractionOnClearSource();
    } catch (error) {
      console.error('onClearDrawInteractionSource', error);
    }
  }

  //// tek çizim yapıldığı için tek feature alınır
  // observer tasarlandı yapı ama olsun dursun
  public static drawInteractionGetFeature(): any {
    try {
      let feature = MapLibInstanceHelper.MapProviderUtils.getDrawInteractionLayerFeatures();
      let GeoJSONText = this.writeFeature(FormatTypes.GeoJSON, feature);
      /*       console.log(GeoJSONText && JSON.parse(GeoJSONText).geometry);
      console.log(GeoJSONText); */
    } catch (error) {
      console.error('drawInteractionGetFeature', error);
    }
  }

  //katman ortalama
  public static setExtent(extent: any) {
    try {
      const extent3857 = MapLibInstanceHelper.MapProviderUtils.transformExtent(extent);
      /*     console.log(extent3857); */
      MapLibInstanceHelper.MapProviderUtils.setExtent(extent3857);
    } catch (error) {
      console.error('setExtent', error);
    }
  }
  public static setExtentBestFit(
    extent: any,
    padding_left_px?: number,
    padding_bottom_px?: number
  ) {
    try {
      const extent3857 = MapLibInstanceHelper.MapProviderUtils.transformExtent(extent);
      /*     console.log(extent3857); */
      MapLibInstanceHelper.MapProviderUtils.setExtentBestFit(
        extent3857,
        padding_left_px,
        padding_bottom_px
      );
    } catch (error) {
      console.error('setExtentBestFit', error);
    }
  }
  // cosmeticLayerGroupManagement

  public static getCosmeticLayerGroupList(cosmeticLayerGroupKey: string): any[] | undefined {
    return MapLibInstanceHelper.MapProviderUtils.getCosmeticLayerGroupList(cosmeticLayerGroupKey);
  }

  public static createCosmeticLayerGroup(cosmeticLayerGroupKey: string): LayerGroup | undefined {
    return MapLibInstanceHelper.MapProviderUtils.createCosmeticLayerGroup(cosmeticLayerGroupKey);
  }

  public static findCosmeticLayerGroupWithKey(
    cosmeticLayerGroupKey: string
  ): LayerGroup | undefined {
    return MapLibInstanceHelper.MapProviderUtils.findCosmeticLayerGroupWithKey(
      cosmeticLayerGroupKey
    );
  }
  public static addCosmeticLayerToCosmeticLayerGroup(
    cosmaticLayerGroup: LayerGroup | undefined,
    layer: Layer | undefined
  ): void {
    MapLibInstanceHelper.MapProviderUtils.addCosmeticLayerToCosmeticLayerGroup(
      cosmaticLayerGroup,
      layer
    );
  }

  public static removeCosmeticLayerInGroupLayer(
    cosmeticLayerGroupKey: string,
    layerName: string
  ): void {
    MapLibInstanceHelper.MapProviderUtils.removeCosmeticLayerInGroupLayer(
      cosmeticLayerGroupKey,
      layerName
    );
  }
  public static removeAllCosmeticLayersInGroupLayer(cosmeticLayerGroupKey: string): void {
    MapLibInstanceHelper.MapProviderUtils.removeAllCosmeticLayersInGroupLayer(
      cosmeticLayerGroupKey
    );
  }

  // click Handler
  public static createClickListener(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.createClickListener();
    } catch (error) {
      console.error('createClickListener', error);
    }
  }
  public static removeClickListener(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.removeClickListener();
    } catch (error) {
      console.error('removeClickListener', error);
    }
  }
  public static createClickDownListener(pointerUpDownDelayMilisecond: number): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.createClickDownListener(pointerUpDownDelayMilisecond);
    } catch (error) {
      console.error('createClickDownListener', error);
    }
  }
  public static removeClickDownListener(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.removeClickDownListener();
    } catch (error) {
      console.error('removeClickDownListener', error);
    }
  }
  public static deactivateInteractions(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.deactivateInteractions();
    } catch (error) {
      console.error('deactivateInteractions', error);
    }
  }
  public static activateInteractions(): any {
    try {
      MapLibInstanceHelper.MapProviderUtils.activateInteractions();
    } catch (error) {
      console.error('activateInteractions', error);
    }
  }
  // selectionInteraction metods
  // useage Example for point
  public static selectionInteractionForPointUseage() {
    // create interactionLayer for points with icon style
    let layer = this.selectionInteractionCreateLayer(InteractionTypes.Point, {
      stroke: { color: 'blue', width: 2 },
      fill: { color: 'rgba(255, 255, 255, 0.8)' },
      image: {
        icon: {
          color: 'blue',
          crossOrigin: 'anonymous',
          // For Internet Explorer 11
          imgSize: [20, 20],
          src: window.location.origin + '/map-marker-area-line-svgrepo-com.svg',
        },
      },
    });
    console.log(layer);

    //NOT:  'FeatureCollection' oluşturmak için kullanılabiliir.

    let _featureCollection = this.createFeatureCollectionFromGeometryArray([
      {
        type: 'Point',
        coordinates: [29.063987731933594, 41.0565730874647],
      },
    ]);

    this.selectionInteractionLayerAddFeatures(_featureCollection);

    /*  setTimeout(() => {
      // interaction kaldırılır
      this.selectionInteractionLayerOnDestroy();
    }, 5000); */
  }
  public static selectionInteractionForPolygonUseage() {
    // create interactionLayer for points with icon style
    this.selectionInteractionCreateLayer(InteractionTypes.Polygon, {
      stroke: { color: 'blue', width: 2 },
      fill: { color: 'rgba(255, 255, 255, 0.8)' },
    });

    //NOT:  'FeatureCollection' oluşturmak için kullanılabiliir.

    let _featureCollection = this.createFeatureCollectionFromGeometryArray([
      {
        type: 'Polygon',
        coordinates: [
          [
            [28.96991729736328, 41.075986705231024],
            [28.97609710693359, 41.04880603558486],
            [29.014549255371094, 41.06304493021621],
            [29.00802612304687, 41.083750547474075],
            [28.96991729736328, 41.075986705231024],
          ],
        ],
      },
    ]);

    this.selectionInteractionLayerAddFeatures(_featureCollection);

    setTimeout(() => {
      // interaction kaldırılır
      this.selectionInteractionLayerOnDestroy();
    }, 10000);
  }
  public static selectionInteractionCreateLayer(
    interactionType: InteractionTypes,
    styles: any,
    force?: boolean
  ): any {
    try {
      let selectionInteractionLayer =
        MapLibInstanceHelper.MapProviderUtils.selectionInteractionCreateLayer(styles, force);
      MapLibInstanceHelper.MapProviderUtils.selectionInteractionOnChangeType({
        drawType: interactionType,
      });
      return selectionInteractionLayer;
    } catch (error) {
      console.error('selectionInteractionCreateLayer', error);
    }
  }

  public static selectionInteractionLayerAddFeatures(geoJsonObject: any): void {
    try {
      let features: Feature | Feature[] | undefined =
        MapLibInstanceHelper.MapProviderUtils.createFeatures(FormatTypes.GeoJSON, geoJsonObject);
      if (features && typeof features === 'object')
        MapLibInstanceHelper.MapProviderUtils.selectionInteractionsAddFeaturesSelectionInteractionLayerSource(
          features
        );
    } catch (error) {
      console.error('selectionInteractionLayerAddFeatures', error);
    }
  }

  public static selectionInteractionLayerOnDestroy(): void {
    try {
      MapLibInstanceHelper.MapProviderUtils.selectionInteractionOnDestroy();
    } catch (error) {
      console.error('selectionInteractionLayerOnDestroy', error);
    }
  }

  // setFeatureStyle Example
  // convexhull

  public static convexhullSetFeatureStyleUseage() {
    // create interactionLayer for points with icon style
    let vectorLayerConvexhullSetFeatureOBJ = {
      layerType: LayerTypes.VectorLayer,
      name: 'convexhull',
      title: 'convexhull',
      visible: true,
      opacity: 1,
      displayInLayerSwitcher: true,
    };

    let arrGeojson = [
      {
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [28.901366734320362, 41.163190845482944],
              [28.901366734320362, 41.069510355538625],
              [29.030440048074723, 41.069510355538625],
              [29.030440048074723, 41.163190845482944],
              [28.901366734320362, 41.163190845482944],
            ],
          ],
        },
        properties: {
          style: {
            stroke: { color: 'red', width: 2 },
            fill: { color: 'rgba(255,0,0, 0.8)' },
          },
        },
      },
      {
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [29.07784826334725, 41.16430672592466],
              [29.07784826334725, 41.066279406980755],
              [29.22986394996056, 41.066279406980755],
              [29.22986394996056, 41.16430672592466],
              [29.07784826334725, 41.16430672592466],
            ],
          ],
        },
        properties: {
          style: {
            stroke: { color: 'blue', width: 4 },
            fill: { color: 'rgba(0, 0, 255, 0.3)' },
          },
        },
      },
      {
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [28.90239013296423, 41.05247892326088],
              [28.803551007070467, 41.10069359217374],
              [28.741094544183795, 41.057669216600345],
              [28.81784463007702, 40.989579102966474],
              [29.237766061195202, 40.90246653129887],
              [29.223094482960335, 41.05095083611707],
              [28.90239013296423, 41.05247892326088],
            ],
          ],
        },
        properties: {
          style: {
            stroke: { color: 'yellow', width: 6 },
            fill: { color: 'rgba(255, 255, 0, 0.5)' },
          },
        },
      },
    ];
    let featureColleciton = this.createFeatureCollectionFromGeoJsonArray(arrGeojson as any);
    let layer = this.createVektorLayerWithDrawFeatureFromGeojson(
      vectorLayerConvexhullSetFeatureOBJ,
      featureColleciton
    );
    this.addLayer(layer);
    if (layer) this.setFeatureStyle2ExistingVektorLayerFromOwnProperties(layer, 'style');
  }

  public static setFeatureStyle2ExistingVektorLayerFromOwnProperties(
    layer: VectorLayer,
    styleRootPropertyName: string
  ): void {
    MapLibInstanceHelper.MapProviderUtils.setFeatureStyle2ExistingVektorLayerFromOwnProperties(
      layer,
      styleRootPropertyName
    );
  }

  public static createFeatureCollectionFromGeoJsonArray(GeojsonArray: [any]): object {
    return MapLibInstanceHelper.MapProviderUtils.createFeatureCollectionFromGeoJsonArray(
      GeojsonArray
    );
  }

  public static goToExtentUsingFeaturesUseage(
    padding_left_px?: number,
    padding_bottom_px?: number
  ): void {
    /*  let arr = [
        {
          coordinates: [
            29.0774634992167,

            40.9639948123194,
          ],

          type: "Point",
        },
        {
          coordinates: [
            29.0902504721318,

            40.9627732223689,
          ],

          type: "Point",
        },
      ]; */
    /*     let arrPoly=[{
        type: 'Polygon',
        coordinates: [
          [
            [28.96991729736328, 41.075986705231024],
            [28.97609710693359, 41.04880603558486],
            [29.014549255371094, 41.06304493021621],
            [29.00802612304687, 41.083750547474075],
            [28.96991729736328, 41.075986705231024],
          ],
        ],
      }] */

    let arrMultiPoly = [
      {
        type: 'Point',
        coordinates: [28.96991729736328, 41.075986705231024],
      },
    ];

    let featureColleciton =
      MapLibInstanceHelper.MapProviderUtils.createFeatureCollectionFromGeometryArray(
        arrMultiPoly as any
      );
    const vectorLayerExample = {
      layerType: LayerTypes.VectorLayer,
      name: 'pointPolygonImage',
      title: 'pointPolygonImage',
      visible: true,
      opacity: 1,
      displayInLayerSwitcher: true,
    };
    let layer = MapLibInstanceHelper.MapProviderUtils.createVektorLayerWithDrawFeatureFromGeojson(
      vectorLayerExample,
      featureColleciton
    );
    this.addLayer(layer);
    this.goToExtentUsingFeatures(arrMultiPoly as any, padding_left_px, padding_bottom_px);
  }

  //////////////////////////   MVT Helperss start

  public static getMvtLayerProperties(
    indexName: string,
    geometryFieldName: string,
    renderMethod: string
  ) {
    const mvtExample: IMl_ITileLayer = {
      active: true,
      layerType: LayerTypes.VectorTileLayer,
      name: 'mvtExample',
      title: 'mvtExample',
      visible: true,
      opacity: 1,
      disabledInLayerSwitcher: true,
      source: {
        sourceType: SourceTypes.VectorTileSource,
        url: `http://localhost:8081/api/rest/v1/mvt/tile?index=${indexName}&geometry=${geometryFieldName}&renderMethod=${renderMethod}&x={x}&y={y}&z={z}`,
      },
      style: {
        /*     stroke: { color: "blue", width: 2 },
    fill: { color: "rgba(255, 255, 255, 0.8)" }, */
        image: {
          icon: {
            color: 'blue',
            crossOrigin: 'anonymous',
            // For Internet Explorer 11
            imgSize: [20, 20],
            src: window.location.origin + '/map-marker-area-line-svgrepo-com.svg',
          },
        },
      },
    };
    return mvtExample;
  }
  public static createMvtLayer(): void {
    try {
      /* const mvtLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(this.getMvtLayerProperties('gvg.cdi_65e86bec4de34f1c9e86613b','geometry','hits'));
     if (mvtLayer instanceof VectorTileLayer)
    mvtLayer.setStyle(new Style({
      image: new CircleStyle({
   
        radius: 4,
        fill: new Fill({
          color: 'blue',
        }),
        stroke: new Stroke({
          color: 'yellow',
          width: 1,
        }),
      }),
    }) ) */
      /*  {
      stroke: { color: "blue", width: 2 },
      fill: { color: "rgba(255, 255, 255, 0.8)" },
      image: {
        icon: {
          color: "blue",
          crossOrigin: "anonymous",
          // For Internet Explorer 11
          imgSize: [20, 20],
          src: window.location.origin + "/map-marker-area-line-svgrepo-com.svg",
        },
      },
    }, */
      let style = MapLibInstanceHelper.MapProviderUtils.createStyleFunction({
        /*  stroke: { color: "blue", width: 2 },
          fill: { color: "rgba(255, 255, 255, 0.8)" }, */
        image: {
          icon: {
            color: 'blue',
            crossOrigin: 'anonymous',
            // For Internet Explorer 11
            imgSize: [20, 20],
            src: window.location.origin + '/map-marker-area-line-svgrepo-com.svg',
          },
        },
      });
      const mvtLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(
        this.getMvtLayerProperties('gvg.cdi_65e86bec4de34f1c9e86613b', 'geometry', 'hits')
      );

      if (mvtLayer instanceof VectorTileLayer) mvtLayer?.setStyle(style);

      this.addLayer(mvtLayer);

      setTimeout(() => {
        mvtLayer?.setVisible(false);
        setTimeout(() => {
          mvtLayer?.setVisible(true);
          setTimeout(() => {
            mvtLayer?.setVisible(false);
          }, 5000);
        }, 5000);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }

  //https://cip.tuik.gov.tr/assets/veri/grid_katmani/10/595/382.bin
  public static tuik_test(): void {
    try {
      let tuik_layer_properties: IMl_ITileLayer = {
        active: true,
        layerType: LayerTypes.VectorTileLayer,
        name: 'tuik_layer',
        title: 'mvtExtuik_layerample',
        visible: true,
        opacity: 1,
        disabledInLayerSwitcher: true,
        /*  minZoom: 12,
      maxZoom:12, */
        minResolution: 2,
        maxResolution: 250,
        source: {
          sourceType: SourceTypes.VectorTileSource,
          /*  minZoom: 12,
        maxZoom:16, */
          url: `https://cip.tuik.gov.tr/assets/veri/grid_katmani/{z}/{x}/{y}.bin`,
        },
      };
      /*  let _style= new Style({
        fill: new Fill({
          color: 'black',
        }),
        stroke: new Stroke({
          color: 'white',
          width: 1,
        })
      
    });  */

      let customStyleFunction = (feature: any, resolution: any) => {
        let fiilColor;
        let opacity = '0.6';
        if (feature.get('toplam_nufus') < 0) {
          fiilColor = `rgba(255, 255, 255,0.0)`;
        } else if (feature.get('toplam_nufus') === 0) {
          fiilColor = `rgba(255, 255, 255,${opacity})`;
        } else if (feature.get('toplam_nufus') > 0 && feature.get('toplam_nufus') <= 500) {
          fiilColor = `rgba(254, 229, 217,${opacity})`;
        } else if (feature.get('toplam_nufus') > 500 && feature.get('toplam_nufus') <= 2000) {
          fiilColor = `rgba(252, 187, 161,${opacity})`;
        } else if (feature.get('toplam_nufus') > 2000 && feature.get('toplam_nufus') <= 5000) {
          fiilColor = `rgba(252, 146, 114,${opacity})`;
        } else if (feature.get('toplam_nufus') > 5000 && feature.get('toplam_nufus') <= 10000) {
          fiilColor = `rgba(251, 106, 74,${opacity})`;
        } else if (feature.get('toplam_nufus') > 10000 && feature.get('toplam_nufus') <= 20000) {
          fiilColor = `rgba(239, 59, 44,${opacity})`;
        } else if (feature.get('toplam_nufus') > 20000 && feature.get('toplam_nufus') <= 30000) {
          fiilColor = `rgba(203, 24, 29,${opacity})`;
        } else if (feature.get('toplam_nufus') > 30000 && feature.get('toplam_nufus') <= 100000) {
          fiilColor = `rgba(153, 0, 13,${opacity})`;
        }

        return [
          new Style({
            fill: new Fill({
              color: fiilColor,
            }),
            stroke: new Stroke({
              color: 'white',
            }),
          }),
        ];
      };
      MapInstanceHelper.Map.getView().on('change:resolution', () => {
        /* console.log(MapInstanceHelper.Map.getView().getZoom())
console.log(MapInstanceHelper.Map.getView().getResolution()) */
      });
      const mvtLayer = MapLibInstanceHelper.MapProviderUtils.createLayer(tuik_layer_properties);

      if (mvtLayer instanceof VectorTileLayer) mvtLayer?.setStyle(customStyleFunction);

      this.addLayer(mvtLayer);
      /*   MapInstanceHelper.Map.on('pointermove', (e)=> {

        MapInstanceHelper.Map.forEachFeatureAtPixel(e.pixel,  (f)=> {
          console.log(f.getProperties())
          return true;
        });
     
      }); */
      /*   map.on('pointermove', function (evt) {
        if (evt.dragging) {
          return;
        }
        const pixel = map.getEventPixel(evt.originalEvent);
        displayFeatureInfo(pixel);
      });
      
      map.on('click', function (evt) {
        displayFeatureInfo(evt.pixel);
      }); */
      MapInstanceHelper.Map.on('click', function (evt) {
        MapInstanceHelper.Map.forEachFeatureAtPixel(evt.pixel, (f) => {
          console.log(f.getProperties());
          return true;
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  public static mvt_test(): void {
    try {
      let indexName = 'gvg.cdi_65e86bec4de34f1c9e86613b';
      //let indexName='gvg.cdi_65f7ea285471aec7a1a2fb90'
      let geometryFieldName = 'geometry';
      let renderMethod = 'hits';
      // let renderMethod='grid';
      //  let renderMethod='hex';
      /*  let _style=new Style({
      stroke:new Stroke({ color: "blue", width: 2 }),
      fill: new Fill({ color: "rgba(255, 255, 255, 0.8)" }),
       image: new Icon({
          color: "blue",
          crossOrigin: "anonymous",
          // For Internet Explorer 11
          imgSize: [20, 20],
          src: window.location.origin + "/map-marker-area-line-svgrepo-com.svg",
      }),
    })  */

      let _style = new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
        }),
      });

      /*   var svg = '<svg width="120" height="120" version="1.1" xmlns="http://www.w3.org/2000/svg">'
    + '<circle cx="60" cy="60" r="6"/>'
    + '</svg>';

var _style = new  Style({
  image: new Icon({
    opacity: 1,
    src: 'data:image/svg+xml;utf8,' + svg,
    scale: 0.3
  })
}); */

      let _mvLayer = new VectorTileLayer({
        /* declutter:true,  */
        source: new VectorTileSource({
          format: new MVT(),
          url: `http://localhost:8081/api/rest/v1/mvt/tile?index=${indexName}&geometry=${geometryFieldName}&renderMethod=${renderMethod}&x={x}&y={y}&z={z}`,
        }),
        style: _style,
      });
      this.addLayer(_mvLayer);
      setTimeout(() => {
        console.log('style calll');
        _mvLayer.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({
                color: 'blue',
              }),
              stroke: new Stroke({
                color: 'yellow',
                width: 1,
              }),
            }),
          })
        );
        setTimeout(() => {
          _mvLayer.setVisible(false);
          setTimeout(() => {
            _mvLayer.setVisible(true);
            setTimeout(() => {
              _mvLayer.setVisible(false);
            }, 5000);
          }, 5000);
        }, 5000);
      }, 10000);

      MapInstanceHelper.Map.on('pointermove', (e) => {
        MapInstanceHelper.Map.forEachFeatureAtPixel(e.pixel, (f) => {
          console.log(f.getProperties());
          return true;
        });
      });
    } catch (error) {}
  }

  //////////////////////////   MVT Helperss end

  public static transformCoordinateToWGS84(coordinate: Coordinate) {
    const source = MapLibInstanceHelper.MapProviderUtils.getFeatureProjection();
    const target = MapLibInstanceHelper.MapProviderUtils.getDataProjection();

    try {
      return MapLibInstanceHelper.MapProviderUtils.transformCoordinate(coordinate, source, target);
    } catch (error) {
      throw error;
    }
  }
}
