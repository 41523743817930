import { Box, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  customLabel?: string;
  disableHelperText?: boolean;
};

export default function RHFTextField({
  name,
  type,
  helperText,
  customLabel,
  disableHelperText,
  ...other
}: Props) {
  const { control, register } = useFormContext();

  const isNumber = type === 'number';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box width={other.fullWidth ? 1 : 'auto'}>
          {customLabel && (
            <Typography variant="subtitle2" pb={0.5}>
              {customLabel}
            </Typography>
          )}
          <TextField
            {...field}
            type={type}
            ref={register(name).ref} // overriding field.ref
            value={isNumber && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (isNumber) {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            helperText={disableHelperText ? undefined : error ? error?.message : helperText}
            {...other}
          />
        </Box>
      )}
    />
  );
}
