import React from 'react';
import { Box, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
//
import requests from 'src/utils/requests';
import TaskSelectedBox from '../../task-selected-box';
import MapAsideSubWrapperEmptyDetail from '../empty-detail';
import { TaskItemsDeletePayload } from 'src/utils/requests/types';
import MapAsideSubWrapperPersonTeamDetailCollapses from './collapses';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';
import MapAsideSubWrapperMultipleProcessesSelectbox, {
  MultipleProcessesOptionType,
} from '../multiple-processes-select-box';

export default function MapAsideSubWrapperPersonDetail() {
  const [loading, setLoading] = React.useState(false);
  const { visibles, selecteds, personDetailPoints } = useMapAsideSubPersonsStore((s) => ({
    visibles: s.visibles,
    selecteds: s.selecteds,
    personDetailPoints: s.personDetailPoints,
  }));

  if (personDetailPoints.length === 0) {
    return <MapAsideSubWrapperEmptyDetail />;
  }

  const handleMultiple = async (type: MultipleProcessesOptionType['id']) => {
    if (type !== 'delete') {
      return;
    }

    setLoading(true);

    const payload: TaskItemsDeletePayload[] = selecteds.map((selected) => {
      return {
        id: Number(selected.id),
        task: { id: selected.task.id },
        point: { id: selected.point.id },
      };
    });

    try {
      await requests.taskItemsDelete(payload);
      const visibleIds = visibles.filter((id) => !payload.map((p) => p.point.id).includes(id));
      useMapAsideSubPersonsStore.setState({ selecteds: [], visibles: visibleIds });
    } catch (error) {
      enqueueSnackbar('Seçilen tasklar silinirken bir hata oluştu.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack flex={1} overflow="hidden">
      <Box p={1.5}>
        <MapAsideSubWrapperMultipleProcessesSelectbox
          loading={loading}
          onClick={handleMultiple}
          disabled={selecteds.length === 0}
        />
      </Box>
      <Stack
        id="person-team-detail-scrollable-div"
        sx={{
          flex: 1,
          p: 1.5,
          pt: 0,
          gap: 1.5,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { gap: 1.25, display: 'flex', flexDirection: 'column' },
        }}
      >
        <MapAsideSubWrapperPersonTeamDetailCollapses />
      </Stack>
      <TaskSelectedBox position="sticky" bottom={0} m={1.5} />
    </Stack>
  );
}
