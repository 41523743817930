export class ErrorHandler {
  public static errorReporter(error: Error | unknown) {
    try {
      if (error instanceof Error) {
        console.log('Error Message:',error);
      } else {
        console.log('Unknown Failure:', error);
      }
    } catch (error) {
      console.log('Error Handler\' Error Var :)');
    }
  }
  public static developerWarning(message: string) {
    try {

        console.log('DEVELOPER WARNING : ', message);
      
    } catch (error) {
      console.log('Error Handler`\' Error Var :)');
    }
  }
}
