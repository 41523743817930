import React from 'react';
import { Eye } from 'iconsax-react';
import { Stack, Avatar, Checkbox, IconButton, Typography, CircularProgress } from '@mui/material';
//
import { StyledBadge } from 'src/layouts/dashboard/navbar/profile-box';
import { MapAsidePersonsListItem } from 'src/store/map-aside-persons/types';
import { onChangeMapAsidePersonsChecked } from 'src/store/map-aside-persons';
import useMapAsideSubPersonsStore, { getMapAsideSubPersons } from 'src/store/map-aside-sub-persons';

interface Props {
  checked: boolean;
  item: MapAsidePersonsListItem;
}

export default function MapAsideWrapperTaskAssignmentPersonsItem(props: Props) {
  const { personDetail, loading } = useMapAsideSubPersonsStore((s) => ({
    loading: s.loading,
    personDetail: s.personDetail,
  }));
  const { item, checked } = props;

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        px: 1.25,
        flexShrink: 0,
        minHeight: 58,
        borderRadius: 1,
        bgcolor: 'background.default',
      }}
    >
      <Checkbox
        disableRipple
        sx={{ px: 0 }}
        checked={checked}
        onChange={(e) => {
          onChangeMapAsidePersonsChecked(item, e.target.checked);
        }}
      />
      <Stack flex={1} spacing={1} direction="row" overflow="hidden" alignItems="center">
        <StyledBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Avatar sx={{ width: 32, height: 32 }} src="" />
        </StyledBadge>
        <Stack
          flex={1}
          overflow="hidden"
          sx={{
            '& > *': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <Typography variant="subtitle2">{item.userName}</Typography>
          <Typography variant="caption" color="text.disabled">
            {item.email}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {/* <Chip size="small" variant="ghost" label="20%" /> */}
        <IconButton
          size="small"
          disabled={Boolean(loading)}
          onClick={() => getMapAsideSubPersons(item)}
          color={personDetail?.id === item.id ? 'primary' : 'default'}
        >
          {loading === item.id ? <CircularProgress size={24} /> : <Eye variant="Bulk" size={24} />}
        </IconButton>
      </Stack>
    </Stack>
  );
}
