import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Stack, Typography } from '@mui/material';
//
import Item from './item';
import { useShallow } from 'zustand/react/shallow';
import LoadingItems from 'src/components/loading-items';
import MapAsideWrapperEmptyDetail from '../../../empty-detail';
import MapAsideWrapperTaskAssignmentPoolsItemEmpty from './empty';
import useMapAsidePoolsStore, { getMapsAsidePoolsSearch } from 'src/store/map-aside-pools';

export default function MapAsideWrapperTaskAssignmentPools() {
  const mapAsidePoolsStore = useMapAsidePoolsStore(useShallow((s) => s));

  if (mapAsidePoolsStore.loading) {
    return (
      <LoadingItems height={1} count={20} spacing={1.5}>
        <MapAsideWrapperTaskAssignmentPoolsItemEmpty />
      </LoadingItems>
    );
  }

  if (mapAsidePoolsStore.response.count === 0) {
    return <MapAsideWrapperEmptyDetail />;
  }

  const nextFetch = () => {
    if (mapAsidePoolsStore.paginationLoading) {
      return;
    }

    useMapAsidePoolsStore.setState({ paginationLoading: true });
    getMapsAsidePoolsSearch(mapAsidePoolsStore.search, true);
  };

  const hasMore = mapAsidePoolsStore.response.totalPages > mapAsidePoolsStore.response.totalItems;
  const endMessage = mapAsidePoolsStore.response.items.length > 0 && !hasMore;

  return (
    <InfiniteScroll
      next={nextFetch}
      hasMore={hasMore}
      dataLength={mapAsidePoolsStore.response.items.length}
      loader={
        mapAsidePoolsStore.loading ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      scrollableTarget="persons-teams-pools-scrollable-div"
      endMessage={
        endMessage && (
          <Typography variant="subtitle2" textAlign="center" color="text.disabled">
            Listenin sonuna geldiniz...
          </Typography>
        )
      }
    >
      {mapAsidePoolsStore.response.items.map((pool) => {
        const checked = mapAsidePoolsStore.selectedItems.some((item) => item.id === pool.id);

        return <Item key={pool.id} item={pool} checked={checked} />;
      })}
    </InfiniteScroll>
  );
}
