import React from 'react';
import { Box, Divider } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
//
import useCalendarStore from 'src/store/calendar';
import CalendarAsideWrapperPointsPlanned from './planned';
import useCalendarAsideStore from 'src/store/calendar-aside';
import CalendarAsideWrapperPointsUnPlanned from './unplanned';
import { AsideWrapperTabsItem } from 'src/components/aside-tabs';
import AsideWrapperTabsCustom from 'src/components/aside-tabs-custom';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';
//

export default function CalendarAsideWrapperPoints() {
  const points = useCalendarAsideStore((s) => s.response.points);
  const calendarStore = useCalendarStore(useShallow((s) => s));

  const unPlannedCount = React.useMemo(() => {
    const unAssignedInRightList = calendarStore.responseRight.filter((item) => {
      return item.status === TaskDefinitionFormStoreTaskStatusEnum.UNPLANNED;
    });

    return unAssignedInRightList.length;
  }, [calendarStore.responseRight]);

  const tabs: AsideWrapperTabsItem[] = [
    { id: 'planned', label: 'Planlanacak', count: points.length },
    { id: 'unplanned', label: 'Plandan Çıkarılan', count: unPlannedCount },
  ];
  const [activeTab, setActiveTab] = React.useState<AsideWrapperTabsItem>(tabs[0]);

  const handleTabChange = (tab: AsideWrapperTabsItem) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab.id === 'planned') {
      return <CalendarAsideWrapperPointsPlanned />;
    }

    return <CalendarAsideWrapperPointsUnPlanned />;
  };

  return (
    <>
      <Box px={0.75}>
        <AsideWrapperTabsCustom
          tabs={tabs}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Box>
      <Divider />
      <Box
        id="scrollable-div"
        sx={{
          p: 1.5,
          flex: 1,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { display: 'flex', flexDirection: 'column', gap: 1.25 },
        }}
      >
        {renderContent()}
      </Box>
    </>
  );
}
