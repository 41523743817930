import * as React from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { Avatar, Divider, IconButton, Stack, Typography } from '@mui/material';
//
import CalendarAsideWrapperListItemPoints from '../item-points';
import { CalendarAsideResponseItem } from 'src/store/calendar-aside/types';

type Props = {
  assign: CalendarAsideResponseItem;
};

export default function CalendarAsideWrapperListItem(props: Props) {
  const { assign } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <Stack sx={{ bgcolor: 'background.default', borderRadius: 1, overflow: 'hidden' }}>
      <Stack direction="row" spacing={1.5} alignItems="center" px={1.5} height={58}>
        <Avatar sx={{ width: 32, height: 32 }} />
        <Stack flex={1} justifyContent="center">
          <Typography variant="subtitle2">
            {assign.name}{' '}
            <Typography variant="subtitle1" component="b" color="primary.light">
              %50
            </Typography>
          </Typography>
          <Typography variant="caption">Kullanıcı Bilgi Alanı</Typography>
        </Stack>
        <IconButton size="small" onClick={() => setVisible(!visible)}>
          {visible ? <ArrowUp2 size={24} /> : <ArrowDown2 size={24} />}
        </IconButton>
      </Stack>
      <Stack px={0.75} display={visible ? 'flex' : 'none'}>
        <Divider />
        <Stack spacing={0.5} py={0.75}>
          {assign.points.map((point, index) => {
            return <CalendarAsideWrapperListItemPoints key={index} point={point} index={index} />;
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
