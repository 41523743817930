import React from 'react';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { Autocomplete, Box, Card, Dialog, Stack, TextField, Typography } from '@mui/material';
//
import requests from 'src/utils/requests';
import useFetch from 'src/hooks/use-fetch';
import Loading from 'src/components/loading';
import { dateQuarterMinAndHour } from 'src/utils';
import axios, { endpoints } from 'src/utils/axios';
import { getCalendarData } from 'src/store/calendar';
import ModalFooter from 'src/components/modals/footer';
import ModalHeader from 'src/components/modals/header';
import ModalContent from 'src/components/modals/content';
import { PlannerAssignPayload } from 'src/utils/requests/types';
import { TeamsResponseType, TeamsType } from 'src/store/teams/types';
import { UsersResponseType, UsersType } from 'src/store/users/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from 'src/store/task-definition-form/types';
import {
  CalendarCreateBulkPayload,
  CalendarStoreResponse,
  CalendarStoreResponseRightItem,
} from 'src/store/calendar/types';
import {
  AutoPlanningAlgorithmTypeEnum,
  AutoPlanningWayCalculationEnum,
  AutoPlanningModelOptimizationEnum,
} from 'src/components/modals/auto-planning-model/types';

const defaultValue = {
  status: true,
  message: null,
  data: { count: 0, items: [], totalPages: 0, totalItems: 0 },
};

interface Props {
  onClose(): void;
}

export default function CalendarBottomUnasignedTasksToPlanner(props: Props) {
  const { onClose } = props;
  const searchTime = React.useRef<any>(null);
  const [searchTeam, setSearchTeam] = React.useState<string>('');
  const [searchEmployee, setSearchEmployee] = React.useState<string>('');
  const [requestLoading, setRequestLoading] = React.useState<boolean>(false);
  const [allSelectedsTeams, setAllSelectedsTeams] = React.useState<Record<string, TeamsType[]>>({});
  const [allSelectedsEmployees, setAllSelectedsEmployees] = React.useState<
    Record<string, UsersType[]>
  >({});

  const unassignedTasksRes = useFetch<CalendarStoreResponse>({
    method: 'post',
    url: endpoints.taskItem.filter(1, 10000),
    defaultValue: { list: [], totalPages: 0, totalElements: 0 },
    body: {
      status: TaskDefinitionFormStoreTaskStatusEnum.UNASSIGNED,
    },
  });

  const unassignedTasks = unassignedTasksRes.data.list;

  const resTeams = useFetch<{ data: TeamsResponseType }>({
    defaultValue,
    url: endpoints.teams.findList,
    query: { page: '0', size: '10', search: searchTeam },
  });

  const resEmployees = useFetch<{ data: UsersResponseType }>({
    defaultValue,
    url: endpoints.users.findList,
    query: { page: '0', size: '10', search: searchEmployee },
  });

  const onChangeSearchTeam = (val: string, type: 'team' | 'employee') => {
    if (type === 'team') {
      setSearchTeam(val);
    } else {
      setSearchEmployee(val);
    }

    if (searchTime.current) {
      clearTimeout(searchTime.current);
    }
  };

  React.useEffect(() => {
    searchTime.current = setTimeout(() => resTeams.getData(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTeam]);

  React.useEffect(() => {
    searchTime.current = setTimeout(() => resEmployees.getData(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEmployee]);

  const teamOptions = resTeams.data.data.items;
  const employeOptions = resEmployees.data.data.items;

  const groupedUnassignedTasks = React.useMemo(() => {
    const grouped: Record<
      string,
      {
        taskTemplateName: string;
        tasks: CalendarStoreResponseRightItem[];
      }
    > = {};

    unassignedTasks.forEach((item) => {
      if (!grouped[`${item.taskTemplateId}`]) {
        grouped[`${item.taskTemplateId}`] = {
          tasks: [item],
          taskTemplateName: item.taskTemplateName,
        };
      } else {
        grouped[`${item.taskTemplateId}`].tasks.push(item);
      }
    });

    return grouped;
  }, [unassignedTasks]);

  const filteredTasks = unassignedTasks.filter((task) => {
    const selectedTeams = allSelectedsTeams[task.taskTemplateId] || [];
    const selectedEmployes = allSelectedsEmployees[task.taskTemplateId] || [];

    return selectedTeams.length > 0 || selectedEmployes.length > 0;
  });

  const handleAssigned = async () => {
    setRequestLoading(true);

    const assignees: PlannerAssignPayload['assignees'] = [];

    Object.entries(allSelectedsTeams).forEach(([taskTemplateId, item]) => {
      item.forEach((team) => {
        const assigneeIndex = assignees.findIndex((i) => i.assignee_id === team.id);

        if (assigneeIndex > -1) {
          assignees[assigneeIndex].role_id.push(`${taskTemplateId}`);
        } else {
          assignees.push({
            existing_tasks: [],
            assignee_id: team.id,
            role_id: [`${taskTemplateId}`],
          });
        }
      });
    });

    Object.entries(allSelectedsEmployees).forEach(([taskTemplateId, item]) => {
      item.forEach((employee) => {
        const assigneeIndex = assignees.findIndex((i) => i.assignee_id === employee.id);

        if (assigneeIndex > -1) {
          assignees[assigneeIndex].role_id.push(`${taskTemplateId}`);
        } else {
          assignees.push({
            existing_tasks: [],
            assignee_id: employee.id,
            role_id: [`${taskTemplateId}`],
          });
        }
      });
    });

    const payload: PlannerAssignPayload = {
      assignees,
      tasks: filteredTasks.map((task) => {
        const serviceTime = moment(task.targetCompleteDate).diff(
          moment(task.targetDate),
          'minutes'
        );
        return {
          frequency: 1,
          points: [task.point.id],
          service_time: serviceTime,
          id: `${task.id}-${task.task.id}`,
          role_id: [`${task.taskTemplateId}`],
          start_date: moment(task.targetDate).format('YYYY-MM-DD'),
          end_date: moment(task.targetCompleteDate).format('YYYY-MM-DD'),
        };
      }),
      session_territory: {
        depot_key: '',
        country_code: 'tr',
        is_pre_routed: false,
        force_to_assign: false,
        break_time_end: '13:00',
        not_optimize_routes: true,
        working_time_end: '18:00',
        break_time_start: '12:00',
        working_time_start: '08:00',
        territory_count: assignees.length,
        algorithm_depot_option: 'NOT_USE_DEPOT',
        day_capacity: [100, 100, 100, 100, 100, 0, 0] as number[],
        algorithm_type: AutoPlanningAlgorithmTypeEnum.BY_TERRITORY_COUNT,
        distance_calculate_type: AutoPlanningWayCalculationEnum.SHORTEST,
        balancing_field: AutoPlanningModelOptimizationEnum.COUNT_PER_TERRITORY,
      },
    };

    try {
      const res = await requests.plannerAssign(payload);

      const paylaodPlanMode: CalendarCreateBulkPayload[] = res.flatMap((item) => {
        return item.tasks.map((assign) => {
          const teamsIds = Object.entries(allSelectedsTeams).flatMap(([taskTemplateId, item]) =>
            item.map((i) => i.id)
          );
          const employeesIds = Object.entries(allSelectedsEmployees).flatMap(
            ([taskTemplateId, item]) => item.map((i) => i.id)
          );

          const assignmentData = {
            teamId: '',
            teamName: '',
            poolId: '',
            poolName: '',
            roleId: '',
            roleName: '',
            employeeId: '',
            employeeName: '',
          };

          if (teamsIds.includes(item.assignee_id)) {
            assignmentData.teamId = item.assignee_id;
          }

          if (employeesIds.includes(item.assignee_id)) {
            assignmentData.employeeId = item.assignee_id;
          }

          const startDate = dateQuarterMinAndHour(assign.start_date);
          const endDate = dateQuarterMinAndHour(assign.end_date);

          const id = `${assign.task_id.split('-')[0]}`;
          const taskId = `${assign.task_id.split('-')[1]}`;

          return {
            id: Number(id),
            assignmentData,
            task: { id: Number(taskId) },
            point: { id: assign.point_id },
            targetDate: moment(startDate).format('YYYY-MM-DDTHH:mm'),
            targetCompleteDate: moment(endDate).format('YYYY-MM-DDTHH:mm'),
          };
        });
      });

      await axios.post(`${endpoints.taskItem.createBulk}?planMode=MANUEL`, paylaodPlanMode);
      await getCalendarData();
      onClose();
      enqueueSnackbar('Taskler başarıyla atandı', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <Dialog open fullWidth maxWidth="lg">
      <ModalHeader title="Unassigned Taskler" />
      <Box position="relative">
        <ModalContent>
          <Stack spacing={1}>
            {Object.entries(groupedUnassignedTasks).map(([taskTemplateId, item]) => {
              const selectedTeams = allSelectedsTeams[taskTemplateId] || [];
              const selectedEmployes = allSelectedsEmployees[taskTemplateId] || [];

              return (
                <Card
                  spacing={1}
                  direction="row"
                  component={Stack}
                  alignItems="center"
                  key={taskTemplateId}
                  sx={{ borderRadius: 1 }}
                >
                  <Typography flex={1} variant="subtitle2" fontWeight="700">
                    {item.taskTemplateName}
                  </Typography>
                  <Stack flex={1} direction="row" alignItems="center" spacing={1}>
                    <Autocomplete
                      multiple
                      fullWidth
                      size="small"
                      value={selectedTeams}
                      options={teamOptions}
                      loading={resTeams.loading}
                      getOptionLabel={(option) => option.name || ''}
                      noOptionsText={searchTeam || 'Takım bulunamadı'}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(i) => (
                        <TextField
                          {...i}
                          value={searchTeam}
                          placeholder="Takım ara..."
                          onBlur={() => onChangeSearchTeam('', 'team')}
                          onChange={(e) => onChangeSearchTeam(e.target.value, 'team')}
                        />
                      )}
                      onChange={(_, value) => {
                        setAllSelectedsTeams((prev) => {
                          const newItem = { ...prev };

                          if (value.length === 0) {
                            delete newItem[taskTemplateId];
                          } else {
                            newItem[taskTemplateId] = value;
                          }

                          return newItem;
                        });
                      }}
                    />
                    <Autocomplete
                      multiple
                      fullWidth
                      size="small"
                      options={employeOptions}
                      value={selectedEmployes}
                      loading={resEmployees.loading}
                      noOptionsText="Kişi bulunamadı"
                      getOptionLabel={(option) => option.fullName || ''}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(i) => (
                        <TextField
                          {...i}
                          value={searchEmployee}
                          placeholder={searchEmployee || 'Kişi ara...'}
                          onBlur={() => onChangeSearchTeam('', 'employee')}
                          onChange={(e) => onChangeSearchTeam(e.target.value, 'employee')}
                        />
                      )}
                      onChange={(_, value) => {
                        setAllSelectedsEmployees((prev) => {
                          const newItem = { ...prev };

                          if (value.length === 0) {
                            delete newItem[taskTemplateId];
                          } else {
                            newItem[taskTemplateId] = value;
                          }

                          return newItem;
                        });
                      }}
                    />
                  </Stack>
                </Card>
              );
            })}
          </Stack>
        </ModalContent>
        <ModalFooter
          onCloseTitle="Kapat"
          onClose={onClose}
          contentLeft={
            <Typography flex={1} variant="subtitle2" color="text.secondary">
              Herhangi bir Task için takım veya kişi seçilmediyse o task atlanacaktır.
            </Typography>
          }
          actions={
            <LoadingButton
              variant="contained"
              onClick={handleAssigned}
              loading={requestLoading}
              disabled={filteredTasks.length === 0}
            >
              Gönder
            </LoadingButton>
          }
        />
        {unassignedTasksRes.loading && (
          <Loading sx={{ borderRadius: 0, p: 0, bgcolor: 'background.paper', boxShadow: 'none' }} />
        )}
      </Box>
    </Dialog>
  );
}
