import { LayerTypes, StaticLayerNamesAndKeys } from "../map/helpers/enums/layer.enum";
import { SourceTypes } from "../map/helpers/enums/source.enum";
import { hereTraficLayer } from "../map/storage/map.config.dependencies";
import { IMl_ITileLayer, IMl_TSldStyles } from "./map.lib.interfaces";


/* 

const GVG_testGeoserver_workspaceGvg='https://geoserver-p.geovisiongroup.com/gvg/wms?';
const GVG_testGeoserver_workspaceGvg_layerIlceler='ilceler';

const Edip_LocalGeoserver_workspaceMongowms='http://localhost:8080/geoserver/mongowms/wms?';
const Edip_LocalGeoserver_workspaceMongowms_layerPointw='pointw';
 */

const GVGTestGeoserver_gvgmongodb_WORKSPACE='http://geovisionmap.test.geovisiongroup.com/geoserver/gvgmongodb/wms?';
const GVGTestGeoserver_gvgmongodb_WORKSPACE_layer_point_clean='point-clean';

export const hereTraficLayerParams = {
  active: true,
  layerType: LayerTypes.HereTrafic,
  type: 'trafic',
  name: StaticLayerNamesAndKeys.HERE_TRAFFIC_LAYER_NAME,
  title: 'Here Map (Trafic)',
  visible: true,
  opacity: 1,
  displayInLayerSwitcher: true,
  source: {
    hereTraficLayer: hereTraficLayer,
    crossOrigin: 'Anonymous',
  },
};
export const tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean:IMl_ITileLayer  = {
  active: true,
  layerType: LayerTypes.TileLayer,
  name: 'tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean',
  title: 'tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean',
  visible: true,
  opacity: 1,
  disabledInLayerSwitcher: true,
  source: {
    sourceType: SourceTypes.TileWMS,
    url: GVGTestGeoserver_gvgmongodb_WORKSPACE,
    params: { LAYERS: GVGTestGeoserver_gvgmongodb_WORKSPACE_layer_point_clean},
    crossOrigin: 'Anonymous',
  },
};

export const tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointcleanWithFilter:IMl_ITileLayer  = {
  active: true,
  layerType: LayerTypes.TileLayer,
  name: 'tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointcleanWithFilter',
  title: 'tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointcleanWithFilter',
  visible: true,
  opacity: 1,
  disabledInLayerSwitcher: true,
  source: {
    sourceType: SourceTypes.TileWMS,
    url:GVGTestGeoserver_gvgmongodb_WORKSPACE,
    params: { LAYERS: GVGTestGeoserver_gvgmongodb_WORKSPACE_layer_point_clean, CQL_Filter:  `"properties.CUSTOMER_I"=196` },
    crossOrigin: 'Anonymous',
  },
};
export const vectorLayerStyleTemplate = {
  layerType: LayerTypes.VectorLayer,
  name: 'StyleTemplate',
  title: 'StyleTemplate',
  visible: true,
  opacity: 1,
  displayInLayerSwitcher: true,
 
  style: {
    stroke: { color: 'blue', width: 2 },
    fill: { color: 'rgba(255, 255, 255, 0.8)' },
    image: {
      icon: {
        color: 'blue',
        crossOrigin: 'anonymous',
        // For Internet Explorer 11
        imgSize: [20, 20],
        src: window.location.origin + '/square.svg'
      }
    },
    text: {
      textAlign: 'center',
      textBaseline: 'middle',
      font: '11px Calibri,sans-serif',
      text: 'conf text',
      fill: { color: '#000' },
      stroke: { color: 'white', width: 5 },
      backgroundFill: { fill: { color: 'white' } },
      offsetX: 20,
      offsetY: -20,
      overflow: true,
      label: 'name'
    }
  }
};
export const katmanlar:IMl_ITileLayer[]=[
  tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointclean,
  tileWMSLayerParamsGVGTestGeoserverGvgmongodbWORKSPACELayerPointcleanWithFilter]

export const addressAramaResponseExample=[
  {
      "address": "İstanbul, Beşiktaş",
      "latitude": "41.043869",
      "longitude": "29.01289"
  },
  {
      "address": "Ayazini-Cumhuriyet Mevkii Köyü Mahallesi Beşiktaş Sk. 03372 İhsaniye/Afyonkarahisar",
      "latitude": "39.006877",
      "longitude": "30.560684"
  },
  {
      "address": "Pınarbaşı Mh. Beşiktaş Sk. 06280 Keçiören/Ankara",
      "latitude": "39.999861",
      "longitude": "32.865233"
  },
  {
      "address": "Dutluk Semti Beşiktaş Sk. Keçiören/Ankara",
      "latitude": "40.000422",
      "longitude": "32.869599"
  },
  {
      "address": "Gazino Semti Beşiktaş Sk. Keçiören/Ankara",
      "latitude": "40.000035",
      "longitude": "32.867714"
  },
  {
      "address": "Arabayatağı Semti Beşiktaş Çıkmaz Sk. Yıldırım/Bursa",
      "latitude": "40.194229",
      "longitude": "29.124621"
  },
  {
      "address": "Dumlupınar Mh. Beşiktaş Sk. 13200 Tatvan/Bitlis",
      "latitude": "38.497684",
      "longitude": "42.271457"
  },
  {
      "address": "Camii Mh. Beşiktaş Sk. 15070 Çeltikçi/Burdur",
      "latitude": "37.52431",
      "longitude": "30.476535"
  },
  {
      "address": "Çebiş Köyü Beşiktaş Sk. 15072 Çeltikçi/Burdur",
      "latitude": "37.508568",
      "longitude": "30.449591"
  },
  {
      "address": "Arabayatağı Mh. Beşiktaş Çıkmaz Sk. 16330 Yıldırım/Bursa",
      "latitude": "40.194229",
      "longitude": "29.124621"
  },
  {
      "address": "Yıldırımbeyazıt Mh. Beşiktaş Sk. 06980 Kahramankazan/Ankara",
      "latitude": "40.183053",
      "longitude": "32.691812"
  }
]

/* export const tileWMSLayerParams_GVG_testGeoserver_workspaceGvg_layerIlceler:TTileLayer  = {
    active: true,
    layerType: LayerTypes.TileLayer,
    name: 'tileWMSLayerParams_GVG_testGeoserver_workspaceGvg_layerIlceler',
    title: 'tileWMSLayerParams_GVG_testGeoserver_workspaceGvg_layerIlceler',
    visible: true,
    opacity: 1,
    disabledInLayerSwitcher: true,
    source: {
      sourceType: SourceTypes.TileWMS,
      url: GVG_testGeoserver_workspaceGvg,
      params: { LAYERS: GVG_testGeoserver_workspaceGvg_layerIlceler},
      crossOrigin: 'Anonymous',
    },
  };

  export const tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointw:TTileLayer= {
    active: true,
    layerType: LayerTypes.TileLayer,
    name: 'tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointw',
    title: 'tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointw',
    visible: true,
    opacity: 1,
    disabledInLayerSwitcher: true,
    source: {
      sourceType: SourceTypes.TileWMS,
      url: Edip_LocalGeoserver_workspaceMongowms,
      params: { LAYERS: Edip_LocalGeoserver_workspaceMongowms_layerPointw},
      crossOrigin: 'Anonymous',
    },
  };

  export const tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointwWithParams:TTileLayer= {
    active: true,
    layerType: LayerTypes.TileLayer,
    name: 'tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointwWithParams',
    title: 'tileWMSLayerParams_Edip_LocalGeoserver_workspaceMongowms_layerPointwWithParams',
    visible: true,
    opacity: 1,
    disabledInLayerSwitcher: true,
    source: {
      sourceType: SourceTypes.TileWMS,
      url: Edip_LocalGeoserver_workspaceMongowms,
      params: { LAYERS: Edip_LocalGeoserver_workspaceMongowms_layerPointw, CQL_Filter:  `"properties.CUSTOMER_I"=196`},
      crossOrigin: 'Anonymous',
    },
  };
 */

  export const sldStyleExample :IMl_TSldStyles=[
    {
      rules: [
        {
          title: "Geometri",
          max: "6",
          min: "8",
          point_symbolizers: [
            {
              symbol: "square",
              size: "14",
              fill: {
                color: "#404040",
                opacity: "1.0",
              },
              stroke: {
                color: "#ff9900",
                opacity: "1.0",
                width: "1",
              },
              rotation: "0",
            },
          ],
        },
        {
          title: "Etiket",
          max: "2",
          min: "5",
          text_symbolizers: [
            {
              label: "",
              font_family: "DejaVu+Sans",
              font_size: "12",
              font_style: "",
              font_weight: "",
              fill: {
                color: "#000000",
                opacity: "1.0",
              },
              halo: {
                color: "#ffffff",
                opacity: "1.0",
                radius: "0",
              },
            },
          ],
        },
      ],
    },
  ]
  