import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios from 'src/utils/axios';
import { WmsNameEnums } from 'src/types';
import useMapActionsStore from '../map-actions';
import useBaseWorkspaceStore from '../base-workspaces';
import useMapAsidePointsStore from '../map-aside-points';
import { LayerTypes } from '../../libs/map/helpers/enums/layer.enum';
import { SourceTypes } from '../../libs/map/helpers/enums/source.enum';
import { CUSTOMER_DATA_ES_URL, WMS_SERVER_URL } from 'src/config-global';
import { MapLibHelper } from '../../libs/mapLibInterface/map.lib.helper';
import { MapHandleMapEvents, MapEventsStore, WmsLayer, MapGeoFilterResponse } from './types';

const initialState: MapEventsStore = {
  terms: [],
  geoShape: null,
  loading: false,
  selectedPolygon: null,
  response: {
    size: 0,
    features: [],
    totalSize: 0,
    indexName: '',
  },
};

const useMapEventsStore = create<MapEventsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => {
    set(initialState);
    MapLibHelper.wmsLayerSil(WmsNameEnums.MAP_POINTS_SELECTED);
  },
}));

export default useMapEventsStore;

const { setState, getState } = useMapEventsStore;

export const handleMapEvents = async (e: MapHandleMapEvents) => {
  setState({ loading: true });

  // Check the event type
  if (e.type === 'Polygon') {
    polygonEvent(e);
    useMapActionsStore.setState({ polygonActive: false });
  }
};

// Haritada polygon çizildiğinde çalışacak fonksiyon
const polygonEvent = async (e: MapHandleMapEvents) => {
  const state = getState();
  const baseWorkspaceStore = useBaseWorkspaceStore.getState();
  const mapAsidePointsStore = useMapAsidePointsStore.getState();

  // Clear the map
  MapLibHelper.drawInteractionOnClear();
  // Bunu bekletmemin nedeni direk çağırırsam map library patlıyor.
  await new Promise((resolve) => {
    setTimeout(() => {
      MapLibHelper.drawInteractionOnDestroy();
      resolve(true);
    }, 500);
  });

  const layersName = baseWorkspaceStore.baseWorkspace?.workspaceId;
  const baseLayerIndexName = `gvg.${baseWorkspaceStore.baseWorkspace?.availability.collectionName}`;

  const selectedPolygon = [e, state.selectedPolygon].filter(Boolean);

  try {
    const resMerge = await axios.post(`${CUSTOMER_DATA_ES_URL}merge-polygon`, selectedPolygon);

    const res = await axios.post<MapGeoFilterResponse>(
      `${CUSTOMER_DATA_ES_URL}create-geofilter/gvg:${layersName}`,
      resMerge.data
    );

    const nativeQuery = JSON.parse(res.data.queryString);

    const geoShape = nativeQuery.query.bool.filter[0].geo_shape;

    const should: any[] = [{ geo_shape: geoShape }];

    if (state.terms.length > 0) {
      should.push({ terms: { _id: state.terms } });
    }

    const resFilter = await axios.post<MapEventsStore['response']>(
      `${CUSTOMER_DATA_ES_URL}filter`,
      {
        page: 0,
        includeGeo: true,
        subCollectionName: 'pooling',
        indexName: baseLayerIndexName,
        size: mapAsidePointsStore.response.count,
        nativeQuery: {
          query: {
            bool: {
              should,
              must: [],
              must_not: [],
            },
          },
        },
      }
    );

    setState({ geoShape, response: resFilter.data, selectedPolygon: resMerge.data });

    handleViewOnMap();
  } catch (error) {
    enqueueSnackbar('Seçilen veriler getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: false });
  }
};

//
export const handlePointSelected = async (id: string, selected: boolean) => {
  setState({ loading: true });
  const state = getState();
  const baseWorkspaceStore = useBaseWorkspaceStore.getState();
  const mapAsidePointsStore = useMapAsidePointsStore.getState();
  const baseLayerIndexName = `gvg.${baseWorkspaceStore.baseWorkspace?.availability.collectionName}`;

  const terms = selected ? [...state.terms, id] : state.terms.filter((term) => term !== id);

  const should: any[] = [{ terms: { _id: terms } }];

  if (state.geoShape) {
    should.unshift({ geo_shape: state.geoShape });
  }

  try {
    const resFilter = await axios.post<MapEventsStore['response']>(
      `${CUSTOMER_DATA_ES_URL}filter`,
      {
        page: 0,
        includeGeo: true,
        subCollectionName: 'pooling',
        indexName: baseLayerIndexName,
        size: mapAsidePointsStore.response.count,
        nativeQuery: {
          query: {
            bool: {
              should,
              must: [],
              must_not: [],
            },
          },
        },
      }
    );

    setState({ terms, geoShape: state.geoShape, response: resFilter.data });

    handleViewOnMap();
  } catch (error) {
    throw error;
  } finally {
    setState({ loading: false });
  }
};

// Map viewed

const handleViewOnMap = () => {
  MapLibHelper.wmsLayerSil(WmsNameEnums.MAP_POINTS_SELECTED);

  const state = getState();

  if (state.response.features.length === 0) {
    return;
  }

  const baseWorkspaceStore = useBaseWorkspaceStore.getState();

  const layersName = baseWorkspaceStore.baseWorkspace?.workspaceId;
  const layersNameWithPREFIX = `gvg:${layersName}`;

  const should = [];

  if (state.geoShape) {
    should.push({ geo_shape: state.geoShape });
  }

  if (state.terms.length > 0) {
    should.push({ terms: { _id: state.terms } });
  }

  const wmsLayer: WmsLayer = {
    opacity: 1,
    active: true,
    visible: true,
    title: 'Seçilen Noktalar',
    disabledInLayerSwitcher: true,
    layerType: LayerTypes.TileLayer,
    name: WmsNameEnums.MAP_POINTS_SELECTED,
    source: {
      url: WMS_SERVER_URL,
      crossOrigin: 'Anonymous',
      sourceType: SourceTypes.TileWMS,
      params: {
        STYLES: 'generic_selected',
        LAYERS: layersNameWithPREFIX,
        viewparams: `q:${JSON.stringify({
          bool: {
            should,
            must: [],
            must_not: [],
          },
        })}`,
      },
    },
  };

  const newLayer = MapLibHelper.createLayer(wmsLayer);

  MapLibHelper.addLayer(newLayer);
};
