import React from 'react';
import { Button, DialogActions } from '@mui/material';

interface Props {
  onClose(): void;
  actions?: React.ReactNode;
  onCloseTitle?: string;
  contentLeft?: React.ReactNode;
}

export default function ModalFooter(props: Props) {
  const { onClose, actions, onCloseTitle, contentLeft } = props;
  return (
    <DialogActions
      sx={{
        bottom: 0,
        zIndex: 2,
        position: 'sticky',
        bgcolor: 'background.neutral',
        px: (t) => ({ xs: `${t.spacing(1.5)}!important`, lg: `${t.spacing(3)}!important` }),
      }}
    >
      {contentLeft}
      <Button color="inherit" variant="outlined" onClick={onClose}>
        {onCloseTitle || 'Vazgeç'}
      </Button>
      {actions}
    </DialogActions>
  );
}
